import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import LinkUtils from "lib/LinkUtils";
import ReactPlayer from "react-player";

class ResourceCenterTopicEmbed extends React.Component {
  render() {
    return (
      <>
        <div className="">
          <div className="border-bottom mb-3 pb-3">
            <Row className="align-items-center">
              <Col xs="">
                <h3 className="text-dark m-0">{this.props.topic?.name}</h3>
                {this.props.topic?.description ? (
                  <p
                    className="text-dark small m-0"
                    style={{ lineHeight: 1.2 }}
                  >
                    {this.props.topic?.description}
                  </p>
                ) : null}
              </Col>
              <Col xs="auto">
                <Button
                  onClick={() => {
                    LinkUtils.openInNewTab(
                      `/university/library/topics/${this.props.topic._id}`,
                      true
                    );
                  }}
                  size="sm"
                  color="secondary"
                  outline
                >
                  Open<i className="mdi mdi-open-in-new pl-2"></i>
                </Button>
              </Col>
            </Row>
          </div>
          {this.props.topic?.videoURL ? (
            <>
              {this.props.topic?.videoURL?.includes("<iframe") ? (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: this.props.topic?.videoURL,
                    }}
                    className="mb-4 border border-midlighter"
                    style={{ width: "100%" }}
                  ></div>
                </>
              ) : (
                <>
                  <ReactPlayer
                    className="mb-4 border border-midlighter"
                    width={"100%"}
                    url={this.props.topic?.videoURL}
                  />
                </>
              )}
            </>
          ) : null}
          <div
            className="plnu-topic"
            dangerouslySetInnerHTML={{
              __html:
                typeof this.props.topic?.content == "string"
                  ? this.props.topic?.content
                  : this.props.topic?.content?.html,
            }}
          ></div>
          {this.props.topic?.attachments?.length ? (
            <Card className="border">
              <CardHeader className="p-3">
                <h3 className="m-0 text-dark" style={{ lineHeight: 1 }}>
                  Attachments
                </h3>
              </CardHeader>
              <CardBody className="p-3">
                {this.props.topic?.attachments?.map((at, i) => (
                  <div
                    className={`p-2 border rounded ${
                      i != this.props.topic?.attachments?.length - 1 && "mb-2"
                    }`}
                    key={i}
                  >
                    <Row className="align-items-center">
                      <Col xs="12" sm="">
                        <p className="small m-0 text-dark text-truncate">
                          {at?.fileName}
                        </p>
                      </Col>
                      <Col
                        xs="12"
                        sm="auto"
                        className="mt-3 mt-sm-0 text-right"
                      >
                        <Button
                          className="btn-icon-only"
                          size="sm"
                          outline
                          color="secondary"
                          onClick={() => {
                            LinkUtils.openPrivateURL(at?.url);
                          }}
                        >
                          <i className="mdi mdi-eye"></i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ))}
              </CardBody>
            </Card>
          ) : null}
        </div>
      </>
    );
  }
}

export default withRouter(ResourceCenterTopicEmbed);
