import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
  Alert,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker, TagInput, Tooltip, Whisper } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import swal from "sweetalert";
import NumberFormat from "react-number-format";
import DateUtils from "lib/DateUtils";
import LinkUtils from "lib/LinkUtils";

class StorefrontOnlinePresenceView extends React.Component {
  state = {
    active: {
      id: "All",
      name: "All",
    },
    phone: {
      value: "",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 25,
    filters: null,
  };

  formatHours(store) {
    let hours = [
      {
        day: "Monday",
        opensAt: "",
        closesAt: "",
        closed: true,
      },
      {
        day: "Tuesday",
        opensAt: "",
        closesAt: "",
        closed: true,
      },
      {
        day: "Wednesday",
        opensAt: "",
        closesAt: "",
        closed: true,
      },
      {
        day: "Thursday",
        opensAt: "",
        closesAt: "",
        closed: true,
      },
      {
        day: "Friday",
        opensAt: "",
        closesAt: "",
        closed: true,
      },
      {
        day: "Saturday",
        opensAt: "",
        closesAt: "",
        closed: true,
      },
      {
        day: "Sunday",
        opensAt: "",
        closesAt: "",
        closed: true,
      },
    ];

    if (!store?.operations?.hours?.standard?.length) {
      return hours;
    }

    for (let i = 0; i < store?.operations?.hours?.standard?.length; i++) {
      const hour = store?.operations?.hours?.standard[i];

      const idx = _.findIndex(hours, { day: hour.day });

      if (idx < 0) {
        continue;
      }

      if (!hour?.opensAt && !hour.closesAt) {
        continue;
      }

      hours[idx].opensAt = hour?.opensAt;
      hours[idx].closesAt = hour?.closesAt;
      hours[idx].closed = hour?.closed;

      if (hours[idx].opensAt.includes("AM")) {
        hours[idx].opensAt = hours[idx].opensAt.replace("AM", "").trim();
      }

      if (hours[idx].opensAt.includes("PM")) {
        hours[idx].opensAt = hours[idx].opensAt.replace("PM", "").trim();

        let opensHours = hours[idx].opensAt.split(":");

        let openMinutes = opensHours[1];

        opensHours = parseInt(opensHours[0]);
        opensHours = opensHours + 12;

        hours[idx].opensAt = `${opensHours}:${openMinutes}`;
      }

      if (hours[idx].closesAt.includes("AM")) {
        hours[idx].closesAt = hours[idx].closesAt.replace("AM", "").trim();
      }

      if (hours[idx].closesAt.includes("PM")) {
        hours[idx].closesAt = hours[idx].closesAt.replace("PM", "").trim();

        let opensHours = hours[idx].closesAt.split(":");

        let openMinutes = opensHours[1];

        opensHours = parseInt(opensHours[0]);
        opensHours = opensHours + 12;

        hours[idx].closesAt = `${opensHours}:${openMinutes}`;
      }

      if (hours[idx].opensAt) {
        const arr = hours[idx].opensAt.split(":");

        if (arr?.length == 2) {
          let hr = parseInt(arr[0]);

          if (hr < 10) {
            hr = `0${hr}`;
          }

          hours[idx].opensAt = `${hr}:${arr[1]}`;
        }
      }

      if (hours[idx].closesAt) {
        const arr = hours[idx].closesAt.split(":");

        if (arr?.length == 2) {
          let hr = parseInt(arr[0]);

          if (hr < 10) {
            hr = `0${hr}`;
          }

          hours[idx].closesAt = `${hr}:${arr[1]}`;
        }
      }
    }

    return hours;
  }

  calculateTotalHours(hours) {
    let totalHours = 0;

    for (let i = 0; i < hours?.length; i++) {
      const hour = hours[i];

      if (hour?.closed || !hour?.opensAt || !hour?.closesAt) {
        continue;
      }

      const opens = moment(hour?.opensAt, "HH:mm");
      const closes = moment(hour?.closesAt, "HH:mm");

      let diff = closes.diff(opens, "hours", true);

      totalHours += diff;

      console.log(diff);
    }

    return totalHours;
  }

  hoursAreValid(hours) {
    if (hours?.length != 7) {
      return false;
    }

    for (let i = 0; i < hours?.length; i++) {
      const hour = hours[i];

      if (hour?.closed && (hour?.opensAt || hour.closesAt)) {
        return false;
      }

      if (!hour?.closed && (!hour?.opensAt || !hour.closesAt)) {
        return false;
      }

      if (!hour?.closed) {
        if (
          moment(hour?.opensAt, "HH:mm").isSameOrAfter(
            moment(hour?.closesAt, "HH:mm")
          )
        ) {
          return false;
        }
      }
    }

    return true;
  }

  convertMilitaryToAMPM(time) {
    if (!time) {
      return "";
    }

    const timeArr = time.split(":");

    if (timeArr?.length != 2) {
      return "";
    }

    let hours = parseInt(timeArr[0]);
    const minutes = timeArr[1];

    const amPm = hours >= 12 ? "PM" : "AM";

    if (hours == 0) {
      hours = "12";
    } else if (hours > 12) {
      hours = hours - 12;
    }

    return `${hours}:${minutes} ${amPm}`;
  }

  loadStore() {
    APIV2.getStoreByID(this.props.match.params.storeID).then(
      (data) => {
        this.setState(
          {
            store: data.data.store,
          },
          () => {
            this.setState({
              hours: this.formatHours(this.state.store),
            });
          }
        );
      },
      (e) => {
        alert("Unable to load store settings. Reload and try again.");
      }
    );
  }

  saveStoreContact() {
    this.setState({
      contactSubmitting: true,
    });

    APIV2.modifyStoreContactInformation(
      this.props.match.params?.storeID,
      `+1${this.state.phone?.value}`
    )
      .then(
        (data) => {
          this.setState({
            store: data.data.store,
            editContact: false,
          });
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          contactSubmitting: false,
        });
      });
  }

  saveStoreConversationPersonas() {
    this.setState({
      conversationSubmitting: true,
    });

    let personas = this.state.conversationPersonas?.length
      ? this.state.conversationPersonas
      : [];

    APIV2.modifyStoreParameter(
      this.props.match.params?.storeID,
      "conversationPersonas",
      personas
    )
      .then(
        (data) => {
          this.setState({
            store: data.data.store,
            editConversation: false,
          });
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          conversationSubmitting: false,
        });
      });
  }

  saveStoreHours() {
    this.setState({
      hoursSubmitting: true,
    });

    APIV2.modifyStoreRetailHours(
      this.props.match.params?.storeID,
      this.state.hours
    )
      .then(
        (data) => {
          if (
            DateUtils.storeIsLive(data.data.store) &&
            this.calculateTotalHours(this.state.hours) < 57
          ) {
            APIV2.createGlobalTask(
              this.props.match.params.storeID,
              "Review Store Hours",
              "Fran-Support",
              `The store has updated their hours to be less than 57 hours per week (${this.calculateTotalHours(
                this.state.hours
              ).toFixed(
                1
              )} hours). Please review and reach out to the franchise partner.`,
              "Normal",
              null,
              null,
              null,
              null,
              "Not Started",
              moment().add(3, "days").toDate().toISOString(),
              "63ebf7c28a34e20de7bf43aa",
              null,
              true,
              null,
              null
            );
          }

          this.setState({
            store: data.data.store,
            editHours: false,
          });
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          hoursSubmitting: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.storeID != prevProps.match.params.storeID) {
      this.loadStore();
    }
  }

  componentDidMount() {
    this.loadStore();
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader title="Online Presence" showBackButton={false} />

          <Row>
            <Col xs="12" md="6" className="mb-4">
              <Card className="border shadow" style={{ height: "100%" }}>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="mb-0">Contact Information</h3>
                    </Col>
                    <Col xs="auto">
                      {this.state.editContact ? (
                        <>
                          <Button
                            size="sm"
                            className="my-0"
                            outline
                            color="dark"
                            onClick={() => {
                              this.setState({ editContact: false });
                            }}
                            disabled={this.state.contactSubmitting}
                          >
                            Cancel
                          </Button>
                          <Button
                            size="sm"
                            className="my-0"
                            color="primary"
                            disabled={
                              !this.state.phone?.value ||
                              this.state.contactSubmitting
                            }
                            onClick={() => {
                              this.saveStoreContact();
                            }}
                          >
                            {this.state.contactSubmitting ? (
                              <Spinner size="sm"></Spinner>
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            size="sm"
                            className="m-0"
                            outline
                            onClick={() => {
                              this.setState({
                                editContact: true,
                                phone: {
                                  value:
                                    this.state.store?.contact?.phone?.replace(
                                      "+1",
                                      ""
                                    ),
                                },
                              });
                            }}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <p className="mb-1 small font-weight-bold text-dark">
                          Email
                        </p>
                      </Col>
                      <Col xs={this.state.editContact ? "10" : "12"} md="">
                        <div className="text-truncate">
                          <p
                            className="m-0 text-truncate"
                            style={{ lineHeight: 1.2 }}
                          >
                            {this.state.store?.contact?.email ?? "--"}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <Row className="mb-1 align-items-center">
                          <Col xs="">
                            <p className="mb-1 small font-weight-bold text-dark">
                              Store Phone Number
                            </p>
                          </Col>
                          <Col xs="auto">
                            <Whisper
                              trigger={["hover", "click"]}
                              placement="auto"
                              delayOpen={300}
                              speaker={
                                <Tooltip>
                                  The phone number that clients and leads will
                                  see and use to communicate with your store.
                                </Tooltip>
                              }
                            >
                              <p className="m-0 cursor-pointer">
                                <i className="mdi mdi-information-outline"></i>
                              </p>
                            </Whisper>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12">
                        <div
                          style={{
                            whiteSpace: "break-word",
                          }}
                        >
                          <p className="m-0" style={{ lineHeight: 1.2 }}>
                            {this.state.store?.birdEnabled ? (
                              <>
                                {this.state.store?.birdPhone?.identifier
                                  ? StringUtils.formatPhoneNumber(
                                      this.state.store?.birdPhone?.identifier
                                    )
                                  : "--"}
                              </>
                            ) : (
                              <>
                                {this.state.store?.twilioNumber
                                  ? StringUtils.formatPhoneNumber(
                                      this.state.store?.twilioNumber
                                    )
                                  : "--"}
                              </>
                            )}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="p-3">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <Row className="mb-1 align-items-center">
                          <Col xs="">
                            <p className="mb-0 small font-weight-bold text-dark">
                              Call Redirect Phone Number
                            </p>
                          </Col>
                          <Col xs="auto">
                            <Whisper
                              trigger={["hover", "click"]}
                              placement="auto"
                              delayOpen={300}
                              speaker={
                                <Tooltip>
                                  When you receive a call to your Store Phone
                                  Number, it will be redirected to this number.
                                </Tooltip>
                              }
                            >
                              <p className="m-0 cursor-pointer">
                                <i className="mdi mdi-information-outline"></i>
                              </p>
                            </Whisper>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12">
                        {this.state.editContact ? (
                          <>
                            <NumberFormat
                              className="form-control"
                              format="+1 (###) ###-####"
                              mask=""
                              name="phoneNumberInput"
                              placeholder="Phone Number"
                              onValueChange={(e) => {
                                this.setState({ phone: e });
                              }}
                              value={this.state.phone.value}
                              type="tel"
                            />
                          </>
                        ) : (
                          <div
                            style={{
                              whiteSpace: "break-word",
                            }}
                          >
                            <p className="m-0" style={{ lineHeight: 1.2 }}>
                              {this.state.store?.contact?.phone
                                ? StringUtils.formatPhoneNumber(
                                    this.state.store?.contact?.phone
                                  )
                                : "--"}
                            </p>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xs="12" md="6" className="mb-4">
              <Card className="border shadow" style={{ height: "100%" }}>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    <Col xs="">
                      <h3 className="mb-0">Social Media</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="p-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <p className="mb-1 small font-weight-bold text-dark">
                          Instagram
                        </p>
                      </Col>
                      <Col xs="">
                        <div
                          style={{
                            whiteSpace: "break-word",
                          }}
                        >
                          <p className="m-0" style={{ lineHeight: 1.2 }}>
                            {this.state.store?.contact?.instagram_url ?? "--"}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="p-3">
                    <Row className="align-items-center">
                      <Col xs="12">
                        <p className="mb-1 small font-weight-bold text-dark">
                          Facebook
                        </p>
                      </Col>
                      <Col xs="">
                        <div
                          style={{
                            whiteSpace: "break-word",
                          }}
                        >
                          <p className="m-0" style={{ lineHeight: 1.2 }}>
                            {this.state.store?.contact?.facebook_url ?? "--"}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {this.state.store?.type == "retail" && (
              <Col xs="12" md="12" className="mb-4">
                <Card className="border shadow" style={{ height: "100%" }}>
                  <CardHeader className="p-3">
                    <Row className="align-items-center">
                      <Col xs="">
                        <h3 className="mb-0">Retail Hours</h3>
                        <p
                          className="small text-dark m-0"
                          style={{ lineHeight: 1.2 }}
                        >
                          Total Open Hours:{" "}
                          {this.calculateTotalHours(this.state.hours).toFixed(
                            1
                          )}{" "}
                          hours
                        </p>
                      </Col>
                      <Col xs="auto">
                        <Button
                          onClick={() => {
                            LinkUtils.openInNewTab(
                              "/university/library/topics/66f6cd06612abc290a21a53e",
                              true
                            );
                          }}
                          size="sm"
                          outline
                          color="dark"
                          className="btn-icon-only"
                        >
                          <i className="mdi mdi-information-outline"></i>
                        </Button>
                        {this.state.editHours ? (
                          <>
                            <Button
                              size="sm"
                              className="my-0"
                              outline
                              color="dark"
                              onClick={() => {
                                this.setState({
                                  editHours: false,
                                  hours: this.formatHours(this.state.store),
                                });
                              }}
                              disabled={this.state.hoursSubmitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              size="sm"
                              className="my-0"
                              color="primary"
                              disabled={
                                this.state.hoursSubmitting ||
                                !this.hoursAreValid(this.state.hours)
                              }
                              onClick={() => {
                                this.saveStoreHours();
                              }}
                            >
                              {this.state.hoursSubmitting ? (
                                <Spinner size="sm"></Spinner>
                              ) : (
                                "Save"
                              )}
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              size="sm"
                              className="m-0"
                              outline
                              onClick={() => {
                                this.setState({
                                  editHours: true,
                                  hours: this.formatHours(this.state.store),
                                });
                              }}
                            >
                              Edit
                            </Button>
                          </>
                        )}
                      </Col>
                    </Row>
                    {this.state.store &&
                    DateUtils.storeIsLive(this.state.store) &&
                    Math.round(this.calculateTotalHours(this.state.hours)) <
                      57 ? (
                      <Alert
                        onClick={() => {
                          LinkUtils.openInNewTab(
                            "/university/library/topics/66f6cd06612abc290a21a53e",
                            true
                          );
                        }}
                        color=""
                        className="mt-3 mb-0 cursor-pointer bg-primary-ultralight text-dark border border-primary-superlight"
                      >
                        <div className="mb-2">
                          <strong>Warning:</strong> Your open hours are less
                          than the standard operating requirements of 57 hours
                          per week. Unless you've received prior approval from
                          the Franchise Team, please adjust your hours to meet
                          or exceed this requirement.
                        </div>
                        <Button size="sm" outline color="dark">
                          Learn More
                        </Button>
                      </Alert>
                    ) : null}
                  </CardHeader>
                  <CardBody className="p-0">
                    {this.state.editHours ? (
                      <>
                        {this.state.hours?.map((hour) => (
                          <div className="p-3 border-bottom" key={hour?.day}>
                            <Row className="align-items-center mb-2">
                              <Col xs="">
                                <p className="m-0 font-weight-bold text-dark">
                                  {hour?.day}
                                </p>
                              </Col>
                              <Col xs="auto">
                                <Checkbox
                                  name="Closed"
                                  checked={hour?.closed}
                                  title="Closed"
                                  inline={true}
                                  onChange={(value, checked) => {
                                    let idx = _.findIndex(this.state.hours, {
                                      day: hour?.day,
                                    });

                                    if (idx < 0) {
                                      return;
                                    }

                                    let hours = this.state.hours;
                                    hours[idx].closed = checked;
                                    hours[idx].opensAt = "";
                                    hours[idx].closesAt = "";

                                    this.setState({ hours });
                                  }}
                                >
                                  Closed
                                </Checkbox>
                              </Col>
                            </Row>

                            <Row className="align-items-center">
                              <Col xs="12" sm="6">
                                <p className="mb-2 font-weight-bold text-dark small">
                                  Open At
                                </p>
                                <Input
                                  type="time"
                                  name="opensAt"
                                  value={hour?.opensAt}
                                  disabled={hour?.closed}
                                  onChange={(e) => {
                                    let idx = _.findIndex(this.state.hours, {
                                      day: hour?.day,
                                    });

                                    if (idx < 0) {
                                      return;
                                    }

                                    let hours = this.state.hours;
                                    hours[idx].closed = false;
                                    hours[idx].opensAt = e.target.value;

                                    console.log(e.target.value);

                                    this.setState({ hours });
                                  }}
                                ></Input>
                              </Col>
                              <Col xs="12" sm="6">
                                <p className="mb-2 font-weight-bold text-dark small">
                                  Closed At
                                </p>
                                <Input
                                  type="time"
                                  name="closesAt"
                                  value={hour?.closesAt}
                                  disabled={hour?.closed}
                                  onChange={(e) => {
                                    let idx = _.findIndex(this.state.hours, {
                                      day: hour?.day,
                                    });

                                    if (idx < 0) {
                                      return;
                                    }

                                    let hours = this.state.hours;
                                    hours[idx].closed = false;
                                    hours[idx].closesAt = e.target.value;

                                    this.setState({ hours });
                                  }}
                                ></Input>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        {this.formatHours(this.state.store)?.map((hour) => (
                          <div className="p-3 border-bottom" key={hour?.day}>
                            <Row className="align-items-center">
                              <Col xs="12" md="2" lg="2" className="pr-md-0">
                                <p className="m-0 font-weight-bold text-dark">
                                  {hour?.day}:
                                </p>
                              </Col>
                              <Col xs="12" md="10" lg="10">
                                {hour?.closed ? (
                                  <p className="m-0">Closed</p>
                                ) : (
                                  <>
                                    <p
                                      className="m-0"
                                      style={{ lineHeight: 1.2 }}
                                    >
                                      {this.convertMilitaryToAMPM(
                                        hour?.opensAt
                                      )}{" "}
                                      -{" "}
                                      {this.convertMilitaryToAMPM(
                                        hour?.closesAt
                                      )}
                                    </p>
                                  </>
                                )}
                              </Col>
                            </Row>
                          </div>
                        ))}
                      </>
                    )}
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
          <Card className="border shadow" style={{ height: "100%" }}>
            <CardHeader className="p-3">
              <Row className="align-items-center">
                <Col xs="">
                  <h3 className="mb-0">Conversation Personalization</h3>
                </Col>
                <Col xs="auto">
                  {this.state.editConversation ? (
                    <>
                      <Button
                        size="sm"
                        className="my-0"
                        outline
                        color="dark"
                        onClick={() => {
                          this.setState({ editConversation: false });
                        }}
                        disabled={this.state.conversationSubmitting}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="sm"
                        className="my-0"
                        color="primary"
                        disabled={this.state.conversationSubmitting}
                        onClick={() => {
                          this.saveStoreConversationPersonas();
                        }}
                      >
                        {this.state.conversationSubmitting ? (
                          <Spinner size="sm"></Spinner>
                        ) : (
                          "Save"
                        )}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        size="sm"
                        className="m-0"
                        outline
                        onClick={() => {
                          this.setState({
                            editConversation: true,
                            conversationPersonas: this.state.store
                              ?.conversationPersonas
                              ? this.state.store?.conversationPersonas
                              : [],
                          });
                        }}
                      >
                        Edit
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-0">
              <div className="p-3">
                <Row className="align-items-center">
                  <Col xs="12">
                    <Row className="mb-1 align-items-center">
                      <Col xs="">
                        <h3 className="mb-0 font-weight-bold text-dark">
                          Staff Personas
                        </h3>
                        <p className="small mb-2 text-dark">
                          Enter the names you'd like Conversations to use when
                          automatically communicating with members &amp; leads.
                          We recommend using only first names.
                        </p>
                        {this.state.editConversation && (
                          <p className="small mb-2 text-dark">
                            Type the name in the field below and press enter or
                            comma to add it to the list. You can add multiple
                            names.
                          </p>
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs="12">
                    {this.state.editConversation ? (
                      <>
                        <TagInput
                          placement="auto"
                          preventOverflow={true}
                          placeholder="Enter Names"
                          block
                          onChange={(v) => {
                            this.setState({
                              conversationPersonas: v,
                            });
                          }}
                          trigger={["Enter", "Comma"]}
                          value={this.state.conversationPersonas}
                        ></TagInput>
                      </>
                    ) : (
                      <div
                        style={{
                          whiteSpace: "break-word",
                        }}
                      >
                        {this.state.store?.conversationPersonas?.length
                          ? this.state.store.conversationPersonas.map(
                              (persona, i) => (
                                <Badge
                                  color="light"
                                  key={i}
                                  className="mr-2 border text-capitalize text-truncate"
                                  style={{ fontSize: 14 }}
                                >
                                  {persona}
                                </Badge>
                              )
                            )
                          : "(none set)"}
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Container>
      </>
    );
  }
}

export default StorefrontOnlinePresenceView;
