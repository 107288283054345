import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import StorefrontStaffMemberDrawer from "components/Drawers/StorefrontStaffMemberDrawer";
import swal from "sweetalert";

class StorefrontStaffListView extends React.Component {
  state = {
    active: {
      id: "All",
      name: "All",
    },
    page: 1,
    count: 25,
    tabs: [
      {
        id: "All",
        name: "All",
      },
    ],
    filters: null,
  };

  componentDidUpdate() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    this.getRoles();

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    PubSub.subscribe(Event.STAFF.CREATED, () => {
      this.loadStaff(this.state.page, this.state.count, this.state.filters);
    });

    PubSub.subscribe(Event.STAFF.MODIFIED, () => {
      this.loadStaff(this.state.page, this.state.count, this.state.filters);
    });
  }

  loadStaff(page = 1, count = 25, filters = null) {
    this.setState({
      loadingStaff: true,
    });

    APIV2.getStaffForStore(
      this.props?.match?.params?.storeID,
      page,
      count,
      filters,
      {
        name: 1,
      }
    )
      .then(
        (data) => {
          if (data && data.data) {
            let staff = [];

            if (data.data.staff.length) {
              staff = data.data.staff;
            }

            this.setState({
              staff,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the staff. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingStaff: false,
        });
      });
  }

  performSearch() {
    console.log(this.state.search);

    APIV2.simpleSearchStaffForStore(
      this.props?.match?.params?.storeID,
      this.state.search
    )
      .then(
        (data) => {
          this.setState({
            searchResultStaff: data.data.staff,
            searchResults: true,
          });
        },
        (e) => {
          console.error(e);

          this.setState({ searchResults: true, searchResultStaff: [] });
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  delete(staff) {
    swal({
      title: "Remove Staff Member?",
      text: `Are you sure you want remove ${staff?.name} as a staff member?`,
      icon: "warning",
      buttons: ["Nevermind", "Remove"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deleteSubmitting: staff?._id,
      });

      APIV2.deleteStoreStaff(staff?.storeID, staff?._id)
        .then(
          (data) => {
            this.loadStaff(
              this.state.page,
              this.state.count,
              this.state.filters
            );
          },
          (e) => {
            console.error(e);
          }
        )
        .finally(() => {
          this.setState({
            deleteSubmitting: false,
          });
        });
    });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadStaff(page, this.state.count, this.state.filters);
  }

  getRoles() {
    this.setState({
      loadingRoles: true,
    });

    APIV2.getStaffRoles(1, 100).then((data) => {
      let tabs = [
        {
          name: "All",
          id: "All",
        },
      ];

      let roles = data?.data?.roles?.length ? data?.data?.roles : [];

      roles = roles.map((role) => {
        let index = 0;

        if (role?.name == "Educator") {
          index = 0;
        } else if (role?.name == "Key Lead") {
          index = 1;
        } else if (role?.name == "Assistant Store Manager") {
          index = 2;
        } else if (role?.name == "Store Manager") {
          index = 3;
        } else if (role?.name == "Franchise Partner") {
          index = 4;
        } else {
          index = 5;
        }

        return {
          name: role.name,
          id: role.name,
          roleID: role._id,
          index,
        };
      });

      roles = _.sortBy(roles, "index");

      tabs = tabs.concat(roles);

      tabs.push({
        name: "Terminated",
        id: "Terminated",
      });

      this.setState({
        tabs,
        loadingRoles: false,
      });
    });
  }

  handleTabChange(option, force = false) {
    let filters = null;

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    if (option.id == "All") {
      filters = {
        terminated: { $ne: true },
      };
    } else if (option.id != "All" && option.id != "Terminated") {
      filters = {
        roleID: option.roleID,
        terminated: { $ne: true },
      };
    } else if (option.id == "Terminated") {
      filters = {
        terminated: true,
      };
    }

    this.setState({
      filters,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadStaff(1, this.state.count, filters);
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Staff Members"
            showBackButton={false}
            primaryAction={{
              name: <>Add</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
            tabs={
              <CardSelectorHeader
                compact={true}
                value={this.state.active}
                options={this.state.tabs}
                loading={this.state.loadingRoles}
                onChange={this.handleTabChange.bind(this)}
              ></CardSelectorHeader>
            }
          />

          <Card
            className="border-0"
            style={{ minHeight: "500px", marginLeft: -15, marginRight: -15 }}
          >
            <CardHeader className="px-3 pt-0">
              <Row className="align-items-center">
                <Col xs="12" sm="">
                  <Input
                    type="text"
                    placeholder="Search staff members..."
                    size="sm"
                    name="search"
                    value={this.state.search}
                    onChange={this.handleSearchChange.bind(this)}
                  ></Input>
                </Col>
              </Row>
              <Row className="align-items-center mt-2">
                {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                <Col xs="" sm="" className="text-dark">
                  {this.state.searchResults ? (
                    <>
                      Found{" "}
                      {this.state.searchResultStaff?.length
                        ? StringUtils.numberFormat(
                            this.state.searchResultStaff?.length
                          )
                        : "0"}{" "}
                      staff members
                      {this.state.searchResultStaff?.length == 1
                        ? ""
                        : "s"}{" "}
                      matching your search.
                    </>
                  ) : (
                    <div>
                      {this.state.result?.start && this.state.result?.end ? (
                        <>
                          Showing{" "}
                          {StringUtils.numberFormat(this.state.result?.start)}-
                          {StringUtils.numberFormat(this.state.result?.end)}
                          {this.state.result?.total ? (
                            <>
                              &nbsp;of{" "}
                              {StringUtils.numberFormat(
                                this.state.result?.total
                              )}{" "}
                              staff members
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>---</>
                      )}
                    </div>
                  )}
                </Col>
                <Col xs="auto" sm="auto" className="pl-2 pl-sm-3 text-right">
                  <Button
                    style={{ height: "28px" }}
                    size="sm"
                    color="secondary"
                    className="btn-icon-only"
                    outline
                    disabled={
                      this.state.page == 1 ||
                      !this.state.pagination?.hasPrevious ||
                      this.state.searchResults
                    }
                    onClick={() => {
                      this.incrementPage(-1);
                    }}
                  >
                    <i
                      className="mdi mdi-chevron-left"
                      style={{ fontSize: "18px" }}
                    ></i>
                  </Button>
                  <Button
                    style={{ height: "28px" }}
                    size="sm"
                    color="secondary"
                    disabled={
                      !this.state.pagination?.hasNext ||
                      this.state.searchResults
                    }
                    className="btn-icon-only"
                    outline
                    onClick={() => {
                      this.incrementPage(1);
                    }}
                  >
                    <i
                      className="mdi mdi-chevron-right"
                      style={{ fontSize: "18px" }}
                    ></i>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="p-0">
              <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                <Row className="align-items-center">
                  <Col xs="6" sm="6" md="4">
                    <h4 className="mb-0">Staff Member</h4>
                  </Col>

                  <Col xs="6" sm="6" md="2" className="text-right text-md-left">
                    <h4 className="mb-0">Position</h4>
                  </Col>
                  <Col xs="6" sm="6" md="3">
                    <h4 className="mb-0">Phone</h4>
                  </Col>
                  <Col xs="6" sm="6" md="3" className="text-right"></Col>
                </Row>
              </div>

              <div>
                {this.state.loadingStaff || this.state.searchLoading ? (
                  <>
                    <div
                      className="text-center p-3"
                      style={{
                        zIndex: 1,
                        position: "absolute",
                        height: "100%",
                        width: "100%",
                        background: "rgba(255, 255, 255, 0.6)",
                      }}
                    >
                      <Spinner
                        style={{ position: "relative", top: "15vh" }}
                        color="medium"
                        size=""
                      ></Spinner>
                    </div>
                  </>
                ) : null}
                {this.state.searchResults ? (
                  <div>
                    {!this.state.searchResultStaff?.length &&
                    !this.state.searchLoading ? (
                      <div className="px-3 py-2">
                        <Row>
                          <Col>
                            No staff members found matching your search "
                            {this.state.search}"
                          </Col>
                        </Row>
                      </div>
                    ) : null}

                    {this.state.searchResultStaff?.map((c, i) => (
                      <div
                        className={`px-3 py-2 bg-superlight--hover cursor-pointer text-dark ${
                          i != this.state.searchResultStaff.length - 1
                            ? "border-bottom"
                            : ""
                        }`}
                        key={i}
                        onClick={() => {
                          this.props.history.push(
                            `/storefront/${this.props?.match?.params?.storeID}/settings/staff/details/${c._id}/summary`
                          );
                        }}
                      >
                        <Row className="align-items-center">
                          <Col xs="6" sm="6" md="4">
                            <h4
                              className={`mb-0 ${
                                c.name ? "text-capitalize" : "text-lowercase"
                              }`}
                            >
                              {c.name ? c.name : c.email}
                              {c?.terminated ? " (Terminated)" : ""}
                            </h4>
                            {c.name ? (
                              <>
                                <p
                                  className="mb-0"
                                  style={{ fontSize: "13px" }}
                                >
                                  {c.email}
                                </p>
                              </>
                            ) : (
                              <>
                                {c.phone ? (
                                  <>
                                    <p
                                      className="mb-0"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {StringUtils.formatPhoneNumber(
                                        c.phone.replace("+1", "")
                                      )}
                                    </p>
                                  </>
                                ) : null}
                              </>
                            )}
                          </Col>
                          <Col
                            xs="6"
                            sm="6"
                            md="2"
                            className="text-right text-md-left"
                          >
                            {c.role?.name ? c.role?.name : "--"}
                          </Col>
                          <Col xs="6" sm="6" md="3">
                            {c.phone
                              ? StringUtils.formatPhoneNumber(c.phone)
                              : "--"}
                          </Col>
                          <Col xs="6" sm="6" md="3" className="text-right">
                            <Button
                              color="secondary"
                              outline
                              className="btn-icon-only"
                              onClick={() => {
                                this.props.history.push(
                                  `/storefront/${this.props?.match?.params?.storeID}/settings/staff/details/${c._id}/summary`
                                );
                              }}
                              size="sm"
                            >
                              <i className="mdi mdi-chevron-right"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    {!this.state.staff?.length && !this.state.loadingStaff ? (
                      <div className="px-3 py-2">
                        <Row>
                          <Col>
                            Get started by creating your first staff member.
                          </Col>
                        </Row>
                      </div>
                    ) : null}

                    {this.state.staff?.map((c, i) => (
                      <div
                        className={`px-3 py-2 bg-superlight--hover cursor-pointer text-dark ${
                          i != this.state.staff.length - 1
                            ? "border-bottom"
                            : ""
                        }`}
                        key={i}
                        onClick={() => {
                          this.props.history.push(
                            `/storefront/${this.props?.match?.params?.storeID}/settings/staff/details/${c._id}/summary`
                          );
                        }}
                      >
                        <Row className="align-items-center">
                          <Col xs="6" sm="6" md="4">
                            <h4
                              className={`mb-0 ${
                                c.name ? "text-capitalize" : "text-lowercase"
                              }`}
                            >
                              {c.name ? c.name : c.email}
                              {c?.terminated ? " (Terminated)" : ""}
                            </h4>
                            {c.name ? (
                              <>
                                <p
                                  className="mb-0"
                                  style={{ fontSize: "13px" }}
                                >
                                  {c.email}
                                </p>
                              </>
                            ) : (
                              <>
                                {c.phone ? (
                                  <>
                                    <p
                                      className="mb-0"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {StringUtils.formatPhoneNumber(
                                        c.phone.replace("+1", "")
                                      )}
                                    </p>
                                  </>
                                ) : null}
                              </>
                            )}
                          </Col>
                          <Col
                            xs="6"
                            sm="6"
                            md="2"
                            className="text-right text-md-left"
                          >
                            {c.role?.name ? c.role?.name : "--"}
                          </Col>
                          <Col xs="6" sm="6" md="3">
                            {c.phone
                              ? StringUtils.formatPhoneNumber(c.phone)
                              : "--"}
                          </Col>
                          <Col xs="6" sm="6" md="3" className="text-right">
                            <Button
                              color="secondary"
                              outline
                              className="btn-icon-only"
                              onClick={() => {
                                this.props.history.push(
                                  `/storefront/${this.props?.match?.params?.storeID}/settings/staff/details/${c._id}/summary`
                                );
                              }}
                              size="sm"
                            >
                              <i className="mdi mdi-chevron-right"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </Container>
        <StorefrontStaffMemberDrawer
          open={this.state.createOpen}
          onClose={() => {
            this.setState({
              createOpen: false,
            });
          }}
          storeID={this?.props?.match?.params?.storeID}
        ></StorefrontStaffMemberDrawer>
      </>
    );
  }
}

export default StorefrontStaffListView;
