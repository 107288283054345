import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex, times } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import {
  Checkbox,
  Dropdown,
  Popover,
  SelectPicker,
  Tooltip,
  Whisper,
} from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import swal from "sweetalert";
import NumberFormat from "react-number-format";
import ConversationListItem from "../../components/Conversation/ConversationListItem";
import ConversationListFilter from "../../components/Conversation/ConversationListFilter";
import ConversationMessagePane from "../../components/Conversation/ConversationMessagePane";
import InfiniteScroll from "react-infinite-scroller";
import ConversationDetailDrawer from "../../components/Drawers/ConversationDetailDrawer";
import Constant from "../../lib/Constant";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";

class StorefrontConversationView extends React.Component {
  state = {
    active: { id: "All" },
    messages: [],
    loadingBottom: false,
    filters: {
      storeID: this.props.match?.params?.storeID,
    },
    orderBy: {
      latestMessageTimestamp: -1,
    },
    newConversation: {
      _id: "new",
      recipientName: "New Conversation",
      latestMessageTimestamp: new Date(),
      unreadCount: 0,
      latestMessage: {
        body: "Start A New Conversation",
      },
    },
    activeConversation: null,
    audioTone: new Audio(Constant.TONES.MESSAGE_NOTIFICATION),
  };

  constructor(props) {
    super(props);

    this.settingsDropdownRef = React.createRef();
  }

  _attachNativeKeyboardHandler() {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    //console.log("TURN OFF RESIZE");

    Keyboard.setResizeMode({ mode: "none" });

    this.setState({
      keyboardResizeMode: "none",
    });

    Keyboard.addListener("keyboardWillHide", () => {
      setTimeout(() => {
        this.setState({
          keyboardOpen: false,
        });
      }, 1);
    });

    Keyboard.addListener("keyboardWillShow", (info) => {
      //console.log(info.keyboardHeight);

      this.setState({
        keyboardOpen: info?.keyboardHeight,
      });
    });
  }

  _detachNativeKeyboardHandler() {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    //console.log("TURN ON RESIZE");

    Keyboard.setResizeMode({ mode: "native" });

    this.setState({ keyboardResizeMode: "native" });
  }

  setTones(turnOn) {
    localStorage.setItem("conversationTones", turnOn);

    this.setState({
      conversationTones: turnOn,
    });
  }

  loadConversationsPage(page) {
    if (this.state.loadingConversationPage || !page) {
      return;
    }

    this.setState({
      loadingConversationPage: true,
    });

    this.loadConversations(
      this.state.conversationPagination?.next,
      12,
      this.state.filters,
      this.state.orderBy,
      false
    );
  }

  loadConversations(
    page = 1,
    count = 12,
    filters = null,
    orderBy = null,
    replace = true
  ) {
    if (replace) {
      this.setState({
        loadingConversations: true,
      });
    }

    APIV2.getConversations(page, count, filters, orderBy)
      .then(
        (data) => {
          if (data && data.data) {
            let conversations = this.state.conversations;

            if (replace) {
              conversations = data?.data?.conversations;
            } else {
              conversations = conversations.concat(data.data.conversations);
            }

            conversations = _.sortBy(conversations, (c) => {
              return moment(c?.latestMessageTimestamp).toDate();
            });

            conversations.reverse();

            //console.log(data.data.pagination);

            this.setState({
              conversations,
              conversationPagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the conversations. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingConversations: false,
          loadingConversationPage: false,
        });
      });

    this.getUnreadCount();
  }

  getUnreadCount() {
    APIV2.getConversationCount({
      unreadCount: { $gt: 0 },
      status: { $in: ["OPEN"] },
      storeID: this.props.match?.params?.storeID,
    }).then(
      (data) => {
        if (data && data.data) {
          let count = data.data.count;

          this.setState({
            unreadCount: count,
          });
        }
      },
      (e) => {
        console.error(e);

        this.setState({
          unreadCount: 0,
        });
      }
    );
  }

  getConversationByID(id, updateList = false, updateActive = true) {
    if (!id) {
      return;
    }

    if (!updateList) {
      this.setState({
        loadingActiveConversation: true,
      });
    }

    APIV2.getConversationByID(id)
      .then(
        (data) => {
          if (data && data.data) {
            let conversation = data.data.conversation;

            if (updateActive) {
              this.setState({
                activeConversation: conversation,
              });
            }

            /*console.log(
              "DETAIL CONVO",
              this.state.detailConversation,
              conversation
            );*/

            if (this.state.detailConversation?._id == conversation?._id) {
              //console.log("MATCH DETAIL");

              this.setState({
                detailConversation: conversation,
              });
            }

            if (updateList) {
              let all = this.state.conversations;

              const idx = _.findIndex(all, { _id: id });

              if (idx >= 0) {
                all[idx] = conversation;
              }

              this.setState({
                conversations: all,
              });
            }
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the current conversation. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingActiveConversation: false,
        });
      });
  }

  markAsRead(id) {
    APIV2.markConversationAsRead(id).then(
      (data) => {
        if (data && data.data) {
          let conversation = data.data.conversation;
        }
      },
      (e) => {
        console.error(e);
      }
    );
  }

  handleTabChange(option, force = false) {
    let filters = null;

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    let allowedStatuses = ["OPEN"];

    if (this.state.showClosed) {
      allowedStatuses.push("CLOSED");
    }

    if (option.id == "All") {
      filters = {
        storeID: this.props.match?.params?.storeID,
        status: { $in: allowedStatuses },
      };
    } else if (option.id == "Unread") {
      filters = {
        storeID: this.props.match?.params?.storeID,
        status: { $in: allowedStatuses },
        unreadCount: { $gt: 0 },
      };
    } else if (option.id == "Members") {
      filters = {
        storeID: this.props.match?.params?.storeID,
        status: { $in: allowedStatuses },
        customerID: { $nin: [null, ""] },
      };
    } else if (option.id == "Leads") {
      filters = {
        storeID: this.props.match?.params?.storeID,
        status: { $in: allowedStatuses },
        leadID: { $nin: [null, ""] },
        customerID: { $in: [null, ""] },
      };
    }

    let orderBy = {
      latestMessageTimestamp: -1,
    };

    this.setState(
      {
        filters,
        active: option,
        orderBy,
      },
      () => {
        if (this.state.search) {
          this.setState({
            searchLoading: true,
            loadingConversations: true,
            oldConversations: this.state.conversations,
          });

          this.performSearch();
        } else {
          this.loadConversations(1, this.state.count, filters, orderBy);
        }
      }
    );

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );
  }

  playTone() {
    if (this.state.audioTone) {
      this.state.audioTone.play();
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
    this._detachNativeKeyboardHandler();
  }

  disableBodyScroll() {
    const mainContent = document.getElementsByClassName(
      "conversation-content-messages"
    );
    const listContent = document.getElementsByClassName(
      "conversation-list-item-container"
    );
    const drawerContent = document.getElementsByClassName("rs-drawer-body");

    if (mainContent?.length) {
      disableBodyScroll(mainContent[0], {
        allowTouchMove: (el) =>
          el.tagName === "TEXTAREA" || el.tagName === "INPUT",
      });
    }

    if (listContent?.length) {
      disableBodyScroll(listContent[0]);
    }

    if (drawerContent?.length) {
      for (let i = 0; i < drawerContent?.length; i++) {
        disableBodyScroll(drawerContent[0][i], {
          allowTouchMove: (el) =>
            el.tagName === "TEXTAREA" || el.tagName === "INPUT",
        });
      }
    }
  }

  componentDidMount() {
    this.disableBodyScroll();
    this._attachNativeKeyboardHandler();

    //console.log(this.props.location, this.props.match);

    this._parseActiveConversationFromURL();

    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      this.handleTabChange(this.state.active, true);
    }

    this.getStore();

    if (localStorage.getItem("conversationTones")) {
      /*console.log(
        typeof localStorage.getItem("conversationTones"),
        localStorage.getItem("conversationTones")
      );*/

      this.setTones(localStorage.getItem("conversationTones") == "true");
    } else {
      this.setTones(true);
    }

    PubSub.subscribe(`ws_conversation-messages:received`, (data) => {
      if (
        data?.storeSent ||
        !this.state.conversationTones ||
        data?.storeID != this.props.match?.params?.storeID ||
        !this.props.match?.params?.storeID ||
        !data.storeID
      ) {
        return;
      }

      this.playTone();
    });

    PubSub.subscribe(`disableBodyScroll`, () => {
      this.disableBodyScroll();
    });

    PubSub.subscribe(`enableBodyScroll`, () => {
      clearAllBodyScrollLocks();
    });

    PubSub.subscribe(`ws_conversations:created`, (data) => {
      //console.log("Conversation Created: ", data);

      if (
        data?.storeID != this.props.match?.params?.storeID ||
        !this.props.match?.params?.storeID ||
        !data.storeID
      ) {
        return;
      }

      if (this.state.search) {
        let conversations = this.state.oldConversations;

        if (this.state.active?.id == "All") {
          conversations = [data].concat(conversations);
        } else if (this.state?.active?.id == "Unread" && data?.unreadCount) {
          conversations = [data].concat(conversations);
        } else if (this.state.active?.id == "Members" && data?.customerID) {
          conversations = [data].concat(conversations);
        } else if (this.state.active?.id == "Leads" && data?.leadID) {
          conversations = [data].concat(conversations);
        }

        this.setState({
          oldConversations: conversations,
        });

        return;
      }

      if (data?.storeID == this.props?.match?.params?.storeID) {
        let conversations = this.state.conversations;

        if (this.state.active?.id == "All") {
          conversations = [data].concat(conversations);
        } else if (this.state?.active?.id == "Unread" && data?.unreadCount) {
          conversations = [data].concat(conversations);
        } else if (this.state.active?.id == "Members" && data?.customerID) {
          conversations = [data].concat(conversations);
        } else if (this.state.active?.id == "Leads" && data?.leadID) {
          conversations = [data].concat(conversations);
        }

        this.setState({
          conversations,
        });

        this.getUnreadCount();
      }
    });

    PubSub.subscribe(Event.CONVERSATION.REASSIGNED, (conversation) => {
      //console.log("Conversation Created: ", data);

      let conversations = this.state.conversations;

      conversations = _.filter(conversations, (i) => {
        return i?._id != conversation?._id;
      });

      this.setState({
        conversations,
      });

      this.getUnreadCount();

      if (conversation?._id == this.state.activeConversation?._id) {
        this.setState({
          activeConversation: null,
        });

        return this.props.history.push(
          `/storefront/${this.props.match?.params?.storeID}/conversations`
        );
      }
    });

    PubSub.subscribe(`ws_conversations:modified`, (data) => {
      //console.log("Conversation Modified: ", data);

      if (
        data?.storeID != this.props.match?.params?.storeID ||
        !this.props.match?.params?.storeID ||
        !data.storeID
      ) {
        return;
      }

      if (data?._id == this.state.activeConversation?._id) {
        this.setState({
          activeConversation: data,
        });
      }

      if (data?.storeID == this.props?.match?.params?.storeID) {
        let conversations = this.state.conversations;

        const idx = _.findIndex(conversations, { _id: data?._id });

        if (idx >= 0) {
          conversations[idx] = data;

          //console.log(idx, data?.status);

          if (this.state.active?.id == "Unread" && !data?.unreadCount) {
            conversations = _.filter(conversations, (c) => {
              return c?._id != data?._id;
            });
          }

          if (!this.state.showClosed) {
            conversations = _.filter(conversations, (c) => {
              return c.status == "OPEN";
            });
          }

          conversations = _.sortBy(conversations, (c) => {
            return moment(c?.latestMessageTimestamp).toDate();
          });

          conversations.reverse();

          this.setState({
            conversations,
          });

          this.getUnreadCount();
        } else {
          if (this.state.active?.id == "Unread" && data?.unreadCount) {
            conversations = [data].concat(conversations);

            this.setState({
              conversations,
            });

            this.getUnreadCount();
          }

          if (!this.state.showClosed && data?.status == "OPEN") {
            //console.log("HERE");

            if (
              this.state.active?.id == "Unread" ||
              this.state.active?.id == "All"
            ) {
              conversations = [data].concat(conversations);
            } else if (this.state.active?.id == "Members" && data?.customerID) {
              conversations = [data].concat(conversations);
            } else if (this.state.active?.id == "Leads" && data?.leadID) {
              conversations = [data].concat(conversations);
            }

            conversations = _.sortBy(conversations, "latestMessageTimestamp");

            conversations.reverse();

            this.setState({
              conversations,
            });
          }
        }
      }
    });

    PubSub.subscribe(Event.CUSTOMER.CREATED, (customer) => {
      if (customer?.phone == this.state.activeConversation?.recipientPhone) {
        this.getConversationByID(this.state.activeConversation?._id, true);
      }

      if (customer?.phone == this.state.detailConversation?.recipientPhone) {
        this.getConversationByID(
          this.state.detailConversation?._id,
          true,
          false
        );
      }
    });

    PubSub.subscribe(Event.CUSTOMER.MODIFIED, (customer) => {
      if (customer?._id == this.state.activeConversation?.customerID) {
        this.getConversationByID(this.state.activeConversation?._id, true);
      }

      if (customer?._id == this.state.detailConversation?.customerID) {
        this.getConversationByID(
          this.state.detailConversation?._id,
          true,
          false
        );
      }
    });

    PubSub.subscribe(Event.LEAD.MODIFIED, (lead) => {
      if (lead?._id == this.state.activeConversation?.leadID) {
        this.getConversationByID(
          this.state.activeConversation?._id,
          true,
          true
        );
      }

      if (lead?._id == this.state.detailConversation?.leadID) {
        this.getConversationByID(
          this.state.detailConversation?._id,
          true,
          false
        );
      }
    });

    PubSub.subscribe(Event.CONVERSATION.VIEW_DETAILS, (c) => {
      //console.log(c?._id);

      this.setState({
        detailConversation: c,
        openDetails: true,
      });
    });

    /**
     * setTimeout(() => {
      if (this?.bottomRef?.current) {
        console.log("HERE");

        this.bottomRef.current.scrollIntoView();
      }
    }, 0);
     * 
     */
  }

  _parseActiveConversationFromURL() {
    let conversationID = this.props.location?.pathname?.replace(
      this.props.match?.url,
      ""
    );

    conversationID = conversationID.replace("/", "");

    if (conversationID == "" && this.state.activeConversation) {
      return this.setState({
        activeConversation: null,
      });
    }

    if (
      !conversationID ||
      this.state.activeConversation?._id == conversationID
    ) {
      return;
    }

    //console.log(conversationID);

    if (
      conversationID != this.state.activeConversation?._id &&
      !this.state.loadingActiveConversation
    ) {
      if (conversationID == "new") {
        this.setState({
          activeConversation: this.state.newConversation,
        });
      } else {
        this.setState({
          activeConversation: {
            _id: conversationID,
          },
        });

        this.getConversationByID(conversationID);
      }
    }
  }

  componentDidUpdate(prevProps) {
    this._parseActiveConversationFromURL();

    if (
      prevProps?.match?.params?.storeID != this.props.match?.params?.storeID
    ) {
      this.setState({
        activeConversation: null,
      });

      this.handleTabChange({ id: "All" }, true);

      if (this.props.match?.params?.storeID) {
        this.getStore();
      }
    }

    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }
  }

  getStore() {
    APIV2.getStoreByID(this.props.match?.params?.storeID).then(
      (data) => {
        this.setState({
          store: data.data.store,
        });
      },
      (e) => {}
    );
  }

  performSearch() {
    const query = this.state.search;

    let numbers = query.replace(/[^\d]/g, ""); //This strips all characters that aren't digits

    let orQuery = [
      { recipientName: { $regex: query, $options: "i" } },

      { leadID: { $regex: query, $options: "i" } },
      {
        customerID: { $regex: query, $options: "i" },
      },
    ];

    if (numbers) {
      orQuery.push({ recipientPhone: { $regex: numbers, $options: "i" } });
    }

    let searchPayload = {
      $or: orQuery,
      storeID: this.props.match?.params?.storeID,
    };

    if (this.state.active?.id == "Unread") {
      searchPayload.unreadCount = { $gt: 0 };
    }

    if (this.state.active?.id == "Members") {
      searchPayload.customerID = { $nin: [null, ""] };
    }

    if (this.state.active?.id == "Leads") {
      searchPayload.leadID = { $nin: [null, ""] };
      searchPayload.customerID = { $in: [null, ""] };
    }

    if (this.state.showClosed) {
      searchPayload.status = { $in: ["OPEN", "CLOSED"] };
    } else {
      searchPayload.status = "OPEN";
    }

    APIV2.getConversations(1, 12, searchPayload, {
      latestMessageTimestamp: -1,
    })
      .then(
        (data) => {
          let conversations = data.data.conversations;

          if (this.state.searchLoading) {
            this.setState({
              oldConversations: this.state.conversations,
              conversations,
              searchLoading: false,
            });
          }
        },
        (e) => {
          console.error(e);

          this.setState({
            conversations: [],
            oldConversations: this.state.conversations,
            searchLoading: false,
          });
        }
      )
      .finally(() => {
        this.setState({
          loadingConversations: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
      loadingConversations: false,
      searchLoading: false,
      conversations: this.state.oldConversations?.length
        ? this.state.oldConversations
        : [],
    });

    this.loadConversations(1, 12, this.state.filters, this.state.orderBy, true);
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
        loadingConversations: true,
        oldConversations: this.state.conversations,
      });
    } else {
      this.clearSearch();

      return;
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  handleSelectedConversation(conversation, selected) {
    console.log(conversation);

    let selectedConversations = this.state.selectedConversations?.length
      ? this.state.selectedConversations
      : [];

    if (selected) {
      if (!selectedConversations.includes(conversation?._id)) {
        selectedConversations.push(conversation?._id);
      }
    } else {
      selectedConversations = _.filter(selectedConversations, (c) => {
        return c != conversation?._id;
      });
    }

    this.setState({ selectedConversations });
  }

  conversationSelected(conversation) {
    if (!this.state.selectedConversations?.length) {
      return false;
    }

    return this.state.selectedConversations?.includes(conversation?._id);
  }

  markAllAsRead(selected) {
    this.setState({
      markingRead: true,
    });

    APIV2.markAllConversationsRead(this.props.match?.params?.storeID, selected)
      .then(
        () => {
          this.loadConversations(
            1,
            12,
            this.state.filters,
            this.state.orderBy,
            true
          );

          swal({
            title: "Conversations Updated",
            text: "Conversations have been successfully marked as read",
            icon: "success",
          });
        },
        (e) => {
          window.alert(
            "There was an error marking the conversations as read. Please reload and try again."
          );
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          markingRead: false,
          selectedConversations: [],
          selectConversations: false,
        });
      });
  }

  closeAll(selected) {
    this.setState({
      closingAll: true,
    });

    APIV2.closeAllConversations(this.props.match?.params?.storeID, selected)
      .then(
        () => {
          this.loadConversations(
            1,
            12,
            this.state.filters,
            this.state.orderBy,
            true
          );

          swal({
            title: "Conversations Closed",
            text: "Conversations have been successfully closed",
            icon: "success",
          });
        },
        (e) => {
          window.alert(
            "There was an error closing the conversations as read. Please reload and try again."
          );
          console.error(e);
        }
      )
      .finally(() => {
        this.setState({
          closingAll: false,
          selectedConversations: [],
          selectConversations: false,
        });
      });
  }

  render() {
    return (
      <>
        {/* Page content */}
        <div className="conversations-container">
          <div
            className={`conversation-list ${
              this.state.activeConversation ? "d-none d-sm-none d-md-block" : ""
            }`}
          >
            <div className="px-3 pt-3 border-bottom">
              <Row className="align-items-center">
                <Col xs="" className="pr-0">
                  <h2 className="mb-0">
                    Conversations
                    {this.state.unreadCount > 0 ? (
                      <Badge
                        className="ml-2 bg-primary text-white"
                        color="primary"
                        style={{
                          position: "relative",
                          top: -2,
                          padding: "0.3rem 0.4rem",
                        }}
                      >
                        {this.state.unreadCount}
                      </Badge>
                    ) : null}
                  </h2>
                </Col>
                <Col xs="auto">
                  <Whisper
                    trigger="click"
                    enterable={true}
                    placement="autoVerticalEnd"
                    ref={this.settingsDropdownRef}
                    speaker={
                      <Popover full className="dropdown-popover">
                        <Dropdown.Menu
                          style={{
                            minWidth: 150,
                            paddingBottom: 0,
                          }}
                        >
                          <Dropdown.Item
                            panel
                            className="font-weight-800 text-uppercase"
                            style={{
                              padding: 12,
                            }}
                          >
                            <h5 className="m-0 text-dark">Settings</h5>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="my-0"
                            divider
                          ></Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              this.settingsDropdownRef.current.close();

                              this.setState({
                                selectConversations:
                                  !this.state.selectConversations,
                                selectedConversations: [],
                              });
                            }}
                            className="text-dark bg-superlight--hover"
                          >
                            <Row className="align-items-center">
                              <Col xs="auto" className="pr-0">
                                <i
                                  className={`mdi mdi-checkbox-marked-circle-outline`}
                                  style={{
                                    fontSize: 16,
                                    lineHeight: 1.3,
                                  }}
                                ></i>
                              </Col>
                              <Col xs="" className="pl-2">
                                <p className="m-0" style={{ fontSize: 14 }}>
                                  {this.state.selectConversations
                                    ? "Done Selecting"
                                    : "Select Conversations"}
                                </p>
                              </Col>
                            </Row>
                          </Dropdown.Item>
                          {this.state.selectConversations ? (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  this.settingsDropdownRef.current.close();

                                  if (
                                    !this.state.selectedConversations?.length
                                  ) {
                                    return;
                                  }

                                  swal({
                                    title: `Mark ${this.state.selectedConversations?.length} As Read?`,
                                    text: `Are you sure you want to mark the ${this.state.selectConversations?.length} selected conversations as read?`,
                                    icon: "warning",
                                    buttons: ["Nevermind", "Mark As Read"],
                                    dangerMode: true,
                                  }).then((conf) => {
                                    if (!conf) {
                                      return;
                                    }

                                    // Mark as read here

                                    this.markAllAsRead(
                                      this.state.selectedConversations
                                    );
                                  });
                                }}
                                className={`${
                                  this.state.markingRead ||
                                  !this.state.selectedConversations?.length
                                    ? "text-light"
                                    : "text-dark bg-superlight--hover"
                                }`}
                                disabled={
                                  this.state.markingRead ||
                                  !this.state.selectedConversations?.length
                                }
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className={`mdi mdi-checkbox-marked-outline`}
                                      style={{ fontSize: 16, lineHeight: 1.3 }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    <p className="m-0" style={{ fontSize: 14 }}>
                                      Mark{" "}
                                      {this.state.selectedConversations
                                        ?.length ?? "Selected"}{" "}
                                      As Read
                                    </p>
                                  </Col>
                                </Row>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  this.settingsDropdownRef.current.close();

                                  if (
                                    !this.state.selectedConversations?.length
                                  ) {
                                    return;
                                  }

                                  swal({
                                    title: `Close ${this.state.selectedConversations?.length} Conversations`,
                                    text: `Are you sure you want to close the ${this.state.selectConversations?.length} selected conversations?`,
                                    icon: "warning",
                                    buttons: ["Nevermind", "Close All"],
                                    dangerMode: true,
                                  }).then((conf) => {
                                    if (!conf) {
                                      return;
                                    }

                                    // Mark as read here

                                    this.closeAll(
                                      this.state.selectedConversations
                                    );
                                  });
                                }}
                                className={`${
                                  this.state.closingAll ||
                                  !this.state.selectedConversations?.length
                                    ? "text-light"
                                    : "text-dark bg-superlight--hover"
                                } `}
                                disabled={
                                  this.state.closingAll ||
                                  !this.state.selectedConversations?.length
                                }
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className={`mdi mdi-close-circle-outline`}
                                      style={{
                                        fontSize: 16,
                                        lineHeight: 1.3,
                                      }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    <p className="m-0" style={{ fontSize: 14 }}>
                                      Close{" "}
                                      {this.state.selectedConversations
                                        ?.length ?? "Selected"}{" "}
                                      Conversations
                                    </p>
                                  </Col>
                                </Row>
                              </Dropdown.Item>
                            </>
                          ) : (
                            <>
                              <Dropdown.Item
                                onClick={() => {
                                  this.settingsDropdownRef.current.close();

                                  swal({
                                    title: "Mark All As Read?",
                                    text: `Are you sure you want to mark all unread conversations as read?`,
                                    icon: "warning",
                                    buttons: ["Nevermind", "Mark As Read"],
                                    dangerMode: true,
                                  }).then((conf) => {
                                    if (!conf) {
                                      return;
                                    }

                                    // Mark as read here

                                    this.markAllAsRead();
                                  });
                                }}
                                className={`${
                                  this.state.markingRead
                                    ? "text-light"
                                    : "text-dark bg-superlight--hover"
                                }`}
                                disabled={this.state.markingRead}
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className={`mdi mdi-checkbox-marked-outline`}
                                      style={{ fontSize: 16, lineHeight: 1.3 }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    <p className="m-0" style={{ fontSize: 14 }}>
                                      Mark All As Read
                                    </p>
                                  </Col>
                                </Row>
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  this.settingsDropdownRef.current.close();

                                  swal({
                                    title: "Close All Conversations",
                                    text: `Are you sure you want to close all your conversations?`,
                                    icon: "warning",
                                    buttons: ["Nevermind", "Close All"],
                                    dangerMode: true,
                                  }).then((conf) => {
                                    if (!conf) {
                                      return;
                                    }

                                    // Mark as read here

                                    this.closeAll();
                                  });
                                }}
                                className={`${
                                  this.state.closingAll
                                    ? "text-light"
                                    : "text-dark bg-superlight--hover"
                                }`}
                                disabled={this.state.closingAll}
                              >
                                <Row className="align-items-center">
                                  <Col xs="auto" className="pr-0">
                                    <i
                                      className={`mdi mdi-close-circle-outline`}
                                      style={{
                                        fontSize: 16,
                                        lineHeight: 1.3,
                                      }}
                                    ></i>
                                  </Col>
                                  <Col xs="" className="pl-2">
                                    <p className="m-0" style={{ fontSize: 14 }}>
                                      Close All Conversations
                                    </p>
                                  </Col>
                                </Row>
                              </Dropdown.Item>
                            </>
                          )}
                          <Dropdown.Item
                            className="my-0"
                            divider
                          ></Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              //this.settingsDropdownRef.current.close();

                              this.setState(
                                {
                                  showClosed: this.state.showClosed
                                    ? false
                                    : true,
                                },
                                () => {
                                  this.handleTabChange(this.state.active, true);
                                }
                              );
                            }}
                            className="text-dark bg-superlight--hover"
                          >
                            <Row className="align-items-center">
                              <Col xs="auto" className="pr-0">
                                <i
                                  className={`mdi mdi-${
                                    this.state.showClosed
                                      ? "checkbox-marked text-primary"
                                      : "checkbox-blank-outline text-dark"
                                  } `}
                                  style={{
                                    fontSize: 16,
                                    lineHeight: 1.3,
                                  }}
                                ></i>
                              </Col>
                              <Col xs="" className="pl-2">
                                <p className="m-0" style={{ fontSize: 14 }}>
                                  View Closed Conversations
                                </p>
                              </Col>
                            </Row>
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              //this.settingsDropdownRef.current.close();

                              this.setTones(!this.state.conversationTones);
                            }}
                            className="text-dark bg-superlight--hover"
                          >
                            <Row className="align-items-center">
                              <Col xs="auto" className="pr-0">
                                <i
                                  className={`mdi mdi-${
                                    this.state.conversationTones
                                      ? "checkbox-marked text-primary"
                                      : "checkbox-blank-outline text-dark"
                                  } `}
                                  style={{
                                    fontSize: 16,
                                    lineHeight: 1.3,
                                  }}
                                ></i>
                              </Col>
                              <Col xs="" className="pl-2">
                                <p className="m-0" style={{ fontSize: 14 }}>
                                  Notification Sounds
                                </p>
                              </Col>
                            </Row>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Popover>
                    }
                  >
                    <Button
                      size="sm"
                      className="btn-icon-only border-0 mr-0"
                      outline
                      color="dark"
                    >
                      <i className="mdi mdi-dots-vertical"></i>
                    </Button>
                  </Whisper>
                  <Button
                    size="sm"
                    className="btn-icon-only border-0"
                    outline
                    color="dark"
                    onClick={() => {
                      this.props.history.push(
                        `/storefront/${this.props.match?.params?.storeID}/conversations/new${window.location.search}`
                      );
                    }}
                  >
                    <i className="mdi mdi-plus"></i>
                  </Button>
                </Col>
              </Row>
              <div className="mt-3" style={{ position: "relative" }}>
                <Input
                  type="text"
                  placeholder="Search Conversations"
                  bsSize="sm"
                  onChange={this.handleSearchChange.bind(this)}
                  name="search"
                  value={this.state.search}
                  style={{ paddingRight: "2rem" }}
                ></Input>
                {this.state.search ? (
                  <Button
                    size="sm"
                    outline
                    color="dark"
                    className="border-0 btn-icon-only"
                    onClick={() => {
                      this.clearSearch();
                    }}
                    style={{
                      top: "0.2rem",
                      right: "0.2rem",
                      position: "absolute",
                      width: "1.6rem",
                      height: "1.6rem",
                      borderRadius: 4,
                    }}
                  >
                    <i className="mdi mdi-close"></i>
                  </Button>
                ) : null}
              </div>
              <ConversationListFilter
                selected={this.state.active?.id}
                onSelect={(v) => {
                  this.handleTabChange({ id: v });
                }}
              ></ConversationListFilter>
            </div>
            <div
              className="conversation-list-item-container"
              style={{
                height: `calc(100% - ${
                  this.state.keyboardOpen ? this.state.keyboardOpen : "0"
                }px - 139px)`,
              }}
            >
              {this.state.loadingConversations ? (
                <>
                  <ConversationListItem skeleton={true}></ConversationListItem>
                  <ConversationListItem skeleton={true}></ConversationListItem>
                  <ConversationListItem skeleton={true}></ConversationListItem>
                  <ConversationListItem skeleton={true}></ConversationListItem>
                </>
              ) : (
                <>
                  {this.state.activeConversation?._id == "new" ? (
                    <>
                      <ConversationListItem
                        conversation={this.state.newConversation}
                        active={this.state.activeConversation?._id == "new"}
                        onClick={(v) => {
                          this.props.history.push(
                            `/storefront/${this.props.match?.params?.storeID}/conversations/new${window.location.search}`
                          );

                          this.setState({
                            activeConversation: this.state.newConversation,
                          });
                        }}
                      ></ConversationListItem>
                    </>
                  ) : null}
                  {this.state.conversations?.length ? (
                    <>
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={this.loadConversationsPage.bind(this)}
                        hasMore={this.state.conversationPagination?.hasNext}
                        loader={
                          <div key={0} className="p-3 text-center">
                            <Spinner size="sm"></Spinner>
                          </div>
                        }
                        isReverse={false}
                        useWindow={false}
                      >
                        {this.state.conversations?.map((conversation) => (
                          <div key={conversation?._id}>
                            <ConversationListItem
                              selectMode={this.state.selectConversations}
                              onSelect={this.handleSelectedConversation.bind(
                                this
                              )}
                              selected={this.conversationSelected(conversation)}
                              conversation={conversation}
                              active={
                                this.state.activeConversation?._id ==
                                conversation?._id
                              }
                              onClick={(v) => {
                                this.props.history.push(
                                  `/storefront/${this.props.match?.params?.storeID}/conversations/${v?._id}${window.location.search}`
                                );

                                //console.log(v);

                                this.markAsRead(v?._id);

                                this.setState({
                                  activeConversation: v,
                                });
                              }}
                            ></ConversationListItem>
                          </div>
                        ))}
                      </InfiniteScroll>
                    </>
                  ) : (
                    <>
                      <p className="small p-3 text-center text-dark">
                        No conversations
                        {this.state.search
                          ? ` match your search "${this.state.search}"`
                          : null}
                        .
                      </p>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div
            className={`${
              this.state.activeConversation?._id
                ? ""
                : "d-none d-sm-none d-md-block"
            } conversation-content`}
          >
            <ConversationMessagePane
              conversation={this.state.activeConversation}
              loading={this.state.loadingActiveConversation}
              newConversation={this.state.activeConversation?._id == "new"}
              emptyState={!this.state.activeConversation}
              onCancel={() => {
                this.props.history.push(
                  `/storefront/${this.props?.match?.params?.storeID}/conversations${window?.location?.search}`
                );
              }}
              store={this.state.store}
              drawerMode={false}
              drawerOpen={false}
            ></ConversationMessagePane>
          </div>
        </div>

        <ConversationDetailDrawer
          open={this.state.openDetails}
          onClose={() => {
            this.setState({ openDetails: false, detailConversation: false });

            PubSub.publish("disableBodyScroll");
          }}
          conversation={this.state.detailConversation}
          onOpen={(ref) => {
            PubSub.publish("enableBodyScroll");
          }}
        ></ConversationDetailDrawer>
      </>
    );
  }
}

export default StorefrontConversationView;
