import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker, Tooltip, Whisper } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import CustomerCreateDrawer from "components/Drawers/CustomerCreateDrawer";
import TaskCreateDrawer from "components/Drawers/TaskCreateDrawer";
import TaskModifyDrawer from "components/Drawers/TaskModifyDrawer";
import TaskDetailDrawer from "components/Drawers/TaskDetailDrawer";
import TechSupportTicketDrawer from "components/Drawers/TechSupportTicketDrawer";
import SupportTicketDetailDrawer from "components/Drawers/SupportTicketDetailDrawer";
import LinkUtils from "lib/LinkUtils";

class StorefrontSupportTicketListView extends React.Component {
  state = {
    active: {
      id: "All Open Tickets",
      name: "All Open Tickets",
    },
    loadingGroups: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    page: 1,
    count: 10,
    filters: null,
    orderBy: {
      dueDate: -1,
    },
    sortByOption: "dueDate",
    sortByDirection: "ascending",
  };

  componentDidUpdate(prevProps) {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab && tab != this.state.active?.id) {
      //console.log("ONE");

      this.handleTabChange({
        id: decodeURIComponent(tab),
        name: decodeURIComponent(tab),
      });
    }

    if (
      prevProps?.match?.params.storeID != this.props.match?.params?.storeID &&
      this.props.match?.params?.storeID &&
      this.props.match?.params?.storeID != "select"
    ) {
      this.handleTabChange(this.state.active, true);
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");

    if (tab) {
      //console.log("TWO");

      this.handleTabChange(
        {
          id: decodeURIComponent(tab),
          name: decodeURIComponent(tab),
        },
        true
      );
    } else {
      //console.log("TWO 2");

      this.handleTabChange(this.state.active, true);
    }

    this.loadStores();

    PubSub.subscribe(Event.TASK.CREATED, (m) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });

    PubSub.subscribe(Event.TASK.MODIFIED, (a) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );

      if (this.state.detailTask?._id == a?._id) {
        this.setState({
          detailTask: a,
        });
      }
    });

    PubSub.subscribe(Event.TASK.DELETED, (a) => {
      this.loadTasks(
        this.state.page,
        this.state.count,
        this.state.filters,
        this.state.orderBy
      );
    });
  }

  onFileChange(e) {
    this.setState({ selectedFile: e.target.files[0] });
  }

  testImageUpload(e) {
    e.preventDefault();

    // Create an object of formData
    let formData = new FormData();

    formData.append("file", this.state.selectedFile);

    console.log(formData);

    API.setMealImage(1, formData).then(
      (data) => {
        console.log(data);
      },
      (e) => {
        console.error(e);
      }
    );
  }

  loadTasks(page = 1, count = 10, filters = null, orderBy) {
    this.setState({
      loadingTasks: true,
    });

    filters.storeID = this.props?.match?.params?.storeID;

    APIV2.getTechSupportTickets(page, count, filters, orderBy)
      .then(
        (data) => {
          if (data && data.data) {
            let tasks = [];

            if (data.data.tickets?.length) {
              tasks = data.data.tickets;
            }

            this.setState({
              tasks,
              pagination: data.data.pagination,
              result: data.data.result,
            });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the tasks. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingTasks: false,
        });
      });
  }

  performSearch() {
    console.log(this.state.search);

    APIV2.simpleSearchTasksForStore(
      this.props?.match?.params?.storeID,
      this.state.search
    )
      .then(
        (data) => {
          this.setState({
            searchResultTasks: data.data.tasks,
            searchResults: true,
          });
        },
        (e) => {
          console.error(e);

          this.setState({ searchResults: true, searchResultTasks: [] });
        }
      )
      .finally(() => {
        this.setState({
          searchLoading: false,
        });
      });
  }

  clearSearch() {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);

      this.setState({
        searchTimeout: null,
      });
    }

    this.setState({
      search: "",
    });
  }

  handleTabChange(option, force = false) {
    let filters = null;
    let orderBy = {
      dueDate: 1,
    };

    if (this.state.active?.id == option?.id && !force) {
      return;
    }

    console.log("LOAD");

    if (option.id == "All Open Tickets") {
      filters = {
        type: "Fran-Support",
        status: { $nin: ["Resolved"] },
        supportTicket: true,
      };
      orderBy = { createdAt: -1 };
    } else if (option.id == "Response Requested") {
      filters = {
        type: "Fran-Support",
        supportTicket: true,
        requiresResponse: true,
        status: { $nin: ["Resolved"] },
      };
      orderBy = { createdAt: -1 };
    } else if (option.id == "All Resolved Tickets") {
      filters = {
        type: "Fran-Support",
        status: { $in: ["Resolved"] },
        supportTicket: true,
      };
      orderBy = { createdAt: -1 };
    }

    this.setState({
      filters,
      orderBy,
      active: option,
    });

    // Construct URLSearchParams object instance from current URL querystring.
    var queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set("tab", encodeURIComponent(option.id));
    window.history.pushState(
      null,
      null,
      window.location.pathname + "?" + queryParams.toString()
    );

    this.loadTasks(1, this.state.count, filters, orderBy);
  }

  handleSearchChange(e) {
    const { name, value } = e.target;

    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    if (value) {
      this.setState({
        searchLoading: true,
      });
    } else {
      this.setState({
        searchLoading: false,
        searchResults: false,
      });
    }

    if (value && value.length >= 3) {
      let timeout = setTimeout(() => {
        this.performSearch();
      }, 1200);

      this.setState({
        searchTimeout: timeout,
      });
    }

    this.setState({
      [name]: value,
    });
  }

  loadStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStoreByID(this.props?.match?.params?.storeID)
      .then(
        (data) => {
          if (data?.data?.store) {
            let store = data.data.store;

            this.setState({ store });
          }
        },
        (e) => {
          console.error(e);
          window.alert(
            "There was an error loading the stores. Please reload and try again."
          );
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  incrementPage(count) {
    let page = this.state.page ? this.state.page + count : 1 + count;

    if (page < 1) {
      page = 1;
    }

    this.setState({ page });

    this.loadTasks(
      page,
      this.state.count,
      this.state.filters,
      this.state.orderBy
    );
  }

  formatDueDate(dateString, status) {
    if (!dateString) {
      return "--";
    }

    console.log(dateString);

    let now = moment();
    let due = moment(dateString);

    return due.format("MMM DD, YYYY");
  }

  formatStatus(status) {
    if (!status) {
      return "--";
    }

    return status;
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Franchise Support Tickets"
            showBackButton={false}
            primaryAction={{
              name: <>Create</>,
              onClick: () => {
                this.setState({
                  createOpen: true,
                });
              },
            }}
          />

          <Row>
            <div className="col">
              <Card className=" shadow" style={{ minHeight: "500px" }}>
                <CardSelectorHeader
                  compact={true}
                  value={this.state.active}
                  options={[
                    {
                      name: "All Open Tickets",
                      id: "All Open Tickets",
                    },
                    {
                      name: "Response Requested",
                      id: "Response Requested",
                    },
                    {
                      name: "All Resolved Tickets",
                      id: "All Resolved Tickets",
                    },
                  ]}
                  onChange={this.handleTabChange.bind(this)}
                ></CardSelectorHeader>
                <CardHeader className="p-3">
                  <Row className="align-items-center">
                    {/*<Col xs="auto" sm="auto" className="pr-0">
                      <Checkbox
                        indeterminate={true}
                        checked={true}
                        onChange={() => {}}
                      ></Checkbox>
                </Col>*/}
                    <Col xs="" sm="" className="">
                      {this.state.search ? (
                        <>
                          Found{" "}
                          {this.state.searchTasks?.length
                            ? StringUtils.numberFormat(
                                this.state.searchTasks?.length
                              )
                            : "0"}{" "}
                          task
                          {this.state.searchTasks?.length == 1 ? "" : "s"}{" "}
                          matching your search.
                        </>
                      ) : (
                        <div>
                          {this.state.result?.start &&
                          this.state.result?.end ? (
                            <>
                              Showing{" "}
                              {StringUtils.numberFormat(
                                this.state.result?.start
                              )}
                              -
                              {StringUtils.numberFormat(this.state.result?.end)}
                              {this.state.result?.total ? (
                                <>
                                  &nbsp;of{" "}
                                  {StringUtils.numberFormat(
                                    this.state.result?.total
                                  )}{" "}
                                  tickets
                                </>
                              ) : null}
                            </>
                          ) : (
                            <>---</>
                          )}
                        </div>
                      )}
                    </Col>
                    <Col
                      xs="auto"
                      sm="auto"
                      className="pl-2 pl-sm-3 text-right"
                    >
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        className="btn-icon-only"
                        outline
                        disabled={
                          this.state.page == 1 ||
                          !this.state.pagination?.hasPrevious ||
                          this.state.searchResults
                        }
                        onClick={() => {
                          this.incrementPage(-1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-left"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                      <Button
                        style={{ height: "28px" }}
                        size="sm"
                        color="secondary"
                        disabled={
                          !this.state.pagination?.hasNext ||
                          this.state.searchResults
                        }
                        className="btn-icon-only"
                        outline
                        onClick={() => {
                          this.incrementPage(1);
                        }}
                      >
                        <i
                          className="mdi mdi-chevron-right"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="py-2 px-3 bg-superlight border-bottom border-top text-dark">
                    <Row className="align-items-center">
                      <Col xs="6" sm="6" md="4">
                        <h4 className="mb-0">Subject</h4>
                      </Col>

                      <Col xs="6" sm="6" md="3">
                        <h4 className={`mb-0 cursor-pointer`}>Created</h4>
                      </Col>
                      <Col xs="6" sm="6" md="2">
                        <h4 className="mb-0">Status</h4>
                      </Col>
                      <Col xs="6" sm="6" md="3">
                        <h4 className="mb-0">Assigned To</h4>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    {this.state.loadingTasks || this.state.searchLoading ? (
                      <>
                        <div
                          className="text-center p-3"
                          style={{
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            background: "rgba(255, 255, 255, 0.6)",
                          }}
                        >
                          <Spinner
                            style={{ position: "relative", top: "15vh" }}
                            color="medium"
                            size=""
                          ></Spinner>
                        </div>
                      </>
                    ) : null}
                    <div>
                      {!this.state.tasks?.length && !this.state.loadingTasks ? (
                        <div className="px-3 py-2">
                          <Row>
                            <Col>No tickets found.</Col>
                          </Row>
                        </div>
                      ) : null}

                      {this.state.tasks?.map((c, i) => (
                        <div
                          className={`px-3 py-2 bg-superlight--hover cursor-pointer ${
                            i != this.state.tasks.length - 1
                              ? "border-bottom"
                              : ""
                          }`}
                          key={i}
                          onClick={() => {
                            this.setState({
                              detailTask: c,
                              detailOpen: true,
                            });
                          }}
                        >
                          <Row className="align-items-center">
                            <Col xs="6" sm="6" md="4">
                              <h4 className="mb-0">
                                {c?.requiresResponse &&
                                  c?.status != "Resolved" && (
                                    <Whisper
                                      trigger="hover"
                                      delayOpen={200}
                                      placement="auto"
                                      speaker={
                                        <Tooltip>
                                          <p className="m-0 text-white">
                                            Your response to this ticket is
                                            requested.
                                          </p>
                                        </Tooltip>
                                      }
                                    >
                                      <i
                                        className="mdi mdi-chat-alert text-danger pr-2"
                                        style={{ position: "relative", top: 1 }}
                                      ></i>
                                    </Whisper>
                                  )}
                                {c.subject}
                              </h4>
                              <p className="mb-0" style={{ fontSize: "13px" }}>
                                {c.supportType}
                                {c?.customer ? ` for ${c?.customer?.name}` : ""}
                                {c?.lead
                                  ? ` for ${
                                      c?.lead?.name
                                        ? c?.lead?.name
                                        : c?.lead?.email
                                    }`
                                  : ""}
                              </p>
                            </Col>
                            <Col xs="6" sm="6" md="3">
                              {moment(c?.createdAt).format("MMM DD, YYYY")}
                            </Col>
                            <Col xs="6" sm="6" md="2">
                              {this.formatStatus(c.status)}
                            </Col>
                            <Col xs="6" sm="6" md="3">
                              {c?.assignedTo?.name
                                ? c?.assignedTo?.name
                                : "Pending"}
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <TechSupportTicketDrawer
          store={
            this.state.store
              ? this.state.store
              : { _id: this.props?.match?.params?.storeID }
          }
          open={this.state.createOpen}
          storeMode={true}
          onClose={() => {
            this.setState({
              createOpen: false,
            });
          }}
          onCreate={(c) => {
            PubSub.publish(Event.TASK.CREATED, c);
          }}
        ></TechSupportTicketDrawer>
        <SupportTicketDetailDrawer
          store={this.state.store}
          open={this.state.detailOpen}
          task={this.state.detailTask}
          storeMode={true}
          onClose={() => {
            this.setState({
              detailOpen: false,
              detailTask: null,
            });
          }}
        ></SupportTicketDetailDrawer>
      </>
    );
  }
  f;
}

export default StorefrontSupportTicketListView;
