import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, Drawer, SelectPicker } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import swal from "sweetalert";
import { Capacitor } from "@capacitor/core";
import LinkUtils from "lib/LinkUtils";

import { Filesystem, Directory } from "@capacitor/filesystem";
import axios from "axios";
import { Share } from "@capacitor/share";
import ContentCalendarWidget from "../../components/Widgets/ContentCalendarWidget";
import NewContentDrawer from "../../components/Drawers/NewContentDrawer";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import FileUploader from "../../components/FileUploader";

class ContentCalendarView extends React.Component {
  state = {
    store: null,
  };

  openFile(url) {
    LinkUtils.openInNewTab(url);
  }

  componentDidUpdate() {}

  componentDidMount() {}

  copyToClipboard(val) {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(val);
    }
  }

  async base64FromBlob(blob) {
    console.log(typeof blob);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        if (typeof reader.result === "string") {
          resolve(reader.result);
        } else {
          reject("method did not return a string");
        }
      };

      reader.readAsDataURL(blob);
    });
  }

  userIsGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  render() {
    return (
      <>
        {/* Page content */}

        <Container className="main-content-padding" fluid>
          <PageHeader
            title="Content Calendar"
            showBackButton={false}
            primaryAction={
              this.userIsGlobal()
                ? {
                    name: "Add",
                    onClick: () => {
                      this.setState({
                        newContent: true,
                      });
                    },
                  }
                : null
            }
          />
          {/* Table */}
          <div>
            <ContentCalendarWidget
              store={this.state.store}
            ></ContentCalendarWidget>
            <NewContentDrawer
              open={this.state.newContent}
              onClose={() => {
                this.setState({ newContent: false });
              }}
            ></NewContentDrawer>
          </div>
        </Container>
      </>
    );
  }
}

export default ContentCalendarView;
