import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
  Badge,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import { SelectPicker } from "rsuite";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import Constant from "../../lib/Constant";
import _ from "underscore";

class LeadSourceStatusWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    name: "",
    email: "",
  };

  updateStatus() {
    this.setState({ statusSubmitting: true });

    console.log(this.state.selectedStatus);

    APIV2.setLeadVariable(
      this.props.lead?.storeID,
      this.props?.lead?._id,
      "status",
      this.state.selectedStatus ? this.state.selectedStatus : null
    )
      .then(
        (data) => {
          PubSub.publish(Event.LEAD.MODIFIED, data.data.lead);

          this.setState({ modifyStatus: false });
        },
        (e) => {
          this.setError("error", "Could not update lead, please try again.");
        }
      )
      .finally(() => {
        this.setState({ statusSubmitting: false });
      });
  }

  updateSource() {
    this.setState({ sourceSubmitting: true });

    let promises = [];

    APIV2.setLeadVariable(
      this.props.lead?.storeID,
      this.props?.lead?._id,
      "initialSourceType",
      this.state.selectedSourceType ? this.state.selectedSourceType : null
    ).then(
      (data) => {
        APIV2.setLeadVariable(
          this.props.lead?.storeID,
          this.props?.lead?._id,
          "initialSource",
          this.state.selectedSource ? this.state.selectedSource : null
        )
          .then(
            (data) => {
              PubSub.publish(Event.LEAD.MODIFIED, data.data?.lead);

              this.setState({ modifySource: false });
            },
            () => {
              this.setError(
                "error",
                "Unable to modify lead. Please try again."
              );
            }
          )
          .finally(() => {
            this.setState({ sourceSubmitting: false });
          });
      },
      () => {
        this.setError("error", "Unable to modify lead. Please try again.");

        this.setState({ sourceSubmitting: false });
      }
    );
  }

  getStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStoresForUser()
      .then(
        (data) => {
          let storeOptions = data.data.stores?.map((s) => {
            return {
              value: s._id,
              label: s.name.replace("Project LeanNation", "PLN"),
            };
          });

          this.setState({
            stores: data.data.stores,
            storeOptions: storeOptions,
          });
        },
        (e) => {
          this.setError("error", "Could not fetch stores.");
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  getStoreStaff() {
    this.setState({
      loadingCoaches: true,
    });

    APIV2.getStaffForStore(this?.props?.lead?.storeID, 1, 10000, {
      position: { $nin: ["Terminated", "Applicant"] },
      terminated: { $ne: true },
    })
      .then(
        (data) => {
          let coachOptions = data.data.staff?.map((s) => {
            return {
              value: s._id,
              label: s.name,
            };
          });

          this.setState({
            coaches: data.data.staff,
            coachOptions: coachOptions,
          });
        },
        (e) => {
          this.setError("error", "Could not fetch coaches.");
        }
      )
      .finally(() => {
        this.setState({
          loadingCoaches: false,
        });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  formatStatus(c) {
    if (c?.status == "Not Contacted") {
      return (
        <Badge color="" className="bg-yellow text-dark">
          {c?.status}
        </Badge>
      );
    }

    if (c?.status == "Attempted") {
      return <Badge color="info">{c?.status}</Badge>;
    }

    if (c?.status == "closedwon") {
      return <Badge color="success">Converted</Badge>;
    }

    if (c?.status == "Inactive") {
      return <Badge color="light">{c?.status}</Badge>;
    }

    return "--";
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  componentDidMount() {
    this.getStores();
    this.getStoreStaff();
  }

  componentDidUpdate(prevProps) {
    if (this.props.lead != prevProps.lead) {
      this.getStoreStaff();
    }
  }

  render() {
    return (
      <>
        <Card className="mb-4 shadow border">
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Status &amp; Source</h3>
              </div>
            </Row>
          </CardHeader>
          {this.props.loading ? (
            <CardBody>
              <Spinner size="sm" color="dark" className="mr-2" /> Loading...
            </CardBody>
          ) : (
            <>
              <CardBody className="bg-transparent p-0">
                <div className="px-4 py-3 border-bottom">
                  <Row className="align-items-center">
                    <Col xs="auto">Status:</Col>
                    <Col xs="" className="pl-0">
                      {!this.state.modifyStatus ? (
                        <>{this.formatStatus(this.props?.lead)}</>
                      ) : null}
                    </Col>
                    <Col xs="auto">
                      {this.state.modifyStatus ? (
                        <>
                          <Button
                            onClick={() => {
                              this.setState({ modifyStatus: false });
                            }}
                            size="sm"
                            color="secondary"
                            outline
                            disabled={this.state.statusSubmitting}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.updateStatus();
                            }}
                            disabled={
                              this.state.statusSubmitting ||
                              !this.state.selectedStatus
                            }
                            size="sm"
                            color="primary"
                          >
                            {this.state.statusSubmitting ? (
                              <>
                                <Spinner size="sm" color="white" />
                              </>
                            ) : (
                              <>Save</>
                            )}
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => {
                            this.setState({
                              modifyStatus: true,
                              selectedStatus: this.props.lead?.status,
                            });
                          }}
                          size="sm"
                          color="secondary"
                          outline
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                    {this.state.modifyStatus ? (
                      <Col xs="12" className="mt-3">
                        <SelectPicker
                          placement="auto"
                          placeholder="Select A Status"
                          data={Constant.SELECT_PICKER.LEAD_STATUSES}
                          value={this.state.selectedStatus}
                          cleanable={false}
                          block
                          onChange={(value, item) => {
                            this.setState({ selectedStatus: value });
                          }}
                        ></SelectPicker>
                      </Col>
                    ) : null}
                  </Row>
                </div>
                <div className="px-4 py-3 border-bottom">
                  <Row className="align-items-center">
                    <Col xs="auto">Source:</Col>
                    <Col xs="" className="pl-0">
                      {this.props.lead?.initialSourceType
                        ? `(${this.props.lead?.initialSourceType}) `
                        : null}
                      {this.props.lead?.initialSource
                        ? this.props.lead?.initialSource
                        : "--"}
                    </Col>
                    <Col xs="auto">
                      {this.state.modifySource ? (
                        <>
                          <Button
                            onClick={() => {
                              this.setState({ modifySource: false });
                            }}
                            size="sm"
                            outline
                            color="secondary"
                            disabled={this.state.sourceSubmitting}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.updateSource();
                            }}
                            disabled={this.state.sourceSubmitting}
                            size="sm"
                            color="primary"
                          >
                            {this.state.sourceSubmitting ? (
                              <>
                                <Spinner size="sm" color="white" />
                              </>
                            ) : (
                              <>Save</>
                            )}
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => {
                            this.setState({
                              modifySource: true,
                              selectedSource: this.props.lead?.initialSource,
                            });
                          }}
                          size="sm"
                          color="secondary"
                          outline
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                    {this.state.modifySource ? (
                      <Col xs="12" className="mt-3">
                        <SelectPicker
                          placement="auto"
                          placeholder="Select A Source"
                          data={Constant.SELECT_PICKER.LEAD_SOURCES}
                          value={this.state.selectedSource}
                          cleanable={false}
                          groupBy="type"
                          block
                          onChange={(value, item) => {
                            let i = _.findWhere(
                              Constant.SELECT_PICKER.LEAD_SOURCES,
                              { value }
                            );

                            this.setState({
                              selectedSource: value,
                              selectedSourceType: i?.type,
                            });
                          }}
                        ></SelectPicker>
                      </Col>
                    ) : null}
                  </Row>
                </div>
              </CardBody>
            </>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(LeadSourceStatusWidget);
