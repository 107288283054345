import APIV2 from "lib/APIV2";
import Constant from "lib/Constant";
import Event from "lib/Event";
import LocalStorage from "lib/LocalStorage";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
  Badge,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import { SelectPicker } from "rsuite";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";

class LeadStoreAssignmentWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    name: "",
    email: "",
  };

  updateLocation() {
    this.setState({ locationSubmitting: true });

    console.log(this.state.selectedStore);

    APIV2.assignLeadToStore(this.state.selectedStore, this.props?.lead?._id)
      .then(
        (data) => {
          this.props.history.push(
            "/storefront/" +
              data?.data?.lead?.storeID +
              "/leads/details/" +
              data.data.lead._id +
              "/summary",
            "_self"
          );

          PubSub.publish(Event.LEAD.MODIFIED, data.data.lead);

          this.setState({ modifyLocation: false });
        },
        (e) => {
          this.setError("error", "Could not update lead, please try again.");
        }
      )
      .finally(() => {
        this.setState({ locationSubmitting: false });
      });
  }

  updateCoach() {
    this.setState({ coachSubmitting: true });

    APIV2.setLeadVariable(
      this.props.lead?.storeID,
      this.props?.lead?._id,
      "coachID",
      this.state.selectedCoach ? this.state.selectedCoach : null
    )
      .then(
        (data) => {
          PubSub.publish(Event.LEAD.MODIFIED, data.data.lead);

          this.setState({ modifyCoach: false });
        },
        (e) => {
          this.setError("error", "Could not update lead, please try again.");
        }
      )
      .finally(() => {
        this.setState({ coachSubmitting: false });
      });
  }

  getStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStoresForUser()
      .then(
        (data) => {
          let storeOptions = data.data.stores?.map((s) => {
            return {
              value: s._id,
              label: s.name.replace("Project LeanNation", "PLN"),
            };
          });

          this.setState({
            stores: data.data.stores,
            storeOptions: storeOptions,
          });
        },
        (e) => {
          this.setError("error", "Could not fetch stores.");
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  getStoreStaff() {
    this.setState({
      loadingCoaches: true,
    });

    APIV2.getStaffForStore(this?.props?.lead?.storeID, 1, 10000, {
      position: { $nin: ["Terminated", "Applicant"] },
      terminated: { $ne: true },
    })
      .then(
        (data) => {
          let coachOptions = data.data.staff?.map((s) => {
            return {
              value: s._id,
              label: s.name,
            };
          });

          this.setState({
            coaches: data.data.staff,
            coachOptions: coachOptions,
          });
        },
        (e) => {
          this.setError("error", "Could not fetch coaches.");
        }
      )
      .finally(() => {
        this.setState({
          loadingCoaches: false,
        });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  componentDidMount() {
    this.getStores();
    this.getStoreStaff();
  }

  componentDidUpdate(prevProps) {
    if (this.props.lead != prevProps.lead) {
      this.getStoreStaff();
    }
  }

  isGlobalUser() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  render() {
    return (
      <>
        <Card className="mb-4 shadow border">
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Store Information</h3>
              </div>
            </Row>
          </CardHeader>
          {this.props.loading ? (
            <CardBody>
              <Spinner size="sm" color="dark" className="mr-2" /> Loading...
            </CardBody>
          ) : (
            <>
              <CardBody className="bg-transparent p-0">
                {this.isGlobalUser() && (
                  <div className="px-4 py-3 border-bottom">
                    <Row className="align-items-center">
                      <Col xs="auto">Location:</Col>
                      <Col xs="" className="pl-0">
                        {!this.state.modifyLocation ? (
                          <>
                            {this.props.lead?.store?.name
                              ? this.props.lead?.store?.name
                              : "--"}
                          </>
                        ) : null}
                      </Col>
                      <Col xs="auto">
                        {this.state.modifyLocation ? (
                          <>
                            <Button
                              onClick={() => {
                                this.setState({ modifyLocation: false });
                              }}
                              size="sm"
                              color="secondary"
                              outline
                              disabled={this.state.locationSubmitting}
                            >
                              Cancel
                            </Button>
                            <Button
                              onClick={() => {
                                this.updateLocation();
                              }}
                              disabled={
                                this.state.locationSubmitting ||
                                !this.state.selectedStore
                              }
                              size="sm"
                              color="primary"
                            >
                              {this.state.locationSubmitting ? (
                                <>
                                  <Spinner size="sm" color="white" />
                                </>
                              ) : (
                                <>Save</>
                              )}
                            </Button>
                          </>
                        ) : (
                          <Button
                            onClick={() => {
                              this.setState({
                                modifyLocation: true,
                                selectedStore: this.props.lead?.storeID,
                              });
                            }}
                            size="sm"
                            color="secondary"
                            outline
                          >
                            Edit
                          </Button>
                        )}
                      </Col>
                      {this.state.modifyLocation ? (
                        <Col xs="12" className="mt-3">
                          <SelectPicker
                            placement="auto"
                            loading={this.state.loadingStores}
                            cleanable={false}
                            size="md"
                            placeholder="Select A Store"
                            data={this.state.storeOptions}
                            style={{}}
                            value={this.state.selectedStore}
                            block
                            onChange={(val) => {
                              this.setState({ selectedStore: val });
                            }}
                          />
                        </Col>
                      ) : null}
                    </Row>
                  </div>
                )}
                <div className="px-4 py-3 border-bottom">
                  <Row className="align-items-center">
                    <Col xs="auto">Coach:</Col>
                    <Col xs="" className="pl-0">
                      {this?.props?.lead?.coach?.name ? (
                        this?.props?.lead?.coach?.name
                      ) : (
                        <Badge color="" className="bg-yellow text-dark">
                          None Assigned
                        </Badge>
                      )}
                    </Col>
                    <Col xs="auto">
                      {this.state.modifyCoach ? (
                        <>
                          <Button
                            onClick={() => {
                              this.setState({ modifyCoach: false });
                            }}
                            size="sm"
                            outline
                            color="secondary"
                            disabled={this.state.coachSubmitting}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => {
                              this.updateCoach();
                            }}
                            disabled={this.state.coachSubmitting}
                            size="sm"
                            color="primary"
                          >
                            {this.state.coachSubmitting ? (
                              <>
                                <Spinner size="sm" color="white" />
                              </>
                            ) : (
                              <>Save</>
                            )}
                          </Button>
                        </>
                      ) : (
                        <Button
                          onClick={() => {
                            this.setState({
                              modifyCoach: true,
                              selectedCoach: this.props.lead?.coachID,
                            });
                          }}
                          size="sm"
                          color="secondary"
                          outline
                        >
                          Edit
                        </Button>
                      )}
                    </Col>
                    {this.state.modifyCoach ? (
                      <Col xs="12" className="mt-3">
                        <SelectPicker
                          placement="auto"
                          loading={this.state.loadingCoaches}
                          cleanable
                          size="md"
                          placeholder="Select A Coach"
                          data={this.state.coachOptions}
                          style={{}}
                          value={this.state.selectedCoach}
                          block
                          onChange={(val) => {
                            this.setState({ selectedCoach: val });
                          }}
                        />
                      </Col>
                    ) : null}
                  </Row>
                </div>
              </CardBody>
            </>
          )}
        </Card>
      </>
    );
  }
}

export default withRouter(LeadStoreAssignmentWidget);
