import React from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Collapse,
  Badge,
  Table,
} from "reactstrap";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import API from "lib/API";
import StoreView from "views/examples/StoreView";

import APIV2 from "lib/APIV2";
import Spinner from "reactstrap/lib/Spinner";
import { withRouter } from "react-router";
import moment from "moment";
import { ProgressBar } from "@tremor/react";
import _ from "underscore";
import Constant from "lib/Constant";
import LocalStorage from "lib/LocalStorage";
import LinkUtils from "lib/LinkUtils";
import StringUtils from "../../lib/StringUtils";
import { Drawer } from "rsuite";

class StorePurchaseListWidget extends React.Component {
  state = {
    store: null,
    expanded: [],
  };

  loadNSOTemplate() {
    this.setState({
      templateLoading: true,
    });

    APIV2.getNSOTemplate()
      .then(
        (data) => {
          let template = data?.data?.template;

          let sections = _.filter(_.keys(template), (key) => {
            if (!this.props.sections?.length) {
              return key?.includes("items_");
            }

            return _.contains(this.props.sections, key);
          });

          sections = _.map(sections, (section) => {
            let s = template[section];

            s.key = section;

            return s;
          });

          sections = _.sortBy(sections, "order");

          this.setState({
            template,
            sections,
          });
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          templateLoading: false,
        });
      });
  }

  isComplete(item) {
    let nso = this.props.store?.nso;

    if (nso?.[item?._id]?.completed) {
      return true;
    }

    return false;
  }

  complete(item, status) {
    let user = LocalStorage.get(Constant.CACHE.USER);

    let payload = {
      completed: false,
    };

    if (status == true) {
      payload = {
        completed: true,
        completedBy: {
          _id: user?._id,
          name: user?.name,
          email: user?.email,
        },
        completedAt: new Date(),
      };
    }

    this.setState({
      submitting: item?._id,
    });

    APIV2.modifyStoreNSO(this.props.store?.nsoID, item._id, payload)
      .then(
        (data) => {
          //console.log(data?.data?.nso);

          this.props.onUpdate(data?.data?.nso);
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  }

  componentDidMount() {
    this.loadNSOTemplate();
  }

  calculateSectionCompletion(section) {
    let actions = section?.items;

    if (!actions?.length) {
      return 0;
    }

    let completed = _.filter(actions, (action) => {
      return this.isComplete(action);
    });

    return (completed.length / actions.length) * 100;
  }

  calculateTotalCompletion() {
    let sections = this.state.sections;

    if (!sections?.length) {
      return 0;
    }

    let actions = [];

    for (let i = 0; i < sections.length; i++) {
      actions = actions.concat(sections[i].items);
    }

    if (!actions?.length) {
      return 0;
    }

    let completed = _.filter(actions, (action) => {
      return this.isComplete(action);
    });

    console.log(completed?.length, actions?.length);

    return parseFloat(
      ((completed?.length / actions?.length) * 100)?.toFixed(1)
    );
  }

  getCompletionColor(completion) {
    if (completion == 100) {
      return "emerald";
    }

    if (completion >= 75) {
      return "green";
    }

    if (completion >= 50) {
      return "yellow";
    }

    if (completion >= 49) {
      return "orange";
    }

    return "red";
  }

  filterResults(items, search) {
    if (!search) {
      return items;
    }

    return _.filter(items, (item) => {
      return (
        item?.name?.toLowerCase()?.includes(search?.toLowerCase()) ||
        item?.purchaseGroup?.toLowerCase()?.includes(search?.toLowerCase())
      );
    });
  }

  render() {
    return (
      <>
        {this.state.sections?.map((section, i) => (
          <div key={i}>
            <Card
              className={`shadow border ${
                i != this.state.sections?.length - 1 ? "mb-4" : ""
              }`}
            >
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h3 className="m-0">{section.name}</h3>
                    {section?.description ? (
                      <p
                        className="text-muted mb-0 mt-1 small"
                        style={{ lineHeight: 1.2 }}
                      >
                        {section?.description}
                      </p>
                    ) : null}
                  </Col>
                  <Col xs="auto">
                    <p className="m-0">
                      {parseInt(this.calculateSectionCompletion(section))}%
                      Complete
                    </p>
                  </Col>
                  <Col xs="12">
                    <ProgressBar
                      percentageValue={this.calculateSectionCompletion(section)}
                      color={this.getCompletionColor(
                        this.calculateSectionCompletion(section)
                      )}
                      marginTop="mt-2"
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs="">
                    <Input
                      size="sm"
                      type="text"
                      name="search"
                      id="search"
                      placeholder={`Search ${section.name}`}
                      value={
                        this.state[`search${i}`] ? this.state[`search${i}`] : ""
                      }
                      onChange={(e) => {
                        this.setState({ [`search${i}`]: e.target.value });
                      }}
                    />
                  </Col>
                  {this.state[`search${i}`] ? (
                    <Col xs="auto" className="pl-0">
                      <Button
                        size="sm"
                        outline
                        color="dark"
                        className="btn-icon-only"
                        onClick={() => {
                          this.setState({ [`search${i}`]: null });
                        }}
                      >
                        <i className="mdi mdi-close"></i>
                      </Button>
                    </Col>
                  ) : null}
                </Row>
              </CardHeader>
              {section?.items?.length ? (
                <>
                  <Table className="align-items-center" responsive>
                    <thead className="bg-superlight">
                      <th className="py-2 text-dark font-weight-bold pr-2"></th>
                      <th
                        style={{ minWidth: 90, maxWidth: 90 }}
                        className="py-2 px-0 text-center text-dark font-weight-bold text-center"
                      >
                        Status
                      </th>
                      <th
                        style={{ minWidth: 300, maxWidth: 300 }}
                        className="py-2 text-dark font-weight-bold"
                      >
                        Item
                      </th>
                      <th
                        style={{ minWidth: 70, maxWidth: 70 }}
                        className="py-2 text-dark font-weight-bold text-center"
                      >
                        QTY
                      </th>
                      <th
                        style={{ minWidth: 120, maxWidth: 120 }}
                        className="py-2 text-dark font-weight-bold text-center"
                      >
                        Est. Price
                      </th>
                      <th
                        style={{ minWidth: 120, maxWidth: 120 }}
                        className="py-2 text-dark font-weight-bold text-center"
                      >
                        Total
                      </th>
                      <th
                        style={{ minWidth: 140, maxWidth: 140 }}
                        className="py-2 text-dark font-weight-bold text-center"
                      >
                        Frequency
                      </th>
                    </thead>
                    <tbody>
                      {!this.filterResults(
                        section?.items,
                        this.state[`search${i}`]
                      )?.length ? (
                        <tr>
                          <td colSpan={7} className="text-center py-2">
                            No results found.
                          </td>
                        </tr>
                      ) : null}
                      {this.filterResults(
                        section?.items,
                        this.state[`search${i}`]
                      )?.map((item, i2) => (
                        <>
                          <tr key={i2}>
                            <td className="text-left py-2 pr-2">
                              <Button
                                size="sm"
                                outline={!this.isComplete(item)}
                                color={
                                  this.isComplete(item) ? "success" : "dark"
                                }
                                className="btn-icon-only"
                                onClick={() => {
                                  this.complete(item, !this.isComplete(item));
                                }}
                              >
                                <i className="mdi mdi-check"></i>
                              </Button>
                              {item?.description?.text ||
                              item?.files?.length ? (
                                <Button
                                  size="sm"
                                  outline
                                  color="dark"
                                  className="btn-icon-only"
                                  onClick={() => {
                                    this.setState({
                                      open: true,
                                      details: item,
                                    });
                                  }}
                                >
                                  <i
                                    className={`mdi mdi-information-outline`}
                                  ></i>
                                </Button>
                              ) : null}
                            </td>
                            <td className="text-center py-2 px-0">
                              <div>
                                <h3 className="m-0">
                                  <Badge
                                    color={`${
                                      this.isComplete(item) ? "success" : ""
                                    }`}
                                    className={`border ${
                                      !this.isComplete(item)
                                        ? "bg-yellow text-dark border-dark"
                                        : "border-success"
                                    }`}
                                  >
                                    {this.isComplete(item) ? (
                                      <>Purchased</>
                                    ) : (
                                      <>Needed</>
                                    )}
                                  </Badge>
                                </h3>
                              </div>
                            </td>
                            <td
                              className="py-2 px-2"
                              style={{ maxWidth: 300, whiteSpace: "pre-wrap" }}
                            >
                              <p
                                className="mb-1"
                                style={{ lineHeight: 1.2, fontSize: 12 }}
                              >
                                {item?.required ? (
                                  <span className="pr-1 text-danger">
                                    [REQUIRED]
                                  </span>
                                ) : null}
                                {item?.purchaseGroup}
                              </p>
                              <h3 className="m-0" style={{ fontSize: 14 }}>
                                {item?.link ? (
                                  <a
                                    href={item?.link}
                                    rel="noreferrer"
                                    target="_blank"
                                    onClick={(e) => {
                                      e.preventDefault();

                                      LinkUtils.openInNewTab(item?.link);
                                    }}
                                    className="text-dark text-underline"
                                  >
                                    {item?.name}
                                    <i
                                      className="mdi mdi-open-in-new pl-1"
                                      style={{ position: "relative", top: 1 }}
                                    ></i>
                                  </a>
                                ) : (
                                  item?.name
                                )}
                              </h3>
                              {this.isComplete(item) ? (
                                <p
                                  className="mt-1 mb-0"
                                  style={{ lineHeight: 1.2, fontSize: 12 }}
                                >
                                  Purchased by{" "}
                                  {
                                    this.props.store?.nso?.[item?._id]
                                      ?.completedBy?.name
                                  }{" "}
                                  on{" "}
                                  {moment(
                                    this.props.store?.nso?.[item?._id]
                                      ?.completedAt
                                  ).format("MM/DD/YY")}
                                </p>
                              ) : null}
                            </td>
                            <td className="text-center py-2 px-2">
                              {StringUtils.numberFormat(item?.quantity)}
                            </td>
                            <td className="text-center py-2 px-2">
                              {StringUtils.centsToCurrency(item?.estimatedCost)}
                            </td>
                            <td className="text-center py-2 px-2">
                              {StringUtils.centsToCurrency(
                                item?.estimatedCost * item?.quantity
                              )}
                            </td>
                            <td className="text-center px-2 py-2">
                              {item?.frequency}
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                <CardBody>
                  <p className="m-0">
                    No actions required in the {section.name} stage.
                  </p>
                </CardBody>
              )}
            </Card>
          </div>
        ))}
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.state.open}
          onClose={() => {
            this.setState({
              details: null,
              open: null,
            });
          }}
        >
          <Drawer.Header className="pr-4">
            <h3
              className="m-0"
              style={{ position: "relative", top: "7px", paddingBottom: 10 }}
            >
              Item Details
            </h3>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            <Row className="align-items-center">
              <Col xs="">
                <p className="text-dark m-0">
                  {this.state.details?.purchaseGroup}
                </p>
                <h2 className="mt-0 mb-3">
                  {this.state.details?.link ? (
                    <a
                      href={this.state.details?.link}
                      rel="noreferrer"
                      target="_blank"
                      onClick={(e) => {
                        e.preventDefault();

                        LinkUtils.openInNewTab(this.state.details?.link);
                      }}
                      className="text-dark text-underline"
                    >
                      {this.state.details?.name}
                      <i
                        className="mdi mdi-open-in-new pl-1"
                        style={{ position: "relative", top: 1 }}
                      ></i>
                    </a>
                  ) : (
                    this.state.details?.name
                  )}
                </h2>
                {this.state.details?.required ||
                this.state.details?.specific ? (
                  <>
                    {this.state.details?.required ? (
                      <p>
                        <Badge color="danger" className="border border-danger">
                          Required Purchase
                        </Badge>
                      </p>
                    ) : null}

                    {this.state.details?.specific ? (
                      <div
                        style={{ lineHeight: 1.2 }}
                        className="rounded bg-ultralight text-dark border p-2 my-3"
                      >
                        <span className="text-dark font-weight-bold">
                          Specific Item:&nbsp;
                        </span>
                        The item must be purchased exactly as specified and from
                        the purchase link. It cannot be replaced with an
                        alternative or generic.
                      </div>
                    ) : null}
                  </>
                ) : null}

                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.details?.description?.html,
                  }}
                ></div>
                <div className="my-3">
                  <p className="mb-2 mt-0 text-dark">
                    <span className="font-weight-bold">Quantity: </span>
                    {this.state.details?.quantity}
                  </p>
                  <p className="mb-2 mt-0 text-dark">
                    <span className="font-weight-bold">Estimated Price: </span>

                    {StringUtils.centsToCurrency(
                      this.state.details?.estimatedCost
                    )}
                  </p>
                  <p className="mb-2 mt-0 text-dark">
                    <span className="font-weight-bold">Estimated Total: </span>

                    {StringUtils.centsToCurrency(
                      this.state.details?.estimatedCost *
                        this.state.details?.quantity
                    )}
                  </p>
                  <p className="mb-2 mt-0 text-dark">
                    <span className="font-weight-bold">
                      {" "}
                      Purchase Frequency:{" "}
                    </span>

                    {this.state.details?.frequency
                      ? this.state.details?.frequency
                      : "Once"}
                  </p>
                </div>
                {this.state.details?.description?.text &&
                this.state.details?.files &&
                this.state.details?.files?.length ? (
                  <hr className="my-4"></hr>
                ) : null}
                {this.state.details?.files &&
                this.state.details?.files?.length ? (
                  <>
                    <h4 className="my-4">Files:</h4>
                    {this.state.details?.files?.map((at, i) => (
                      <div className={`border p-2 rounded mb-2`} key={i}>
                        <Row className="align-items-center">
                          <Col xs="12" sm="">
                            <p className="small m-0 text-dark text-truncate">
                              {at?.fileName}
                            </p>
                          </Col>
                          <Col
                            xs="12"
                            sm="auto"
                            className="mt-3 mt-sm-0 text-right"
                          >
                            <Button
                              className="btn-icon-only"
                              size="sm"
                              outline
                              color="secondary"
                              disabled={!at?.url}
                              onClick={() => {
                                if (!at?.url) {
                                  return;
                                }

                                LinkUtils.openPrivateURL(at?.url);
                              }}
                            >
                              <i className="mdi mdi-eye"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </>
                ) : null}
              </Col>
            </Row>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(StorePurchaseListWidget);
