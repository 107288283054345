import React from "react";
import { withRouter } from "react-router";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Button,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import Spinner from "reactstrap/lib/Spinner";
import HapticUtils from "../../lib/HapticUtils";

class PageHeader extends React.Component {
  render() {
    return (
      <>
        <div className="mb-4">
          <Row className="align-items-center">
            {this.props.showBackButton ? (
              <Col xs="auto">
                <Button
                  style={{ height: "43px", width: "43px" }}
                  onClick={(e) => {
                    e.preventDefault();

                    if (this.props.backOverride) {
                      return this.props.history.push(this.props.backOverride);
                    }

                    this.props.history.goBack();
                  }}
                  onTouchStart={(e) => {
                    e.preventDefault();

                    HapticUtils.navigationEvent();

                    if (this.props.backOverride) {
                      return this.props.history.push(this.props.backOverride);
                    }

                    this.props.history.goBack();
                  }}
                  color="secondary"
                  outline
                  className="btn-icon-only d-none d-md-inline-block"
                >
                  <i className="mdi mdi-chevron-left"></i>
                </Button>
                <Button
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();

                    if (this.props.backOverride) {
                      return this.props.history.push(this.props.backOverride);
                    }

                    this.props.history.goBack();
                  }}
                  onTouchStart={(e) => {
                    e.preventDefault();

                    HapticUtils.navigationEvent();

                    if (this.props.backOverride) {
                      return this.props.history.push(this.props.backOverride);
                    }

                    this.props.history.goBack();
                  }}
                  color="secondary"
                  outline
                  className="btn-icon-only d-inline-block d-md-none"
                >
                  <i className="mdi mdi-chevron-left"></i>
                </Button>
              </Col>
            ) : null}
            <Col>
              <h2 className="mb-0 d-none d-sm-block">{this.props.title}</h2>
              <h3 className="mb-0 d-block d-sm-none">{this.props.title}</h3>
            </Col>
            {this.props.secondaryActions &&
            this.props.secondaryActions.length ? (
              <Col
                xs="auto"
                className={`${
                  this.props.primaryAction || this.props.secondaryAction
                    ? "pr-0"
                    : ""
                }`}
              >
                {this.props.secondaryActions.length > 3 ||
                this.props.forceSecondaryDropdown ? (
                  <>
                    <div className="d-none d-md-inline-block">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          color="secondary"
                          className={`btn-icon-only ${
                            this.props.primaryAction ? "mr-0" : ""
                          }`}
                          style={{ height: "43px", width: "43px" }}
                          outline
                        >
                          <i className="mdi mdi-dots-vertical"></i>
                        </DropdownToggle>
                        <DropdownMenu right={true}>
                          {this.props.secondaryActions.map((action, i) => (
                            <DropdownItem
                              key={i}
                              href="#pablo"
                              disabled={action.disabled}
                              onClick={(e) => {
                                e.preventDefault();

                                if (
                                  action.onClick &&
                                  typeof action.onClick == "function"
                                ) {
                                  action.onClick();
                                }
                              }}
                            >
                              {action.name}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="d-none d-md-inline-block">
                      {this.props.secondaryActions.map((action, i) => (
                        <Button
                          color="secondary"
                          outline
                          disabled={action.disabled}
                          key={i}
                          className={`${action?.icon ? "btn-icon-only" : ""}`}
                          onClick={(e) => {
                            e.preventDefault();

                            if (
                              action.onClick &&
                              typeof action.onClick == "function"
                            ) {
                              action.onClick();
                            }
                          }}
                        >
                          {action.name}
                        </Button>
                      ))}
                    </div>
                  </>
                )}
                <div className="d-inline-block d-md-none">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      color="secondary"
                      className={`btn-icon-only btn-sm ${
                        this.props.primaryAction ? "mr-0" : ""
                      }`}
                      outline
                    >
                      <i className="mdi mdi-dots-vertical"></i>
                    </DropdownToggle>
                    <DropdownMenu right={true}>
                      {this.props.secondaryActions.map((action, i) => (
                        <DropdownItem
                          key={i}
                          href="#pablo"
                          disabled={action.disabled}
                          onClick={(e) => {
                            e.preventDefault();

                            if (
                              action.onClick &&
                              typeof action.onClick == "function"
                            ) {
                              action.onClick();
                            }
                          }}
                        >
                          {action.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </Col>
            ) : null}
            {this.props.secondaryAction ? (
              <Col
                xs="auto"
                className={`${this.props.primaryAction ? "pr-0" : ""}`}
              >
                <Button
                  color="secondary"
                  outline
                  className={`d-none d-md-inline-block mr-0 ${
                    this.props.secondaryAction?.iconButton
                      ? "btn-icon-only"
                      : ""
                  }`}
                  disabled={this.props.secondaryAction?.disabled}
                  onClick={(e) => {
                    e.preventDefault();

                    if (
                      this.props.secondaryAction.onClick &&
                      typeof this.props.secondaryAction.onClick == "function"
                    ) {
                      this.props.secondaryAction.onClick();
                    }
                  }}
                >
                  {this.props.secondaryAction.submitting ? (
                    <Spinner size="sm" color="secondary" />
                  ) : (
                    <>{this.props.secondaryAction.name}</>
                  )}
                </Button>
                <Button
                  color="secondary"
                  outline
                  size="sm"
                  className="d-inline-block d-md-none mr-0"
                  disabled={this.props.secondaryAction?.disabled}
                  onClick={(e) => {
                    e.preventDefault();

                    if (
                      this.props.secondaryAction.onClick &&
                      typeof this.props.secondaryAction.onClick == "function"
                    ) {
                      this.props.secondaryAction.onClick();
                    }
                  }}
                >
                  {this.props.secondaryAction.submitting ? (
                    <div className="px-2">
                      <Spinner color="secondary" size="xs" />
                    </div>
                  ) : (
                    <>{this.props.secondaryAction.name}</>
                  )}
                </Button>
              </Col>
            ) : null}
            {this.props.primaryAction ? (
              <Col xs="auto">
                <Button
                  className="d-none d-md-inline-block"
                  color="primary"
                  disabled={this.props.primaryAction?.disabled}
                  onClick={(e) => {
                    e.preventDefault();

                    if (
                      this.props.primaryAction.onClick &&
                      typeof this.props.primaryAction.onClick == "function"
                    ) {
                      this.props.primaryAction.onClick();
                    }
                  }}
                >
                  {this.props.primaryAction.submitting ? (
                    <Spinner size="sm" color="white" />
                  ) : (
                    <>{this.props.primaryAction.name}</>
                  )}
                </Button>
                <Button
                  className="d-inline-block d-md-none"
                  color="primary"
                  disabled={this.props.primaryAction?.disabled}
                  size="sm"
                  onClick={(e) => {
                    e.preventDefault();

                    if (
                      this.props.primaryAction.onClick &&
                      typeof this.props.primaryAction.onClick == "function"
                    ) {
                      this.props.primaryAction.onClick();
                    }
                  }}
                >
                  {this.props.primaryAction.submitting ? (
                    <div className="px-2">
                      <Spinner color="white" size="sm" />
                    </div>
                  ) : (
                    <>{this.props.primaryAction.name}</>
                  )}
                </Button>
              </Col>
            ) : null}
          </Row>
          <hr className="mb-0 mt-3"></hr>
        </div>
      </>
    );
  }
}

export default withRouter(PageHeader);
