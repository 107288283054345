import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import _, { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import { Dropdown, Popover, Tooltip, Whisper } from "rsuite";
import swal from "sweetalert";
import LibraryCategoryEditDrawer from "components/Drawers/LibraryCategoryEditDrawer";
import LibraryModuleEditDrawer from "components/Drawers/LibraryModuleEditDrawer";
import LibraryAllModulesDrawer from "components/Drawers/LibraryAllModulesDrawer";
import { convertTypeAcquisitionFromJson } from "typescript";
import LibraryAllTopicsDrawer from "components/Drawers/LibraryAllTopicsDrawer";
import { Capacitor } from "@capacitor/core";

class LearningCenterHome extends React.Component {
  state = {
    active: 0,
    loadingCategories: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    openCategory: false,
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  componentDidMount() {}

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isProduction() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.productionPermissions?.length ? true : false;
  }

  render() {
    return (
      <>
        <div
          className="bg-white border-bottom px-4 py-4 mb-4"
          style={{ marginTop: "5rem" }}
        >
          {/* Page content */}
          {Capacitor.isNativePlatform() && (
            <div className="pt-5 d-md-none"></div>
          )}
          <Row className="align-items-center">
            <Col xs="12">
              <h2 className="m-0 text-dark">Learning Center</h2>
            </Col>
          </Row>
        </div>

        <Container fluid>
          {/* Table */}

          <Row className="items-center">
            <Col xs="6" sm="6" md="4" className="mb-4">
              <div
                className="bg-superlight p-3 rounded"
                style={{ height: 180 }}
              >
                <h2>Progress</h2>
              </div>
            </Col>
            <Col xs="6" sm="6" md="4" className="mb-4">
              <div
                className="bg-superlight p-3 rounded"
                style={{ height: 180 }}
              >
                <h2>Learning Level</h2>
              </div>
            </Col>
            <Col xs="12" sm="12" md="4" className="mb-4">
              <div
                className="bg-superlight p-3 rounded"
                style={{ height: 180 }}
              >
                <h2>Certificates</h2>
              </div>
            </Col>
          </Row>
          <div
            className="bg-superlight rounded mb-4 p-3"
            style={{ height: 400 }}
          >
            <h2>Learning Tracks</h2>
          </div>
          <div
            className="bg-superlight rounded mb-4 p-3"
            style={{ height: 400 }}
          >
            <h2>Continued Education</h2>
          </div>
        </Container>
      </>
    );
  }
}

export default LearningCenterHome;
