import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import { SelectPicker } from "rsuite";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import _ from "underscore";
import Constant from "lib/Constant";
import StorefrontStaffMemberDrawer from "../Drawers/StorefrontStaffMemberDrawer";
import moment from "moment";
import swal from "sweetalert";

class StaffAccountWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    zip: {
      value: "",
    },
    name: "",
    email: "",
  };

  invite() {
    this.setState({
      inviting: true,
    });

    APIV2.addStaffAccountAccess(
      this.props.match.params.storeID,
      this.props.staff._id
    )
      .then(
        (data) => {
          PubSub.publish(Event.STAFF.MODIFIED, data.data.staff);
        },
        (e) => {}
      )
      .finally(() => {
        this.setState({
          inviting: false,
        });
      });
  }

  delete(user) {
    swal({
      title: "Disable Storefront Access?",
      text: `Are you sure you want remove ${user?.name} as a Storefront user?`,
      icon: "warning",
      buttons: ["Nevermind", "Disable"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deleteSubmitting: true,
      });

      APIV2.deleteStoreUser(this?.props?.match?.params?.storeID, user?._id)
        .then(
          (data) => {
            let staff = this.props.staff;

            staff.user.storePermissions = _.filter(
              staff?.user?.storePermissions,
              (sp) => {
                return sp?.storeID != this?.props?.match?.params?.storeID;
              }
            );

            console.log(staff);

            PubSub.publish(
              Event.STAFF.MODIFIED,
              JSON.parse(JSON.stringify(staff))
            );
          },
          (e) => {
            console.error(e);
          }
        )
        .finally(() => {
          this.setState({
            deleteSubmitting: false,
          });
        });
    });
  }

  userHasPermission() {
    return _.findWhere(this.props.staff?.user?.storePermissions, {
      storeID: this.props.match?.params?.storeID,
    });
  }

  render() {
    return (
      <>
        <Card
          className={`mb-4 ${
            this.props.flat ? "shadow-none" : "shadow"
          } border`}
        >
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Storefront Access</h3>
              </div>
              {this.props.staff?.user?._id && this.userHasPermission() ? (
                <Col xs="auto">
                  <Button
                    size="sm"
                    color="danger"
                    outline
                    disable={this.state.deleteSubmitting}
                    onClick={() => {
                      this.delete(this.props.staff?.user);
                    }}
                  >
                    {this.state.deleteSubmitting ? (
                      <Spinner size="sm" />
                    ) : (
                      "Disable Access"
                    )}
                  </Button>
                </Col>
              ) : (
                <Col xs="auto">
                  <Button
                    size="sm"
                    color="primary"
                    disabled={this.state.inviting}
                    onClick={() => {
                      this.invite();
                    }}
                  >
                    {this.state.inviting ? <Spinner size="sm" /> : "Invite"}
                  </Button>
                </Col>
              )}
            </Row>
          </CardHeader>
          {this.props.loading ? (
            <CardBody>
              <Spinner size="sm" color="dark" className="mr-2" /> Loading...
            </CardBody>
          ) : (
            <CardBody className="p-0">
              <div className="px-4 py-3 border-bottom text-dark">
                <Row className="align-items-center">
                  <Col xs="auto">Access:</Col>
                  <Col xs="" className="pl-0">
                    {this.props.staff?.user?._id && this.userHasPermission()
                      ? "Enabled"
                      : "Not Enabled"}
                  </Col>
                </Row>
              </div>
              {this.props.staff?.user?._id && this.userHasPermission() ? (
                <div className="px-4 py-3 border-bottom text-dark">
                  <Row className="align-items-center">
                    <Col xs="auto">Role:</Col>
                    <Col xs="" className="pl-0">
                      Administrator
                    </Col>
                  </Row>
                </div>
              ) : null}
            </CardBody>
          )}
        </Card>
        <StorefrontStaffMemberDrawer
          open={this.state.openModify}
          onClose={() => {
            this.setState({
              openModify: false,
              modifyStaff: null,
            });
          }}
          staff={this.state.modifyStaff}
          storeID={this?.props?.match?.params?.storeID}
        ></StorefrontStaffMemberDrawer>
      </>
    );
  }
}

export default withRouter(StaffAccountWidget);
