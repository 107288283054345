import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  CheckPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import Constant from "lib/Constant";

class NewConsultationDrawer extends React.Component {
  state = {
    consultationDate: moment()
      .hours(0)
      .minutes(0)
      .seconds(0)
      .millisecond(0)
      .toDate(),
    coachID: null,
    notes: "",
    type: "nc-consult",
    types: [
      {
        label: Constant.APPOINTMENT_TYPES["nc-consult"].SHORT_NAME,
        value: "nc-consult",
      },
      {
        label: Constant.APPOINTMENT_TYPES["ec-consult"].SHORT_NAME,
        value: "ec-consult",
      },
    ],
    phone: {
      value: "",
    },
    hourlyTimeslots: [],
    loadingTimeslots: false,
    goal: [],
  };

  getStoreStaff() {
    this.setState({
      loadingCoaches: true,
    });

    APIV2.getStaffForStore(this.props.store?._id, 1, 10000, {
      position: { $nin: ["Terminated", "Applicant"] },
      terminated: { $ne: true },
    })
      .then(
        (data) => {
          let coachOptions = data.data.staff?.map((s) => {
            return {
              value: s._id,
              label: s.name,
            };
          });

          this.setState({
            coaches: data.data.staff,
            coachOptions: coachOptions,
          });
        },
        (e) => {
          this.setError("error", "Could not fetch coaches.");
        }
      )
      .finally(() => {
        this.setState({
          loadingCoaches: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.consultation != prevProps.consultation &&
      this.props.consultation
    ) {
      this.setState({
        name: this.props.consultation?.customer
          ? this.props.consultation?.customer?.name
          : this.props.consultation?.lead?.name,
        email: this.props.consultation?.email,
        phone: {
          value: this.props.consultation?.phone?.replace("+1", ""),
        },
        goal: this.props.consultation?.goal,
        notes: this.props.consultation?.additionalNotes,
        type: this.props.consultation?.appointmentType,
        consultationDate: moment(this.props.consultation?.startsAt)
          .hour(0)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toDate(),
        consultationTime: moment(this.props.consultation?.startsAt).format(
          "HH:mm"
        ),
      });
    }

    if (
      (this.props.lead != prevProps.lead ||
        this.props.open != prevProps.open) &&
      this.props.lead
    ) {
      this.setState({
        name: this.props.lead?.name,
        email: this.props.lead?.email,
        phone: {
          value: this.props.lead?.phone?.replace("+1", ""),
        },
        type: "nc-consult",
      });
    }

    if (this.props.open != prevProps.open && this.props.open) {
      this.getHourlyTimeslots(this.state.consultationDate);
    }

    if (
      (this.props.customer != prevProps.customer ||
        this.props.open != prevProps.open) &&
      this.props.customer
    ) {
      this.setState({
        name: this.props.customer?.name,
        email: this.props.customer?.email,
        phone: {
          value: this.props.customer?.phone?.replace("+1", ""),
        },
        type:
          this.props.receivedConsult || this.props.receivedCheckin
            ? "ec-consult"
            : "nc-consult",
        types:
          this.props.receivedConsult || this.props.receivedCheckin
            ? [
                {
                  label: Constant.APPOINTMENT_TYPES["nc-consult"].SHORT_NAME,
                  value: "nc-consult",
                },
                {
                  label: Constant.APPOINTMENT_TYPES["ec-consult"].SHORT_NAME,
                  value: "ec-consult",
                },
              ]
            : [
                {
                  label: Constant.APPOINTMENT_TYPES["nc-consult"].SHORT_NAME,
                  value: "nc-consult",
                },
              ],
      });
    }
  }

  componentDidMount() {
    if (this.props.consultation) {
      this.setState({
        name: this.props.consultation?.name,
        email: this.props.consultation?.email,
        phone: {
          value: this.props.consultation?.phone?.replace("+1", ""),
        },
        goal: this.props.consultation?.goal,
        notes: this.props.consultation?.additionalNotes,
        type: this.props.consultation?.appointmentType,
      });
    } else if (this.props.lead) {
      this.setState({
        name: this.props.lead?.name,
        email: this.props.lead?.email,
        phone: {
          value: this.props.lead?.phone?.replace("+1", ""),
        },
        type: "nc-consult",
      });
    } else if (this.props.customer) {
      this.setState({
        name: this.props.customer?.name,
        email: this.props.customer?.email,
        phone: {
          value: this.props.customer?.phone?.replace("+1", ""),
        },
        type:
          this.props.customer?.cohort_received_consult ||
          this.props.customer?.cohort_received_checkin
            ? "ec-consult"
            : "nc-consult",
        types:
          this.props.customer?.cohort_received_consult ||
          this.props.customer?.cohort_received_checkin
            ? [
                {
                  label: Constant.APPOINTMENT_TYPES["nc-consult"].SHORT_NAME,
                  value: "nc-consult",
                },
                {
                  label: Constant.APPOINTMENT_TYPES["ec-consult"].SHORT_NAME,
                  value: "ec-consult",
                },
              ]
            : [
                {
                  label: Constant.APPOINTMENT_TYPES["nc-consult"].SHORT_NAME,
                  value: "nc-consult",
                },
              ],
      });
    }

    if (this.props.customer || this.props.lead) {
      this.setState({
        readOnly: true,
      });
    } else {
      this.setState({
        readOnly: false,
      });
    }
  }

  toggleModal() {
    this.setState({
      notes: "",
      consultationDate: moment()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .millisecond(0)
        .toDate(),
      type: "nc-consult",
      goal: [],
      name: "",
      email: "",
      phone: {
        value: "",
      },
      consultationTime: "",
      walkIn: null,
      coachID: null,
      consultNotes: "",
    });

    this.props.onClose();
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  complete(consultation) {
    return new Promise((resolve, reject) => {
      let payload = {
        staffIDs: [this.state.coachID],
        completionNote: this.state.consultNotes,
        status: "COMPLETED",
      };

      this.setState({ submitting: true });

      APIV2.modifyAppointmentInformation(
        consultation?.storeID,
        consultation?._id,
        payload
      ).then(
        (data) => {
          const appointment = data.data.appointment;

          //this.toggleModal();

          resolve(appointment);
        },
        (e) => {
          reject(
            e?.response?.body?.message ??
              "Unable to update consultation status - unknown error occurred. Try again."
          );
        }
      );
    });
  }

  create() {
    this.setState({ submitting: true });

    let startMoment = moment(this.state.consultationDate?.toISOString());
    let timeMoment = moment(this.state.consultationTime, "HH:mm");

    startMoment.hours(timeMoment.hours()).minutes(timeMoment.minutes());

    let dateTime = startMoment.toDate()?.toISOString();

    if (this.state.walkIn == "yes") {
      dateTime = moment().toDate()?.toISOString();
    }

    let payload = {
      appointmentType: this.state.type,
      dateTime,
      name: this.state.name,
      email: this.state.email,
      phone: "+1" + this.state.phone?.value,
      notes: this.state.notes ? this.state.notes : "",
      goal: this.state.goal ? this.state.goal : [],
      walkIn: this.state.walkIn == "yes",
    };

    APIV2.scheduleAppointment(this.props?.store?._id, payload).then(
      (data) => {
        let appointment = data.data.appointment;

        if (this.state.walkIn == "yes") {
          this.complete(appointment)
            .then(
              (appointment) => {
                this.toggleModal();

                PubSub.publish(Event.APPOINTMENT.CREATED, appointment);
              },
              (e) => {
                this.setError(
                  "error",
                  e ??
                    `Unable to schedule consultation - unknown error occurred. Try again.`
                );
              }
            )
            .finally(() => {
              this.setState({ submitting: false });
            });
        } else {
          this.toggleModal();

          PubSub.publish(Event.APPOINTMENT.CREATED, appointment);

          this.setState({ submitting: false });
        }
      },
      (e) => {
        this.setError(
          "error",
          e?.response?.body?.message ??
            `Unable to schedule consultation - unknown error occurred. Try again.`
        );

        this.setState({ submitting: false });
      }
    );
  }

  modify() {
    this.setState({
      submitting: true,
    });

    if (this.props.mode == "reschedule") {
      let startMoment = moment(this.state.consultationDate?.toISOString());
      let timeMoment = moment(this.state.consultationTime, "HH:mm");

      startMoment.hours(timeMoment.hours()).minutes(timeMoment.minutes());

      let dateTime = startMoment.toDate()?.toISOString();

      APIV2.rescheduleAppointment(
        this.props.consultation?.storeID,
        this.props.consultation?._id,
        dateTime
      )
        .then(
          (data) => {
            const appointment = data.data.appointment;

            this.toggleModal();

            PubSub.publish(Event.APPOINTMENT.MODIFIED, appointment);
          },
          (e) => {
            this.setError(
              "error",
              e?.response?.body?.message ??
                `Unable to reschedule consultation - unknown error occurred. Try again.`
            );
          }
        )
        .finally(() => {
          this.setState({
            submitting: false,
          });
        });
    } else {
      let payload = {
        email: this.state.email,
        phone: this.state.phone?.value,
        notes: this.state.notes ?? "",
        goal: this.state.goal ?? "",
      };

      APIV2.modifyAppointmentInformation(
        this.props.consultation?.storeID,
        this.props.consultation?._id,
        payload
      )
        .then(
          (data) => {
            const appointment = data.data.appointment;

            this.toggleModal();

            PubSub.publish(Event.APPOINTMENT.MODIFIED, appointment);
          },
          (e) => {
            this.setError(
              "error",
              e?.response?.body?.message ??
                `Unable to modify consultation - unknown error occurred. Try again.`
            );
          }
        )
        .finally(() => {
          this.setState({
            submitting: false,
          });
        });
    }
  }

  handlePhoneChange(values) {
    this.setState({
      phone: values,
    });
  }

  async getExistingAppointments(store, appointmentType, date) {
    return new Promise((resolve, reject) => {
      APIV2.getExistingAppointments(
        store?._id,
        appointmentType?.APPOINTMENT_TYPE,
        date.toISOString()
      ).then(
        (data) => {
          const appointments = data?.data?.appointments
            ? data.data.appointments
            : [];

          resolve(appointments);
        },
        (e) => {
          reject(e);
        }
      );
    });
  }

  async getHourlyTimeslots(date) {
    this.setState({
      loadingTimeslots: true,
    });

    if (!date) {
      this.setState({
        loadingTimeslots: false,
        hourlyTimeslots: [],
      });

      return;
    }

    let day = date.getDay();

    if (day == 0) {
      day = "Sunday";
    } else if (day == 1) {
      day = "Monday";
    } else if (day == 2) {
      day = "Tuesday";
    } else if (day == 3) {
      day = "Wednesday";
    } else if (day == 4) {
      day = "Thursday";
    } else if (day == 5) {
      day = "Friday";
    } else if (day == 6) {
      day = "Saturday";
    }

    const store = this.props.store;

    if (!store?.operations?.hours?.standard?.length) {
      this.setState({
        loadingTimeslots: false,
        hourlyTimeslots: [],
      });

      return;
    }

    let hours = _.findWhere(store?.operations?.hours?.standard, {
      day,
    });

    if (!hours || hours?.closed || !hours.opensAt || !hours.closesAt) {
      this.setState({
        loadingTimeslots: false,
        hourlyTimeslots: [],
      });

      return;
    }

    let opensAtInt = parseInt(hours.opensAt?.replace(":")) * 60;
    let closesAtInt = parseInt(hours.closesAt?.replace(":")) * 60;

    let now = store?.timezone ? moment.tz(store?.timezone) : moment();

    let chosenDate = store?.timezone
      ? moment.tz(date?.toISOString(), store?.timezone)
      : moment(date?.toISOString());

    let opensAt = store?.timezone
      ? moment(hours?.opensAt, "HH:mm").tz(store?.timezone)
      : moment(hours?.opensAt, "HH:mm");

    if (chosenDate?.format("YYYY-MM-DD") == now?.format("YYYY-MM-DD")) {
      if (now?.toDate() <= opensAt?.toDate()) {
        opensAtInt = store?.timezone
          ? moment(hours?.opensAt, "HH:mm").tz(store?.timezone)
          : moment(hours?.opensAt, "HH:mm").add(30, "minutes");
      } else {
        if (now.minutes() >= 20) {
          now = now
            .hour(now.hour() + 1)
            .minutes(0)
            .seconds(0);
        } else {
          now = now.minutes(30).seconds(0);
        }

        opensAtInt = now;
      }
    } else {
      opensAtInt = store?.timezone
        ? moment(hours?.opensAt, "HH:mm").tz(store?.timezone).add(30, "minutes")
        : moment(hours?.opensAt, "HH:mm").add(30, "minutes");
    }

    closesAtInt = moment(hours?.closesAt, "HH:mm").subtract(30, "minutes");

    let out = [];

    let counter = moment(opensAtInt.toISOString());

    while (counter.toDate() <= closesAtInt.toDate()) {
      const dateVal = store?.timezone
        ? moment.tz(counter.toDate(), store?.timezone)
        : moment(counter.toDate());

      dateVal
        .day(chosenDate.day())
        .month(chosenDate.month())
        .year(chosenDate.year());

      out.push({
        label: counter.format("h:mm A"),
        value: counter.format("HH:mm"),
        date: dateVal.toDate(),
        status: "available",
      });

      counter.add(30, "minutes");
    }

    let existing = [];

    try {
      existing = await this.getExistingAppointments(
        store,
        this.state.type,
        date
      );
    } catch (e) {}

    for (let i = 0; i < existing?.length; i++) {
      const startTime = store?.timezone
        ? moment
            .tz(existing[i]?.startsAt, store?.timezone)
            .seconds(0)
            .millisecond(0)
            .format("HH:mm")
        : moment(existing[i]?.startsAt)
            .seconds(0)
            .millisecond(0)
            .format("HH:mm");

      let match = _.filter(out, (item) => {
        return item?.value == startTime;
      });

      let allowedCount = store?.appointmentsPerSlot
        ? store?.appointmentsPerSlot
        : 1;

      if (match?.length >= allowedCount) {
        out = _.filter(out, (item) => {
          return item?.value != startTime;
        });
      }
    }

    this.setState({
      hourlyTimeslots: out,
      loadingTimeslots: false,
    });
  }

  filterTypes(types) {
    if (this.props.lead && !this.props.customer) {
      return _.filter(types, (type) => {
        return type?.value == "nc-consult";
      });
    }

    return types;
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.consultation ? (
                <>{this.props.mode == "reschedule" ? "Reschedule" : "Modify"}</>
              ) : (
                "Schedule"
              )}{" "}
              Appointment
            </h3>
            <Drawer.Actions>
              <Button
                size="sm"
                disabled={
                  this.state.submitting ||
                  (!this.props.consultation && !this.state.walkIn) ||
                  (!this.props.consultation &&
                    ((this.state.walkIn != "yes" &&
                      (!this.state.consultationDate ||
                        !this.state.consultationTime)) ||
                      (this.state.walkIn == "yes" &&
                        (!this.state.coachID || !this.state.consultNotes)) ||
                      !this.state.name ||
                      !this.state.phone?.value ||
                      !this.state.type)) ||
                  (this.props.mode == "reschedule" &&
                    (!this.state.consultationDate ||
                      !this.state.consultationTime)) ||
                  (this.props.mode == "information" &&
                    (!this.state.name ||
                      !this.state.email ||
                      !this.state.phone?.value)) ||
                  !this.state.email
                }
                color="primary"
                onClick={() => {
                  this.props.consultation ? this.modify() : this.create();
                }}
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : (
                  <>
                    {this.props.consultation ? (
                      "Save"
                    ) : (
                      <>
                        {this.state.walkIn == "yes" ? "Complete" : "Schedule"}
                      </>
                    )}
                  </>
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error ? (
              <>
                <Alert color="danger">{this.state.error}</Alert>
              </>
            ) : null}
            {!this.props.consultation ? (
              <FormGroup>
                <h5>
                  Appointment Type
                  <i
                    className="mdi mdi-octagram text-danger pl-1"
                    style={{ fontSize: "80%", position: "relative", top: -1 }}
                  ></i>
                </h5>
                <SelectPicker
                  searchable={false}
                  oneTap
                  data={this.filterTypes(this.state.types)}
                  value={this.state.type}
                  block
                  placeholder="Select Type"
                  onChange={(v) => {
                    this.setState({ type: v });
                  }}
                  placement="autoVerticalStart"
                ></SelectPicker>
              </FormGroup>
            ) : null}
            {this.props.consultation && this.props.mode == "reschedule" ? (
              <>
                <FormGroup>
                  <h5>
                    Appointment Date
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <DatePicker
                    required
                    block
                    oneTap
                    placeholder="Select A Date"
                    value={this.state.consultationDate}
                    format="MM/dd/yy"
                    placement="autoVerticalStart"
                    onChange={(e) => {
                      this.setState(
                        {
                          consultationDate: e,
                          consultationTime: null,
                        },
                        () => {
                          this.getHourlyTimeslots(this.state.consultationDate);
                        }
                      );
                    }}
                    ranges={[]}
                  ></DatePicker>
                </FormGroup>
                <FormGroup>
                  <h5>
                    Appointment Time
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <SelectPicker
                    required
                    block
                    oneTap
                    placeholder="Select A Time Slot"
                    searchable={true}
                    value={this.state.consultationTime}
                    data={this.state.hourlyTimeslots}
                    loading={this.state.loadingTimeslots}
                    placement="autoVerticalStart"
                    onChange={(e) => {
                      console.log(e);

                      this.setState({ consultationTime: e });
                    }}
                  ></SelectPicker>
                </FormGroup>
              </>
            ) : (
              <>
                {!this.props.consultation ? (
                  <>
                    <FormGroup>
                      <h5 className="mb-1">
                        {this.state.type
                          ? `${
                              this.state.type == "ec-consult"
                                ? "Member Check-In"
                                : "LEAN Consultation"
                            } `
                          : ""}
                        Walk-In
                        <i
                          className="mdi mdi-octagram text-danger pl-1"
                          style={{
                            fontSize: "80%",
                            position: "relative",
                            top: -1,
                          }}
                        ></i>
                      </h5>
                      <p
                        className="text-muted small"
                        style={{ lineHeight: 1.2 }}
                      >
                        Select whether you're recording a walk-in client
                        interaction or scheduling a future appointment.
                      </p>
                      <SelectPicker
                        cleanable={false}
                        placeholder="Select An Option"
                        data={[
                          { label: "Yes", value: "yes" },
                          { label: "No", value: "no" },
                        ]}
                        value={this.state.walkIn}
                        placement="auto"
                        block
                        preventOverflow={true}
                        onChange={(v) => {
                          if (v == "yes") {
                            this.getStoreStaff();
                          }

                          this.setState({
                            walkIn: v,
                          });
                        }}
                        searchable={false}
                      ></SelectPicker>
                    </FormGroup>

                    {this.state.walkIn == "no" ? (
                      <>
                        <FormGroup>
                          <h5>
                            Appointment Date
                            <i
                              className="mdi mdi-octagram text-danger pl-1"
                              style={{
                                fontSize: "80%",
                                position: "relative",
                                top: -1,
                              }}
                            ></i>
                          </h5>
                          <DatePicker
                            required
                            block
                            oneTap
                            placeholder="Select A Date"
                            value={this.state.consultationDate}
                            format="MM/dd/yy"
                            placement="autoVerticalStart"
                            onChange={(e) => {
                              this.setState(
                                {
                                  consultationDate: e,
                                  consultationTime: null,
                                },
                                () => {
                                  this.getHourlyTimeslots(
                                    this.state.consultationDate
                                  );
                                }
                              );
                            }}
                            ranges={[]}
                          ></DatePicker>
                        </FormGroup>
                        <FormGroup>
                          <h5>
                            Appointment Time
                            <i
                              className="mdi mdi-octagram text-danger pl-1"
                              style={{
                                fontSize: "80%",
                                position: "relative",
                                top: -1,
                              }}
                            ></i>
                          </h5>
                          <SelectPicker
                            required
                            block
                            oneTap
                            placeholder="Select A Time Slot"
                            searchable={true}
                            value={this.state.consultationTime}
                            data={this.state.hourlyTimeslots}
                            loading={this.state.loadingTimeslots}
                            placement="autoVerticalStart"
                            onChange={(e) => {
                              console.log(e);

                              this.setState({ consultationTime: e });
                            }}
                          ></SelectPicker>
                        </FormGroup>
                      </>
                    ) : null}
                  </>
                ) : null}
                <FormGroup>
                  <h5>
                    Name
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <Input
                    readOnly={
                      this.state.readOnly &&
                      (this.props.customer?.name || this.props.lead?.name)
                    }
                    type="text"
                    name="name"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.name}
                    placeholder="Name"
                    bsSize="sm"
                  ></Input>
                </FormGroup>
                <FormGroup>
                  <h5>
                    Email
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <Input
                    readOnly={
                      this.state.readOnly &&
                      (this.props.customer?.email || this.props.lead?.email)
                    }
                    type="email"
                    name="email"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.email}
                    placeholder="Email"
                    bsSize="sm"
                  ></Input>
                </FormGroup>
                <FormGroup>
                  <h5>
                    Phone
                    <i
                      className="mdi mdi-octagram text-danger pl-1"
                      style={{ fontSize: "80%", position: "relative", top: -1 }}
                    ></i>
                  </h5>
                  <NumberFormat
                    readOnly={
                      this.state.readOnly &&
                      (this.props.customer?.phone || this.props.lead?.phone)
                    }
                    className="form-control mb-3 form-control-sm"
                    format="(###) ###-####"
                    name="phoneNumberInput"
                    placeholder="Phone"
                    onValueChange={this.handlePhoneChange.bind(this)}
                    value={this.state.phone.value}
                    type="tel"
                    mask="_"
                  />
                </FormGroup>
                {this.state.type == "nc-consult" ? (
                  <FormGroup>
                    <h5>Goal</h5>

                    <CheckPicker
                      placeholder="Select Goals"
                      placement="autoVerticalStart"
                      data={Constant.SELECT_PICKER.CLIENT_GOALS}
                      value={this.state.goal}
                      cleanable={true}
                      searchable={false}
                      block
                      required
                      onChange={(value, item) => {
                        this.setState({ goal: value });
                      }}
                    ></CheckPicker>
                  </FormGroup>
                ) : null}

                {this.state.walkIn ? (
                  <>
                    {this.state.walkIn == "yes" ? (
                      <>
                        <FormGroup>
                          <h5>
                            Staff Member&nbsp;<span color="text-danger">*</span>
                          </h5>
                          <SelectPicker
                            loading={this.state.loadingCoaches}
                            cleanable
                            size="md"
                            placeholder="Select A Staff Member"
                            data={this.state.coachOptions}
                            style={{}}
                            value={this.state.coachID}
                            block
                            onChange={(val) => {
                              this.setState({ coachID: val });
                            }}
                            placement="auto"
                          />
                        </FormGroup>
                        <FormGroup>
                          <h5>
                            Appointment Notes&nbsp;
                            <span color="text-danger">*</span>
                          </h5>
                          <Input
                            bsSize="sm"
                            type="textarea"
                            name="consultNotes"
                            placeholder="Notes"
                            value={this.state.consultNotes}
                            onChange={this.handleInputChange.bind(this)}
                          ></Input>
                        </FormGroup>
                      </>
                    ) : (
                      <>
                        <FormGroup>
                          <h5>Additional Notes Shared By Member</h5>
                          <Input
                            type="textarea"
                            name="notes"
                            onChange={this.handleInputChange.bind(this)}
                            value={this.state.notes}
                            placeholder="Notes"
                            bsSize="sm"
                          ></Input>
                        </FormGroup>
                      </>
                    )}
                  </>
                ) : null}
              </>
            )}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(NewConsultationDrawer);
