import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
  CheckPicker,
  Checkbox,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import { isThisTypeNode } from "typescript";
import EditorV2 from "components/NovelEditor/ui/editor";
import LearningCourseSelectorDrawer from "./LearningCourseSelectorDrawer";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

class LearningTrackDrawer extends React.Component {
  state = {
    name: "",
    description: "",
    courseIDs: [],
    courses: [],
    certificationIDs: [],
    staffRoleIDs: [],
    storeIDs: [],
  };

  constructor() {
    super();

    this.storePickerRef = React.createRef();
  }

  onApplyChangeStores() {
    this.setState({
      storeIDs: this.state.pendingStoreIDs,
    });
  }

  onChangeStores(v) {
    this.setState({
      pendingStoreIDs: v,
    });
  }

  onCancelChangeStores() {
    this.setState({
      pendingStoreIDs: this.state.storeIDs,
    });
  }

  onSelectAllStores() {
    let selected = this.state.storeOptions?.map((opt) => {
      return opt.value;
    });

    if (this.state.pendingStoreIDs?.length == this.state.storeOptions?.length) {
      return this.setState({
        pendingStoreIDs: [],
      });
    }

    this.setState({
      pendingStoreIDs: selected,
    });
  }

  updateForm() {
    this.setState({
      name: this.props.track?.name ? this.props.track?.name : "",
      description: this.props.track?.description
        ? this.props.track?.description
        : "",
      courseIDs: this.props.track?.courseIDs?.length
        ? this.props.track?.courseIDs
        : [],
      courses: this.props.track?.courses?.length
        ? this.props.track?.courses
        : [],
      certificationIDs: this.props.track?.certificationIDs?.length
        ? this.props.track?.certificationIDs
        : [],
      staffRoleIDs: this.props.track?.staffRoleIDs
        ? this.props.track?.staffRoleIDs
        : [],
      preRequisiteTrackIDs: this.props.track?.preRequisiteTrackIDs
        ? this.props.track?.preRequisiteTrackIDs
        : [],
      required:
        this.props.track?.required !== null &&
        this.props.track?.required !== undefined
          ? this.props.track?.required
            ? 1
            : 0
          : 0,
      draft:
        this.props.track?.draft !== null &&
        this.props.track?.draft !== undefined
          ? this.props.track?.draft
            ? 1
            : 0
          : 1,
      type: this.props.track?.type ? this.props.track?.type : "",
      allStores:
        this.props.track?.allStores !== null &&
        this.props.track?.allStores !== undefined
          ? this.props.track?.allStores
            ? 1
            : 0
          : 1,
      storeIDs: this.props.track?.storeIDs?.length
        ? this.props.track?.storeIDs
        : [],
      pendingStoreIDs: this.props.track?.storeIDs?.length
        ? this.props.track?.storeIDs
        : [],
      initialDueDays:
        this.props.track?.initialDueDays !== null &&
        this.props.track?.initialDueDays !== undefined
          ? this.props.track?.initialDueDays
          : null,
      recertificationRequired:
        this.props.track?.recertificationRequired !== null &&
        this.props.track?.recertificationRequired !== undefined
          ? this.props.track?.recertificationRequired
            ? 1
            : 0
          : 0,
      recertificationDueDays:
        this.props.track?.recertificationDueDays !== null &&
        this.props.track?.recertificationDueDays !== undefined
          ? this.props.track?.recertificationDueDays
          : null,
    });
  }

  loadStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStores(null, { _id: 1, name: 1, nsoStage: 1, supportLevel: 1 })
      .then((data) => {
        let stores = _.filter(data.data.stores, (st) => {
          return (
            st.nsoStage &&
            st.nsoStage != "Lead" &&
            st.nsoStage != "Funding" &&
            st.nsoStage != "Cold" &&
            st.nsoStage != "Discovery"
          );
        });

        let storeOptions = stores?.map((st) => {
          return {
            label: st?.name?.replace("Project LeanNation", "PLN"),
            value: st?._id,
            groupBy: st?.nsoStage,
          };
        });

        this.setState({
          stores,
          storeOptions,
        });
      })
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props?.track != prevProps?.track) {
      this.updateForm();
      this.loadLearningTracks();
    }
  }

  componentDidMount() {
    if (this.props.track) {
      this.updateForm();
      this.loadLearningTracks();
    }

    this.loadCertifications();
    this.loadStaffRoles();
    this.loadStores();
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  toggleModal() {
    this.setState({
      name: "",
      courseIDs: [],
      courses: [],
      openCourseDrawer: false,
      certificationIDs: [],
      description: "",
    });

    this.props.onClose();
  }

  create() {
    this.setState({ submitting: true });

    APIV2.createLearningTrack(
      this.state.name,
      this.state.description,
      this.state.type,
      this.state.draft ? true : false,
      this.state.staffRoleIDs ? this.state.staffRoleIDs : [],
      this.state.courseIDs,
      this.state.certificationIDs,
      this.state.preRequisiteTrackIDs,
      this.state.allStores ? true : false,
      this.state.storeIDs?.length ? this.state.storeIDs : [],
      this.state.required ? true : false,
      this.state.initialDueDays ? this.state.initialDueDays : null,
      this.state.recertificationRequired ? true : false,
      this.state.recertificationDueDays
        ? this.state.recertificationDueDays
        : null
    )
      .then(
        (data) => {
          const staff = data.data.track;

          PubSub.publish(Event.STAFF.CREATED, staff);

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create track - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  electTier(name) {
    let tierStores = _.filter(this.state.stores, (st) => {
      return st.supportLevel == name;
    });

    console.log(tierStores);

    let tierStoreIDs = tierStores.map((st) => {
      return st._id;
    });

    this.setState({
      pendingStoreIDs: tierStoreIDs,
    });
  }

  countTier(name) {
    let tierStores = _.filter(this.state.stores, (st) => {
      return st.supportLevel == name;
    });

    return tierStores.length;
  }

  isTier(name) {
    let selectedStores = this.state.pendingStoreIDs;

    if (!selectedStores?.length) {
      return false;
    }

    let tierStores = _.filter(this.state.stores, (st) => {
      return st.supportLevel == name;
    });

    let tierStoreIDs = tierStores.map((st) => {
      return st._id;
    });

    if (_.difference(selectedStores, tierStoreIDs).length) {
      return false;
    }

    return true;
  }

  selectTier(name) {
    let tierStores = _.filter(this.state.stores, (st) => {
      return st.supportLevel == name;
    });

    let tierStoreIDs = tierStores.map((st) => {
      return st._id;
    });

    this.setState({
      pendingStoreIDs: tierStoreIDs,
    });
  }

  modify() {
    this.setState({ submitting: true });

    console.log(this.state.courseIDs);

    APIV2.modifyLearningTrack(
      this.props?.track?._id,
      this.state.name,
      this.state.description,
      this.state.type,
      this.state.draft ? true : false,
      this.state.staffRoleIDs ? this.state.staffRoleIDs : [],
      this.state.courseIDs,
      this.state.certificationIDs,
      this.state.preRequisiteTrackIDs,
      this.state.allStores ? true : false,
      this.state.storeIDs?.length ? this.state.storeIDs : [],
      this.state.required ? true : false,
      this.state.initialDueDays ? this.state.initialDueDays : null,
      this.state.recertificationRequired ? true : false,
      this.state.recertificationDueDays
        ? this.state.recertificationDueDays
        : null
    )
      .then(
        (data) => {
          const staff = data.data.track;

          PubSub.publish(Event.STAFF.MODIFIED, staff);

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to modify track - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  /**
   * Helper function to handle reorder events from react-beautiful-dnd library
   *
   * @param {*} list
   * @param {*} startIndex
   * @param {*} endIndex
   * @returns
   */
  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  onDragStart(result, e) {
    console.log(result, e);

    let elements = document.querySelectorAll(
      `[data-rbd-draggable-id="${result?.draggableId}"]`
    );

    if (elements?.length) {
      let element = elements[0];

      if (element) {
        element.style.left = "24px";
      }
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.courses,
      result.source.index,
      result.destination.index
    );

    console.log(items);

    this.setState({
      courses: items,
      courseIDs: items.map((item) => item._id),
    });
  }

  loadCertifications() {
    this.setState({
      loadingCertifications: true,
    });

    APIV2.getLearningCertifications(1, 500, {
      type: "manual",
    })
      .then(
        (data) => {
          this.setState({
            certifications: data.data.certifications,
            certificationOptions: data.data.certifications?.map((cert) => {
              return {
                label: cert.name,
                value: cert._id,
              };
            }),
          });
        },
        () => {
          window.alert("Failed to get certifications. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingCertifications: false,
        });
      });
  }

  loadLearningTracks() {
    this.setState({
      loadingLearningTracks: true,
    });

    APIV2.getLearningTracks(1, 500, {
      _idString: { $ne: this.props.track?._id },
    })
      .then(
        (data) => {
          this.setState({
            tracks: data.data.tracks,
            trackOptions: data.data.tracks.map((cert) => {
              return {
                label: cert.name,
                value: cert._id,
                type: cert.type,
              };
            }),
          });
        },
        () => {
          window.alert("Failed to get learning tracks. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingTracks: false,
        });
      });
  }

  loadStaffRoles() {
    this.setState({
      loadingStaffRoles: true,
    });

    APIV2.getStaffRoles(1, 500, {}, { name: 1 })
      .then(
        (data) => {
          this.setState({
            roles: data.data.roles,
            roleOptions: data.data.roles.map((cert) => {
              return {
                label: cert.name,
                value: cert._id,
              };
            }),
          });
        },
        () => {
          window.alert("Failed to get staff roles. Please try again.");
        }
      )
      .finally(() => {
        this.setState({
          loadingStaffRoles: false,
        });
      });
  }

  addCourse(course) {
    let courseIDs = this.state.courseIDs?.length ? this.state.courseIDs : [];
    let courses = this.state.courses?.length ? this.state.courses : [];

    if (courseIDs.indexOf(course._id) == -1) {
      courseIDs.push(course._id);
      courses.push(course);

      this.setState({
        courseIDs,
        courses,
      });
    }
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
          backdrop="static"
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.track ? "Modify" : "Create"}
              &nbsp;Track
            </h3>
            <Drawer.Actions>
              <Button
                onClick={() => {
                  if (this.props.track) {
                    this.modify();
                  } else {
                    this.create();
                  }
                }}
                size="sm"
                color="primary"
                disabled={
                  !this.state.name ||
                  !this.state.type ||
                  !this.state?.courses?.length ||
                  this.state.required === null ||
                  this.state.required === undefined ||
                  (this.state.required &&
                    (this.state.initialDueDays === null ||
                      this.state.initialDueDays === undefined)) ||
                  (this.state.required &&
                    this.state.recertificationRequired &&
                    (this.state.recertificationDueDays === null ||
                      this.state.recertificationDueDays === undefined)) ||
                  (this.state.type == "Ad-Hoc Track" &&
                    this.state.allStores == 0 &&
                    !this.state.storeIDs?.length) ||
                  this.state.submitting
                }
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : this.props.track ? (
                  "Save"
                ) : (
                  "Create"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error && (
              <>
                <Alert
                  color=""
                  className="m-0 mb-4 bg-primary-superlight text-dark font-weight-500"
                >
                  {this.state.error}
                </Alert>
              </>
            )}
            <h3 className="text-dark">Track Information</h3>
            <FormGroup>
              <h5>
                Name<span className="text-danger">*</span>
              </h5>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                required
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>

            <FormGroup>
              <h5 className="mb-2">Description</h5>
              <Input
                type="textarea"
                name="description"
                numRows={3}
                placeholder="Description"
                value={this.state.description}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>
                Type<span className="text-danger">*</span>
              </h5>

              <SelectPicker
                searchable={false}
                cleanable={false}
                data={[
                  {
                    label: "Core Track",
                    value: "Core Track",
                  },
                  {
                    label: "Ad-Hoc Track",
                    value: "Ad-Hoc Track",
                  },
                ]}
                value={this.state.type}
                onChange={(value) => {
                  this.setState(
                    {
                      type: value,
                    },
                    () => {
                      if (value == "Ad-Hoc Track") {
                        this.setState({
                          allStores: 1,
                        });
                      } else {
                        this.setState({
                          storeIDs: [],
                          pendingStoreIDs: [],
                          allStores: 1,
                        });
                      }
                    }
                  );
                }}
                style={{ width: "100%" }}
                placeholder="Select type"
                preventOverflow={true}
                placement="autoVertical"
              ></SelectPicker>
            </FormGroup>
            <FormGroup>
              <h5>
                Status<span className="text-danger">*</span>
              </h5>

              <SelectPicker
                searchable={false}
                cleanable={false}
                data={[
                  {
                    label: "Draft",
                    value: 1,
                  },
                  {
                    label: "Published",
                    value: 0,
                  },
                ]}
                value={this.state.draft}
                onChange={(value) => {
                  this.setState({
                    draft: value,
                  });
                }}
                style={{ width: "100%" }}
                placeholder="Select option"
                preventOverflow={true}
                placement="autoVertical"
              ></SelectPicker>
            </FormGroup>
            <hr className="my-4"></hr>
            <h3 className="text-dark">Track Availability</h3>
            {this.state.type == "Ad-Hoc Track" && (
              <div>
                <FormGroup>
                  <h5 className="m-0">
                    Available To All Current & Future Stores
                  </h5>
                  <p className="small text-dark">
                    If enabled, this learning track will be available to all
                    current and future stores.
                  </p>
                  <SelectPicker
                    block
                    searchable={false}
                    cleanable={false}
                    data={[
                      {
                        label: "Yes",
                        value: 1,
                      },
                      {
                        label: "No",
                        value: 0,
                      },
                    ]}
                    value={this.state.allStores}
                    onChange={(value) => {
                      this.setState({
                        allStores: value,
                      });

                      if (value) {
                        this.setState({
                          storeIDs: [],
                          pendingStoreIDs: [],
                        });
                      }
                    }}
                    placeholder="Select option"
                  ></SelectPicker>
                </FormGroup>

                {!this.state.allStores && (
                  <FormGroup>
                    <h5>Assign To Stores</h5>
                    <CheckPicker
                      groupBy="groupBy"
                      block
                      ref={this.storePickerRef}
                      cleanable={true}
                      label="Stores"
                      data={this.state.storeOptions}
                      placement="autoVertical"
                      preventOverflow={true}
                      placeholder="Select Stores"
                      value={this.state.pendingStoreIDs}
                      onChange={this.onChangeStores.bind(this)}
                      onClose={() => {
                        if (
                          JSON.stringify(this.state.pendingStoreIDs) !=
                          JSON.stringify(this.state.selectedStores)
                        ) {
                          this.onApplyChangeStores();
                        }
                      }}
                      sort={(isGroup) => {
                        if (isGroup) {
                          return (a, b) => {
                            if (b.groupTitle == "Live") {
                              return 1;
                            }

                            if (b.groupTitle == "Prelaunch") {
                              if (a.groupTitle == "Live") {
                                return -1;
                              }

                              return 1;
                            }

                            if (b.groupTitle == "Buildout") {
                              if (
                                a.groupTitle == "Live" ||
                                a.groupTitle == "Prelaunch"
                              ) {
                                return -1;
                              }

                              return 1;
                            }

                            if (b.groupTitle == "Site Selection") {
                              if (
                                a.groupTitle == "Live" ||
                                a.groupTitle == "Prelaunch" ||
                                a.groupTitle == "Buildout"
                              ) {
                                return -1;
                              }

                              return 1;
                            }

                            return a.groupTitle < b.groupTitle ? -1 : 1;
                          };
                        }

                        return (a, b) => {
                          return a.label < b.label ? -1 : 1;
                        };
                      }}
                      renderExtraFooter={() => (
                        <div className="pr-2 py-2 border-top">
                          <div className="pl-2" style={{ maxWidth: "100%" }}>
                            <div className="small text-dark font-weight-bold text-center mb-2">
                              Quick Select
                            </div>
                            <Row className="align-items-center">
                              <Col xs="4" className="pr-1">
                                <Button
                                  block
                                  color={
                                    this.isTier("Impact Level")
                                      ? "primary"
                                      : "dark"
                                  }
                                  outline={
                                    this.isTier("Impact Level") ? false : true
                                  }
                                  size="sm"
                                  onClick={() => {
                                    this.selectTier("Impact Level");
                                  }}
                                >
                                  Impact ({this.countTier("Impact Level")})
                                </Button>
                              </Col>
                              <Col xs="4" className="pl-1 pr-1">
                                <Button
                                  block
                                  color={
                                    this.isTier("Growth") ? "primary" : "dark"
                                  }
                                  outline={this.isTier("Growth") ? false : true}
                                  size="sm"
                                  onClick={() => {
                                    this.selectTier("Growth");
                                  }}
                                >
                                  Growth ({this.countTier("Growth")})
                                </Button>
                              </Col>
                              <Col xs="4" className="pl-1">
                                <Button
                                  block
                                  color={
                                    this.isTier("Critical Level")
                                      ? "primary"
                                      : "dark"
                                  }
                                  outline={
                                    this.isTier("Critical Level") ? false : true
                                  }
                                  size="sm"
                                  onClick={() => {
                                    this.selectTier("Critical Level");
                                  }}
                                >
                                  Critical ({this.countTier("Critical Level")})
                                </Button>
                              </Col>
                              <Col xs="6" className="pr-1 mt-2">
                                <Button
                                  block
                                  color={
                                    this.isTier("Post-Open Level")
                                      ? "primary"
                                      : "dark"
                                  }
                                  outline={
                                    this.isTier("Post-Open Level")
                                      ? false
                                      : true
                                  }
                                  size="sm"
                                  onClick={() => {
                                    this.selectTier("Post-Open Level");
                                  }}
                                >
                                  Post-Open ({this.countTier("Post-Open Level")}
                                  )
                                </Button>
                              </Col>
                              <Col xs="6" className="pl-1 mt-2">
                                <Button
                                  block
                                  color={
                                    this.isTier("Pre-Open Level")
                                      ? "primary"
                                      : "dark"
                                  }
                                  outline={
                                    this.isTier("Pre-Open Level") ? false : true
                                  }
                                  size="sm"
                                  onClick={() => {
                                    this.selectTier("Pre-Open Level");
                                  }}
                                >
                                  Pre-Open ({this.countTier("Pre-Open Level")})
                                </Button>
                              </Col>
                            </Row>
                          </div>
                          <hr className="my-2"></hr>
                          <Row className="align-items-center">
                            <Col xs="">
                              <Checkbox
                                className="ml-1"
                                inline
                                indeterminate={
                                  this.state.pendingStoreIDs?.length > 0 &&
                                  this.state.pendingStoreIDs?.length <
                                    this.state.storeOptions?.length
                                }
                                checked={
                                  this.state.pendingStoreIDs?.length ===
                                  this.state.storeOptions?.length
                                }
                                onChange={this.onSelectAllStores.bind(this)}
                              >
                                All Stores
                              </Checkbox>
                            </Col>
                            <Col xs="auto">
                              <Button
                                color="dark"
                                outline
                                size="sm"
                                onClick={() => {
                                  this.onCancelChangeStores();

                                  this.storePickerRef.current.close();
                                }}
                              >
                                Cancel
                              </Button>

                              <Button
                                color="primary"
                                size="sm"
                                onClick={() => {
                                  this.storePickerRef.current.close();
                                }}
                              >
                                Apply
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      )}
                    ></CheckPicker>
                  </FormGroup>
                )}
              </div>
            )}

            <FormGroup>
              <h5 className="m-0">Assign To Staff Roles</h5>
              <p className="small text-dark">
                Select one or more staff role to assign this learning track to.
              </p>
              <CheckPicker
                data={this.state.roleOptions}
                value={this.state.staffRoleIDs}
                onChange={(value) => {
                  this.setState({
                    staffRoleIDs: value,
                  });
                }}
                style={{ width: "100%" }}
                placeholder="Select roles"
                preventOverflow={true}
                placement="autoVertical"
                loading={this.state.loadingStaffRoles}
              ></CheckPicker>
            </FormGroup>
            <FormGroup>
              <h5 className="m-0">Prerequisite Learning Tracks</h5>
              <p className="small text-dark" style={{ lineHeight: 1.3 }}>
                Select one or more manual learning tracks that must be completed
                before this learning track is unlocked.
              </p>
              <CheckPicker
                data={this.state.trackOptions}
                groupBy="type"
                value={this.state.preRequisiteTrackIDs}
                onChange={(value) => {
                  this.setState({
                    preRequisiteTrackIDs: value,
                  });
                }}
                style={{ width: "100%" }}
                placeholder="Select tracks"
                preventOverflow={true}
                placement="autoVertical"
                loading={this.state.loadingTracks}
              ></CheckPicker>
            </FormGroup>
            <hr className="my-4"></hr>
            <h3 className="text-dark">Track Completion</h3>
            <FormGroup>
              <h5>
                Require Completion<span className="text-danger">*</span>
              </h5>
              <p className="small text-dark" style={{ lineHeight: 1.3 }}>
                If Required, this learning track will be automatically assigned
                to existing staff members. New staff members will be assigned
                this track upon account creation. If Optional, this track will
                be available but not automatically assigned.
              </p>
              <SelectPicker
                searchable={false}
                cleanable={false}
                data={[
                  {
                    label: "Required",
                    value: 1,
                  },
                  {
                    label: "Optional",
                    value: 0,
                  },
                ]}
                value={this.state.required}
                onChange={(value) => {
                  let recertificationDueDays = 30;
                  let initialDueDays = 30;

                  let payload = {
                    required: value,
                  };

                  if (value && !this.state.required) {
                    payload.initialDueDays = initialDueDays;
                    payload.recertificationDueDays = recertificationDueDays;
                  }

                  this.setState(payload);

                  if (!value) {
                    this.setState({
                      initialDueDays: null,
                      recertificationDueDays: null,
                    });
                  }
                }}
                style={{ width: "100%" }}
                placeholder="Select option"
                preventOverflow={true}
                placement="autoVertical"
              ></SelectPicker>
            </FormGroup>
            {this.state.required ? (
              <>
                <FormGroup>
                  <h5 className="m-0">
                    Initial Due Date Days<span className="text-danger">*</span>
                  </h5>
                  <p className="small text-dark" style={{ lineHeight: 1.3 }}>
                    The number of days after the track is assigned to a staff
                    member that this learning track must be completed.
                  </p>
                  <NumberFormat
                    className="form-control"
                    name="initialDueDays"
                    placeholder="Initial Due Date Days"
                    value={this.state.initialDueDays}
                    onValueChange={(values) => {
                      const { value } = values;

                      this.setState({
                        initialDueDays: value,
                      });
                    }}
                    allowNegative={false}
                    allowEmptyFormatting={false}
                    allowLeadingZeros={false}
                    decimalScale={0}
                    thousandSeparator={false}
                  ></NumberFormat>
                </FormGroup>
                <FormGroup>
                  <h5>
                    Ongoing Recertification
                    <span className="text-danger">*</span>
                  </h5>
                  <p className="small text-dark" style={{ lineHeight: 1.3 }}>
                    If Yes, staff members who no longer meet passing grade
                    criteria will be prompted to recomplete this learning track
                    to meet minimum standards. If No, staff members will not be
                    prompted again once the learning track is completed.
                  </p>
                  <SelectPicker
                    searchable={false}
                    cleanable={false}
                    data={[
                      {
                        label: "Yes",
                        value: 1,
                      },
                      {
                        label: "No",
                        value: 0,
                      },
                    ]}
                    value={this.state.recertificationRequired}
                    onChange={(value) => {
                      let recertificationDueDays = 30;

                      let payload = {
                        recertificationRequired: value,
                      };

                      if (value && !this.state.recertificationRequired) {
                        payload.recertificationDueDays = recertificationDueDays;
                      }

                      this.setState(payload);

                      if (!value) {
                        this.setState({
                          recertificationDueDays: null,
                          recertificationRequired: 0,
                        });
                      }
                    }}
                    style={{ width: "100%" }}
                    placeholder="Select option"
                    preventOverflow={true}
                    placement="autoVertical"
                  ></SelectPicker>
                </FormGroup>
                {this.state.recertificationRequired ? (
                  <FormGroup>
                    <h5 className="m-0">
                      Recertification Date Days
                      <span className="text-danger">*</span>
                    </h5>
                    <p className="small text-dark" style={{ lineHeight: 1.3 }}>
                      The number of days that a staff member has to recomplete
                      this learning track after they are no longer certified in
                      compliance.
                    </p>
                    <NumberFormat
                      className="form-control"
                      name="recertificationDueDays"
                      placeholder="Recertification Date Days"
                      value={this.state.recertificationDueDays}
                      onValueChange={(values) => {
                        const { value } = values;

                        this.setState({
                          recertificationDueDays: value,
                        });
                      }}
                      allowNegative={false}
                      allowEmptyFormatting={false}
                      allowLeadingZeros={false}
                      decimalScale={0}
                      thousandSeparator={false}
                    ></NumberFormat>
                  </FormGroup>
                ) : null}
              </>
            ) : null}
            <hr className="my-4"></hr>
            <h3 className="text-dark">Track Content</h3>
            <FormGroup>
              <h5 className="m-0">Manual Certifications</h5>
              <p className="small text-dark">
                Select one or more manual certifications required for this
                learning track.
              </p>
              <CheckPicker
                data={this.state.certificationOptions}
                value={this.state.certificationIDs}
                onChange={(value) => {
                  this.setState({
                    certificationIDs: value,
                  });
                }}
                style={{ width: "100%" }}
                placeholder="Select certifications"
                preventOverflow={true}
                placement="autoVertical"
                loading={this.state.loadingCertifications}
              ></CheckPicker>
            </FormGroup>

            <FormGroup>
              <Row className="align-items-center">
                <Col xs="">
                  <h5 className="m-0">
                    Courses<span className="text-danger">*</span>
                  </h5>
                  <p className="small text-dark">
                    Add and organize one or more courses for this learning
                    track.
                  </p>
                </Col>
                <Col xs="auto">
                  <Button
                    size="sm"
                    color="primary"
                    outline
                    onClick={() => {
                      this.setState({
                        openCourseDrawer: true,
                      });
                    }}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
              {/**
               * TODO: drag to reorder course
               */}

              <div style={{ position: "relative" }}>
                <DragDropContext
                  onDragStart={this.onDragStart.bind(this)}
                  onDragEnd={this.onDragEnd.bind(this)}
                >
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          background: snapshot.isDraggingOver
                            ? "var(--primary-superlight)"
                            : "",
                          borderRadius: 6,
                        }}
                      >
                        {this.state.courses?.map((section, index) => (
                          <Draggable
                            key={section._id}
                            draggableId={section._id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="my-2"
                              >
                                <Card
                                  className={`cursor-pointer ${
                                    snapshot.isDragging ? "shadow" : ""
                                  } border`}
                                >
                                  <CardBody>
                                    <Row className="align-items-center">
                                      <Col xs="auto" className="pr-0 pl-2">
                                        <i
                                          className="mdi mdi-drag text-dark"
                                          style={{ fontSize: 25 }}
                                        ></i>
                                      </Col>
                                      <Col xs="">
                                        <h3 className="m-0 text-dark">
                                          {section?.draft ? (
                                            <Badge
                                              color="medium"
                                              className="mr-2"
                                            >
                                              Draft
                                            </Badge>
                                          ) : null}

                                          {section?.name}
                                        </h3>
                                        {section?.description ? (
                                          <p className="m-0 small text-dark">
                                            {section?.description}
                                          </p>
                                        ) : null}
                                      </Col>
                                      <Col xs="auto" className="pl-0">
                                        <Button
                                          size="sm"
                                          outline
                                          color="danger"
                                          className="btn-icon-only"
                                          onClick={() => {
                                            swal({
                                              title: "Remove Course?",
                                              text: "Once deleted this course will be removed from the track.",
                                              icon: "warning",
                                              buttons: true,
                                              dangerMode: true,
                                            }).then((willDelete) => {
                                              if (willDelete) {
                                                let courses =
                                                  this.state.courses;
                                                let courseIDs =
                                                  this.state.courseIDs;

                                                courses.splice(index, 1);
                                                courseIDs.splice(index, 1);

                                                this.setState({
                                                  courses,
                                                  courseIDs,
                                                });
                                              }
                                            });
                                          }}
                                        >
                                          <i className="mdi mdi-trash-can"></i>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </FormGroup>
          </Drawer.Body>
        </Drawer>
        <LearningCourseSelectorDrawer
          open={this.state.openCourseDrawer}
          onClose={() => {
            this.setState({
              openCourseDrawer: false,
            });
          }}
          selectedCourses={this.state.courseIDs ?? []}
          onSelect={(course) => {
            this.addCourse(course);
          }}
        ></LearningCourseSelectorDrawer>
      </>
    );
  }
}

export default withRouter(LearningTrackDrawer);
