import React from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  CardFooter,
  PaginationItem,
  PaginationLink,
  Pagination,
  Button,
  Badge,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import API from "lib/API";
import BillingAccountRow from "components/BillingAccountRow";
import PubSub from "lib/PubSub";
import Event from "lib/Event";
import { filter, findIndex, isNumber } from "underscore";
import { withRouter } from "react-router-dom";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import FormGroup from "reactstrap/lib/FormGroup";
import _ from "underscore";
import swal from "sweetalert";
import Spinner from "reactstrap/lib/Spinner";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import draftToHtml from "draftjs-to-html";
import { TagPicker, Uploader } from "rsuite";
import moment from "moment";
import StringUtils from "lib/StringUtils";
import ArrowText from "components/ArrowText";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import AffiliateInformationWidget from "components/Widgets/AffiliateInformationWidget";
import { Capacitor } from "@capacitor/core";
import AffiliateSummaryWidget from "components/Widgets/AffiliateSummaryWidget";
import AffiliateAllTasksWidget from "components/Widgets/AffiliateAllTasksWidget";
import StaffAboutWidget from "../../components/Widgets/StaffAboutWidget";
import StaffNotesWidget from "../../components/Widgets/StaffNotesWidget";
import StaffAccountWidget from "../../components/Widgets/StaffAccountWidget";

class StaffView extends React.Component {
  state = {
    store: null,
    loading: true,
    descriptionState: EditorState.createEmpty(),
    loadingCategories: false,
    categories: [],
    categoryTags: [],
    allergens: [],
    tab: { name: "Information", id: "information" },
    allTabs: [
      {
        name: "Information",
        id: "information",
      },
      {
        name: "Notes",
        id: "notes",
      },

      /*{
        name: "Timeline",
        id: "timeline",
      },*/
    ],
  };

  constructor(props) {
    super(props);

    this.headerRef = React.createRef();
  }

  async loadStaff() {
    const {
      match: { params },
    } = this.props;

    APIV2.getStaffByID(params.storeID, params.staffID)
      .then(
        (data) => {
          this.setState({
            staff: data.data.staff,
          });
        },
        (error) => {
          console.error(error);
        }
      )
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  delete(staff) {
    swal({
      title: "Terminate Staff Member?",
      text: `Are you sure you want terminate ${staff?.name} as a staff member?`,
      icon: "warning",
      buttons: ["Nevermind", "Terminate"],
      dangerMode: true,
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deleteSubmitting: staff?._id,
      });

      APIV2.deleteStoreStaff(staff?.storeID, staff?._id)
        .then(
          (data) => {
            this.loadStaff();
          },
          (e) => {
            console.error(e);
          }
        )
        .finally(() => {
          this.setState({
            deleteSubmitting: false,
          });
        });
    });
  }

  reactivate(staff) {
    swal({
      title: "Reactivate Staff Member?",
      text: `Are you sure you want reactivate ${staff?.name} as a staff member?`,
      icon: "warning",
      buttons: ["Nevermind", "Reactivate"],
    }).then((conf) => {
      if (!conf) {
        return;
      }

      this.setState({
        deleteSubmitting: staff?._id,
      });

      APIV2.reactivateStoreStaff(staff?.storeID, staff?._id)
        .then(
          (data) => {
            this.loadStaff();
          },
          (e) => {
            console.error(e);
          }
        )
        .finally(() => {
          this.setState({
            deleteSubmitting: false,
          });
        });
    });
  }

  checkStickyHeader(e) {
    if (this.headerRef.current) {
      const stickyElm = this.headerRef.current;

      var rect = stickyElm.getBoundingClientRect();

      if (rect.top <= (Capacitor.getPlatform() == "ios" ? 110 : 80)) {
        this.setState({ sticky: true });
      } else {
        this.setState({ sticky: false });
      }

      if (rect.top == 0) {
        this.setState({ fixed: true });
      } else {
        this.setState({ fixed: false });
      }
    }
  }

  componentDidMount() {
    this.loadStaff();

    PubSub.subscribe(Event.STAFF.MODIFIED, (c) => {
      this.setState({ staff: c ? JSON.parse(JSON.stringify(c)) : null });
    });

    if (this.props.match?.params?.tabID) {
      this.setTab(this.props.match?.params?.tabID);
    } else {
      this.setTab("information");
    }

    document.addEventListener("scroll", this.checkStickyHeader.bind(this));
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.checkStickyHeader.bind(this));
  }

  setTab(tab) {
    if (!tab) {
      return;
    }

    let t = _.findWhere(this.state.allTabs, { id: tab });

    if (t) {
      this.setState({ tab: t });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match?.params?.staffID != prevProps.match?.params?.staffID ||
      this.props.match?.params?.storeID != prevProps.match?.params?.storeID
    ) {
      this.loadStaff();
    }

    if (this.props.match?.params?.tabID !== prevProps.match?.params?.tabID) {
      if (this.props.match?.params?.tabID) {
        this.setTab(this.props.match?.params?.tabID);
      } else {
        this.setTab("information");
      }
    }
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          <PageHeader
            backOverride={`/storefront/${this?.props?.match?.params?.storeID}/settings/staff`}
            title={
              this.state.staff?.name ? this.state.staff.name : "Loading..."
            }
            showBackButton={true}
          />
          {/* Table */}
          <Row>
            <Col xs="12" md="12">
              <Card className="mb-4 shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-top">
                    <Col xs="12" sm="6">
                      <h3 className=" mb-1">
                        {this.state.staff?.name
                          ? this.state.staff?.name
                          : this.state.staff?.email}
                      </h3>
                      <p className="mb-0">
                        {this.state.staff?.phone
                          ? StringUtils.formatPhoneNumber(
                              this.state.staff?.phone
                            )
                          : this.state.staff?.email}
                      </p>
                    </Col>
                    <Col
                      xs="12"
                      sm="6"
                      className="text-left text-sm-right text-dark"
                    >
                      <div>
                        <small className="mb-0" style={{ lineHeight: 1.4 }}>
                          {this.state.staff?.terminated ? (
                            <>
                              Terminated
                              {this.state.staff?.role?.name
                                ? ` (${this.state.staff?.role?.name})`
                                : ""}
                            </>
                          ) : (
                            <>
                              {this.state.staff?.role?.name
                                ? this.state.staff?.role?.name
                                : "No Role Assigned"}
                            </>
                          )}
                        </small>
                      </div>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              {/** Page navigation */}
              <div
                ref={this.headerRef}
                style={{ position: "sticky", top: "80px" }}
                className={`stickable ${this.state.sticky ? "stuck" : ""}`}
              >
                <Card className="mb-4 shadow border">
                  <CardSelectorHeader
                    id="stickyHeader"
                    value={this.state.tab}
                    options={this.state.allTabs}
                    onChange={(v) => {
                      if (v.id != this.state.tab.id) {
                        this.props.history.push(
                          "/storefront/" +
                            this.props.match?.params?.storeID +
                            "/settings/staff/details/" +
                            this.props.match?.params?.staffID +
                            "/" +
                            v.id,
                          { noScroll: true }
                        );
                      }
                    }}
                  ></CardSelectorHeader>
                </Card>
              </div>
              {this.state.tab.id == "notes" ? (
                <>
                  <StaffNotesWidget
                    loading={this.state.loading}
                    staff={this.state.staff}
                  />
                </>
              ) : null}
              {/**Primary  Widgets */}
              {this.state.tab.id == "information" ? (
                <>
                  <StaffAboutWidget
                    staff={this.state.staff}
                    modifiable={true}
                    loading={this.state.loading}
                  />
                  <StaffAccountWidget
                    staff={this.state.staff}
                    loading={this.state.loading}
                  />
                </>
              ) : null}
            </Col>
            <Col xs="12" md="4">
              {/*<Card className="mb-4 shadow">
                <CardHeader className="bg-transparent">
                  <Row>
                    <div className="col">
                      <h3 className=" mb-0">Insights</h3>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody></CardBody>
    </Card>*/}
            </Col>
          </Row>
          <hr className="mt-2 mb-4" />
          <Row>
            <Col xs="auto">
              {this.state.staff?.terminated ? (
                <Button
                  color="dark"
                  onClick={() => this.reactivate(this.state.staff)}
                  outline
                  disabled={this.state.deleting}
                >
                  {this.state.deleting ? (
                    <>
                      <Spinner size="sm" color="dark" />
                    </>
                  ) : (
                    <>Reactivate Staff Member</>
                  )}
                </Button>
              ) : (
                <Button
                  color="danger"
                  onClick={() => this.delete(this.state.staff)}
                  outline
                  disabled={this.state.deleting}
                >
                  {this.state.deleting ? (
                    <>
                      <Spinner size="sm" color="danger" />
                    </>
                  ) : (
                    <>Terminate Staff Member</>
                  )}
                </Button>
              )}
            </Col>
            <Col xs=""></Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(StaffView);
