import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import { SelectPicker } from "rsuite";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import _ from "underscore";
import Constant from "lib/Constant";
import StorefrontStaffMemberDrawer from "../Drawers/StorefrontStaffMemberDrawer";
import moment from "moment";

class StaffAboutWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    zip: {
      value: "",
    },
    name: "",
    email: "",
  };

  render() {
    return (
      <>
        <Card
          className={`mb-4 ${
            this.props.flat ? "shadow-none" : "shadow"
          } border`}
        >
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">Information</h3>
              </div>
              {this.props.modifiable ? (
                <Col xs="auto">
                  <Button
                    size="sm"
                    color="secondary"
                    outline
                    onClick={() => {
                      this.setState({
                        openModify: true,
                        modifyStaff: JSON.parse(
                          JSON.stringify(this.props.staff)
                        ),
                      });
                    }}
                  >
                    Edit
                  </Button>
                </Col>
              ) : null}
            </Row>
          </CardHeader>
          {this.props.loading ? (
            <CardBody>
              <Spinner size="sm" color="dark" className="mr-2" /> Loading...
            </CardBody>
          ) : (
            <CardBody className="p-0">
              <div className="px-4 py-3 border-bottom text-dark">
                <Row className="align-items-center">
                  <Col xs="auto">Name:</Col>
                  <Col xs="" className="pl-0">
                    {this.props.staff?.name ? this.props.staff?.name : "--"}
                  </Col>
                </Row>
              </div>
              <div className="px-4 py-3 border-bottom text-dark">
                <Row className="align-items-center">
                  <Col xs="auto">Role:</Col>
                  <Col xs="" className="pl-0">
                    {this.props.staff?.role?.name
                      ? this.props.staff?.role?.name
                      : "--"}
                  </Col>
                </Row>
              </div>

              <div className="px-4 py-3 border-bottom text-dark">
                <Row className="align-items-center">
                  <Col xs="auto">Phone:</Col>
                  <Col xs="" className="pl-0">
                    <div>
                      {this.props.staff?.phone ? (
                        <>
                          <a href={`tel:${this.props.staff?.phone}`}>
                            {StringUtils.formatPhoneNumber(
                              this.props.staff?.phone
                            )}
                          </a>
                        </>
                      ) : (
                        "--"
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="px-4 py-3 border-bottom text-dark">
                <Row className="align-items-center">
                  <Col xs="auto">Address:</Col>
                  <Col xs="" className="pl-0">
                    <div>
                      {this.props.staff?.address?.formatted_address
                        ? this.props.staff?.address?.formatted_address
                        : "--"}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="px-4 py-3 border-bottom text-dark">
                <Row className="align-items-center">
                  <Col xs="auto">Hire Date:</Col>
                  <Col xs="" className="pl-0">
                    <div>
                      {this.props.staff?.hireDate
                        ? moment(this.props.staff?.hireDate).format(
                            "MM/DD/YYYY"
                          )
                        : "--"}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="px-4 py-3 border-bottom">
                <Row className="align-items-center">
                  <Col xs="auto" className="text-dark">
                    Status:
                  </Col>
                  <Col xs="" className="pl-0">
                    <div
                      className={`${
                        this.props.staff?.terminated
                          ? "text-danger"
                          : "text-dark"
                      }`}
                    >
                      {this.props.staff?.terminated ? "Terminated" : "Active"}
                    </div>
                  </Col>
                </Row>
              </div>
              {this.props.staff?.terminated ? (
                <div className="px-4 py-3 border-bottom text-dark">
                  <Row className="align-items-center">
                    <Col xs="auto">Termination Date:</Col>
                    <Col xs="" className="pl-0">
                      <div>
                        {this.props.staff?.terminationDate
                          ? moment(this.props.staff?.terminationDate).format(
                              "MM/DD/YYYY"
                            )
                          : "--"}
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : null}
            </CardBody>
          )}
        </Card>
        <StorefrontStaffMemberDrawer
          open={this.state.openModify}
          onClose={() => {
            this.setState({
              openModify: false,
              modifyStaff: null,
            });
          }}
          staff={this.state.modifyStaff}
          storeID={this?.props?.match?.params?.storeID}
        ></StorefrontStaffMemberDrawer>
      </>
    );
  }
}

export default withRouter(StaffAboutWidget);
