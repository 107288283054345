import React, { useState } from "react";
// react component that copies the given text inside your clipboard

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
  Alert,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeaderV2";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import { Checkbox, SelectPicker, Tooltip, Whisper } from "rsuite";
import StringUtils from "lib/StringUtils";
import _ from "underscore";
import moment from "moment";
import CustomerGlobalFilterDrawer from "components/CustomerGlobalFilterDrawer";
import swal from "sweetalert";
import NumberFormat from "react-number-format";
import LinkUtils from "../../lib/LinkUtils";

import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPayments,
  ConnectNotificationBanner,
  ConnectBalances,
  ConnectComponentsProvider,
  ConnectAccountManagement,
  ConnectPayouts,
} from "@stripe/react-connect-js";
import Constant from "lib/Constant";

function StripeConnectSummary(props) {
  // We use `useState` to ensure the Connect instance is only initialized once
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      console.log("fetchClientSecret called", props?.clientSecret);

      return props?.clientSecret;
    };

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey:
        APIV2.ENV_CURRENT == APIV2.ENV_PRODUCTION
          ? Constant.STRIPE_KEY
          : Constant.STRIPE_KEY_TEST,
      fetchClientSecret: fetchClientSecret,
      fonts: [
        {
          src: "https://fonts.googleapis.com/css2?family=Karla:wght@400;700",
          family: "Karla",
        },
      ],
      appearance: {
        // See all possible variables below
        overlays: "dialog",
        variables: {
          fontFamily: "Karla, sans-serif",
          colorPrimary: "#fb6340",
        },
      },
    });
  });

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <ConnectNotificationBanner></ConnectNotificationBanner>
      <Card className="border mb-4">
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="">
              <h3 className="m-0 text-dark">Stripe Balance</h3>
              <p className="small text-dark m-0" style={{ lineHeight: 1.2 }}>
                Your current Stripe balance, available funds, and funds on their
                way to your bank.
              </p>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <ConnectBalances></ConnectBalances>
        </CardBody>
      </Card>
    </ConnectComponentsProvider>
  );
}

function StripeConnectSettings(props) {
  // We use `useState` to ensure the Connect instance is only initialized once
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      console.log("fetchClientSecret called", props?.clientSecret);

      return props?.clientSecret;
    };

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey:
        APIV2.ENV_CURRENT == APIV2.ENV_PRODUCTION
          ? Constant.STRIPE_KEY
          : Constant.STRIPE_KEY_TEST,
      fetchClientSecret: fetchClientSecret,
      fonts: [
        {
          src: "https://fonts.googleapis.com/css2?family=Karla:wght@400;700",
          family: "Karla",
        },
      ],
      appearance: {
        // See all possible variables below
        overlays: "dialog",
        variables: {
          fontFamily: "Karla, sans-serif",
          colorPrimary: "#fb6340",
        },
      },
    });
  });

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <ConnectNotificationBanner></ConnectNotificationBanner>
      <Card className="border mb-4">
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="">
              <h3 className="m-0 text-dark">Stripe Settings</h3>
              <p className="small text-dark m-0" style={{ lineHeight: 1.2 }}>
                View and manage your Stripe payment processing settings.
              </p>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <ConnectAccountManagement></ConnectAccountManagement>
        </CardBody>
      </Card>
    </ConnectComponentsProvider>
  );
}

function StripeConnectPayouts(props) {
  // We use `useState` to ensure the Connect instance is only initialized once
  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      console.log("fetchClientSecret called", props?.clientSecret);

      return props?.clientSecret;
    };

    return loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey:
        APIV2.ENV_CURRENT == APIV2.ENV_PRODUCTION
          ? Constant.STRIPE_KEY
          : Constant.STRIPE_KEY_TEST,
      fetchClientSecret: fetchClientSecret,
      fonts: [
        {
          src: "https://fonts.googleapis.com/css2?family=Karla:wght@400;700",
          family: "Karla",
        },
      ],
      appearance: {
        // See all possible variables below
        overlays: "dialog",
        variables: {
          fontFamily: "Karla, sans-serif",
          colorPrimary: "#fb6340",
        },
      },
    });
  });

  return (
    <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
      <Card className="border mb-4">
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="">
              <h3 className="m-0 text-dark">Bank Account Payouts</h3>
              <p className="small text-dark m-0" style={{ lineHeight: 1.2 }}>
                View current and historical payouts to your connected bank
                accounts.
              </p>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <ConnectPayouts />
        </CardBody>
      </Card>
    </ConnectComponentsProvider>
  );
}

class StorefrontPaymentProcessingView extends React.Component {
  state = {
    active: {
      id: "Summary",
      name: "Summary",
    },
    initialTabs: [
      {
        id: "Summary",
        name: "Summary",
      },
      {
        id: "Payouts",
        name: "Payouts",
      },
      {
        id: "Settings",
        name: "Settings",
      },
    ],
    tabs: [
      {
        id: "Summary",
        name: "Summary",
      },
      {
        id: "Payouts",
        name: "Payouts",
      },
      {
        id: "Settings",
        name: "Settings",
      },
    ],
  };

  loadAccount() {
    this.setState({
      loading: true,
    });

    APIV2.getStoreStripeAccount(this.props.match.params.storeID).then(
      (data) => {
        console.log(data);

        let tabs = this.state.initialTabs;

        if (!data?.data?.account) {
          tabs = this.state.initialTabs.map((item) => {
            if (item?.id == "Summary") {
              return {
                ...item,
                disabled: false,
              };
            }

            return {
              ...item,
              disabled: true,
            };
          });
        }

        this.setState({
          account: data.data.account,
          clientSecret: data.data.clientSecret,
          stripeComponents: data.data.stripeComponents,
          loading: false,
          tabs,
        });
      },
      (e) => {
        alert(
          "Unable to load store payment processing settings. Reload and try again."
        );
      }
    );
  }

  loadStore() {
    this.setState({
      loading: true,
      tabs: this.state.initialTabs.map((item) => {
        if (item?.id == "Summary") {
          return {
            ...item,
            disabled: false,
          };
        }

        return {
          ...item,
          disabled: true,
        };
      }),
    });

    APIV2.getStoreByID(this.props.match.params.storeID).then(
      (data) => {
        console.log(data?.data?.store);

        this.setState({
          store: data.data.store,
        });

        this.loadAccount();
      },
      (e) => {
        alert("Unable to load store settings. Reload and try again.");
      }
    );
  }

  getAccountStatus(account) {
    if (!account) {
      return "--";
    }

    if (!account?.details_submitted) {
      return <span className="text-danger">SETUP INCOMPLETE</span>;
    }

    if (!account?.charges_enabled) {
      return <span className="text-danger">PROCESSING DISABLED</span>;
    }

    return "ACTIVE";
  }

  continue() {
    this.setState({
      submitting: true,
    });

    if (this.state.account?.details_submitted) {
      APIV2.getStoreStripeLoginLink(this.props.match.params.storeID).then(
        (data) => {
          LinkUtils.openInNewTab(data?.data?.link?.url);
        },
        (e) => {
          alert("Unable to retrive login link, please try again.");
        }
      );

      return;
    }

    if (this.state.account) {
      APIV2.getStoreStripeSetupLink(this.props.match.params.storeID).then(
        (data) => {
          LinkUtils.openInNewTab(data?.data?.link?.url);
        },
        (e) => {
          alert("Unable to retrive setup link, please try again.");
        }
      );
    } else {
      APIV2.createStoreStripeAccount(this.props.match.params.storeID).then(
        (data) => {
          let account = data?.data?.account;

          this.setState({
            account,
          });

          APIV2.getStoreStripeSetupLink(this.props.match.params.storeID)
            .then(
              (data) => {
                LinkUtils.openInNewTab(data?.data?.link?.url);
              },
              (e) => {
                alert("Unable to retrive setup link, please try again.");
              }
            )
            .finally(() => {
              this.setState({
                submitting: false,
              });
            });
        },
        (e) => {
          alert(
            "Unable to create payment processing account, please try again."
          );

          this.setState({
            submitting: false,
          });
        }
      );
    }
  }

  openDashboard() {
    this.setState({
      dashboardSubmitting: true,
    });

    APIV2.getStripeDashboardLink(this.props.match.params.storeID)
      .then(
        (data) => {
          LinkUtils.openInNewTab(data?.data?.link?.url);
        },
        (e) => {
          alert("Unable to retrive dashboard link, please try again.");
        }
      )
      .finally(() => {
        this.setState({
          dashboardSubmitting: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.storeID != prevProps.match.params.storeID) {
      this.loadStore();
    }
  }

  componentDidMount() {
    this.loadStore();
  }

  render() {
    return (
      <>
        {/* Page content */}
        <Container className="main-content-padding" fluid>
          {/* Table */}
          <PageHeader
            title="Payment Processing"
            tabs={
              <CardSelectorHeader
                options={this.state.tabs}
                value={this.state.active}
                onChange={(e) => {
                  this.setState(
                    {
                      active: e,
                    },
                    () => {
                      this.loadAccount();
                    }
                  );
                }}
              ></CardSelectorHeader>
            }
            showBackButton={false}
          />

          {this.state.loading ? (
            <>
              <div className="skeleton" style={{ height: 300 }}></div>
            </>
          ) : (
            <>
              {this.state.active?.id == "Summary" ? (
                <>
                  {this.state.clientSecret ? (
                    <>
                      <StripeConnectSummary
                        clientSecret={this.state.clientSecret}
                      ></StripeConnectSummary>
                    </>
                  ) : null}
                  <Card className="border mb-4" style={{ height: "100%" }}>
                    <CardHeader className="p-3">
                      {(!this.state.store?.twilioNumber &&
                        !this.state.store?.birdEnabled) ||
                      !this.state.store?.contact?.email ? (
                        <Alert color="danger" className="mt-0 mb-3">
                          Please add a store phone number and store email to
                          continue.
                        </Alert>
                      ) : null}
                      <Row className="align-items-center">
                        <Col xs="">
                          <h3 className="mb-0">Stripe Account Status</h3>
                          <p className="m-0 small">
                            View your current payment processing account status.
                          </p>
                        </Col>
                        <Col xs="auto">
                          {this.state.account?.charges_enabled && (
                            <Button
                              size="sm"
                              color="secondary"
                              outline
                              disabled={
                                this.state?.loading ||
                                this.state.dashboardSubmitting
                              }
                              onClick={() => {
                                this.openDashboard();
                              }}
                            >
                              {this.state.dashboardSubmitting ? (
                                <Spinner size="sm"></Spinner>
                              ) : (
                                "View Stripe Dashboard"
                              )}
                            </Button>
                          )}

                          {!this.state.account?.details_submitted && (
                            <Button
                              size="sm"
                              outline
                              color={
                                this.state.account?.charges_enabled
                                  ? "secondary"
                                  : "primary"
                              }
                              disabled={
                                this.state?.loading ||
                                this.state.submitting ||
                                this.state.dashboardSubmitting ||
                                !this.state.store?.contact?.email ||
                                !this.state.store?.twilioNumber
                              }
                              onClick={() => {
                                this.continue();
                              }}
                            >
                              {this.state.submitting ? (
                                <>
                                  <Spinner size="sm"></Spinner>
                                </>
                              ) : (
                                <>
                                  {this.state.account ? (
                                    <>
                                      {this.state.account?.details_submitted
                                        ? "Stripe Settings"
                                        : "Continue Setup"}
                                    </>
                                  ) : (
                                    "Get Started"
                                  )}
                                </>
                              )}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="p-3">
                      {this.state.account ? (
                        <>
                          <div className="mb-3 pb-3 border-bottom">
                            <h3 className="m-0 text-dark">
                              Status:
                              <span className="ml-1 font-weight-bold">
                                {this.getAccountStatus(this.state.account)}
                              </span>
                            </h3>
                          </div>
                          <div className="mb-4">
                            <h2 className="mb-3 text-dark">
                              Processing Capabilities
                            </h2>
                            <Row>
                              {[
                                {
                                  name: "Card Payments",
                                  key: "card_payments",
                                },
                                {
                                  name: "Checkout Link™ Payments",
                                  key: "link_payments",
                                },
                                {
                                  name: "ACH Payments",
                                  key: "us_bank_account_ach_payments",
                                },
                                {
                                  name: "Inter-Bank Payments",
                                  key: "bank_transfer_payments",
                                },
                                {
                                  name: "Balance Transfers To Your Bank",
                                  key: "transfers",
                                },
                              ].map((item, index) => (
                                <Col
                                  xs="12"
                                  sm="6"
                                  className="mb-2 text-dark"
                                  key={index}
                                >
                                  <span className="font-weight-bold mr-1">
                                    {item?.name}:
                                  </span>
                                  <span
                                    className={`text-underline ${
                                      this.state.account?.capabilities?.[
                                        item?.key
                                      ] == "inactive"
                                        ? "text-danger"
                                        : ""
                                    }`}
                                  >
                                    {this.state.account?.capabilities?.[
                                      item?.key
                                    ] != "inactive"
                                      ? "Enabled"
                                      : "Disabled"}
                                  </span>
                                </Col>
                              ))}
                            </Row>
                          </div>
                          <div
                            className={`mb-4 ${
                              !this.state.account ? "d-none" : ""
                            }`}
                          >
                            <h2 className="mb-3 text-dark">
                              Connected Accounts
                            </h2>
                            <Row>
                              {!this.state.account?.external_accounts?.data
                                ?.length && (
                                <>
                                  <Col xs="12">
                                    <p className="text-danger m-0">
                                      Please add one or more connected bank
                                      accounts to receive funds from your
                                      processed payments.
                                    </p>
                                  </Col>
                                </>
                              )}
                              {this.state.account?.external_accounts?.data?.map(
                                (item, index) => (
                                  <Col
                                    xs="12"
                                    className="mb-2 text-dark"
                                    key={index}
                                  >
                                    <span className="font-weight-bold mr-1">
                                      {item?.bank_name} x{item?.last4}:
                                    </span>
                                    <span className="text-capitalize">
                                      {item?.available_payout_methods
                                        ?.length ? (
                                        <>
                                          (
                                          {item?.available_payout_methods
                                            ?.map((item) => `${item} payouts`)
                                            .join(", ")}
                                          )
                                        </>
                                      ) : null}
                                    </span>
                                  </Col>
                                )
                              )}
                            </Row>
                          </div>
                        </>
                      ) : (
                        <>
                          {this.state.loading ? (
                            <p className="m-0 text-dark">
                              Hang tight while we load your account
                              information...
                            </p>
                          ) : (
                            <p className="m-0 text-dark">
                              Get started by creating your store's payment
                              processing account, powered by our processing
                              partner Stripe.
                            </p>
                          )}
                        </>
                      )}
                    </CardBody>
                  </Card>
                </>
              ) : null}
              {this.state.active?.id == "Payouts" ? (
                <>
                  {this.state.clientSecret ? (
                    <>
                      <StripeConnectPayouts
                        clientSecret={this.state.clientSecret}
                      ></StripeConnectPayouts>
                    </>
                  ) : (
                    <div className="p-4 rounded border text-center">
                      <h4 className="m-0">Something Went Wrong</h4>
                      <p className="small text-dark m-0">
                        We're unable to load your Stripe payouts. Please refresh
                        and try again.
                      </p>
                    </div>
                  )}
                </>
              ) : null}
              {this.state.active?.id == "Settings" ? (
                <>
                  {this.state.clientSecret ? (
                    <>
                      <StripeConnectSettings
                        clientSecret={this.state.clientSecret}
                      ></StripeConnectSettings>
                    </>
                  ) : (
                    <div className="p-4 rounded border text-center">
                      <h4 className="m-0">Something Went Wrong</h4>
                      <p className="small text-dark m-0">
                        We're unable to load your Stripe settings. Please
                        refresh and try again.
                      </p>
                    </div>
                  )}
                </>
              ) : null}
            </>
          )}
        </Container>
      </>
    );
  }
}

export default StorefrontPaymentProcessingView;
