import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import swal from "sweetalert";
import FormGroup from "reactstrap/lib/FormGroup";
import Alert from "reactstrap/lib/Alert";
import TaskModifyDrawer from "./TaskModifyDrawer";
import CustomerInformationWidget from "components/Widgets/CustomerInformationWidget";
import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import NoteBody from "components/NoteBody";
import NoteBodyV2 from "components/NoteBodyV2";
import Spinner from "reactstrap/lib/Spinner";
import LeadContactInformationWidget from "components/Widgets/LeadContactInformationWidget";
import AffiliateAboutWidget from "components/Widgets/AffiliateAboutWidget";
import LinkUtils from "lib/LinkUtils";
import Linkify from "components/Linkify";
import FranLeadMessageDrawer from "../../components/Drawers/FranLeadMessageDrawer";
import FranDevLeadNotesWidget from "components/Widgets/FranDevLeadNotesWidget";
import ReactPlayer from "react-player";
import ResourceCenterTopicEmbed from "../ResourceCenterTopicEmbed";

class NSOStepDetailDrawer extends React.Component {
  state = {
    dueDate: moment().day(6).toDate(),
    subject: "",
    description: "",
    relatedToType: "",
    type: "",
    priority: "Normal",
    status: "Not Started",
    assignedTo: null,
    timeToContact: "",
    customers: [],
    recentFirst: "recentFirst",
  };

  dropdownRef = React.createRef();

  fetchStep() {
    this.setState({
      item: this.props.nsoStep,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.nsoStep != this.props.nsoStep && this.props.nsoStep) {
      this.fetchStep();
    }
  }

  componentDidMount() {
    if (this.props.nsoStep) {
      this.fetchStep();
    }
  }

  toggleModal() {
    this.setState({
      name: "",
      email: "",
      phone: {
        value: "",
      },
      plan: null,
    });

    this.props.onClose();
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4 pb-4">
            <div style={{ position: "relative", top: "7px" }}>
              <h3 className="m-0">NSO Step</h3>
            </div>
            <Drawer.Actions></Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            <Row className="align-items-center">
              <Col xs="">
                {this.state.item?.topic?._id ? (
                  <>
                    <ResourceCenterTopicEmbed topic={this.state.item?.topic} />
                  </>
                ) : (
                  <>
                    <div
                      className="mt-2 p-3 border rounded"
                      dangerouslySetInnerHTML={{
                        __html: this.state.item?.description?.html,
                      }}
                    ></div>
                    {this.state.item?.description?.text &&
                    this.state.item?.files &&
                    this.state.item?.files?.length ? (
                      <hr className="my-3"></hr>
                    ) : null}
                  </>
                )}

                {this.state.item?.files && this.state.item?.files?.length ? (
                  <>
                    <h4 className="my-3">Files:</h4>
                    {this.state.item?.files?.map((at, i) => (
                      <div className={`border p-2 rounded mb-2`} key={i}>
                        <Row className="align-items-center">
                          <Col xs="12" sm="">
                            <p className="small m-0 text-dark text-truncate">
                              {at?.fileName}
                            </p>
                          </Col>
                          <Col
                            xs="12"
                            sm="auto"
                            className="mt-3 mt-sm-0 text-right"
                          >
                            <Button
                              className="btn-icon-only"
                              size="sm"
                              outline
                              color="secondary"
                              disabled={!at?.url}
                              onClick={() => {
                                if (!at?.url) {
                                  return;
                                }

                                LinkUtils.openPrivateURL(at?.url);
                              }}
                            >
                              <i className="mdi mdi-eye"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </>
                ) : null}
              </Col>
            </Row>
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(NSOStepDetailDrawer);
