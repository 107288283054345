import APIV2 from "lib/APIV2";
import Constant from "lib/Constant";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
  Badge,
} from "reactstrap";
import Alert from "reactstrap/lib/Alert";
import { SelectPicker, Tooltip, Whisper } from "rsuite";
import swal from "sweetalert";
import _ from "underscore";
import CustomerOpenActivitiesWidget from "./CustomerOpenActivitiesWidget";
import CustomerUnclaimedBoxWidget from "./CustomerUnclaimedBoxWidget";
import moment from "moment";
import ReferralDetailDrawer from "components/Drawers/ReferralDetailDrawer";
import ReferralCreateDrawer from "components/Drawers/ReferralCreateDrawer";

class CustomerReferralsWidget extends React.Component {
  state = {
    expand: false,
    phone: {
      value: "",
    },
    name: "",
    email: "",
  };

  updateLocation() {
    this.setState({ locationSubmitting: true });

    console.log(this.state.selectedStore);

    APIV2.assignCustomerToStore(
      this.state.selectedStore,
      this.props?.customer?._id
    )
      .then(
        (data) => {
          this.props.history.push(
            "/storefront/" +
              this.state.selectedStore +
              "/members/details/" +
              data.data.customer._id +
              "/summary"
          );

          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({ modifyLocation: false });
        },
        (e) => {
          this.setError(
            "error",
            "Could not update customer, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ locationSubmitting: false });
      });
  }

  renenerateCode() {
    this.setState({ regenerating: true });

    APIV2.regenerateCustomerReferralCode(
      this.props.customer?.storeID,
      this.props?.customer?._id
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);
        },
        (e) => {
          this.setError(
            "error",
            "Could not regenerate customer referral coupon code, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ regenerating: false });
      });
  }

  updateInitialSource() {
    this.setState({ initialSourceSubmitting: true });

    APIV2.setCustomerVariable(
      this.props.customer?.storeID,
      this.props?.customer?._id,
      "initialSource",
      this.state.selectedInitialSource ? this.state.selectedInitialSource : null
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({ modifyInitialSource: false });
        },
        (e) => {
          this.setError(
            "error",
            "Could not update customer, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ initialSourceSubmitting: false });
      });
  }

  updateConversionSource() {
    this.setState({ conversionSourceSubmitting: true });

    APIV2.setCustomerVariable(
      this.props.customer?.storeID,
      this.props?.customer?._id,
      "conversionSource",
      this.state.selectedConversionSource
        ? this.state.selectedConversionSource
        : null
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({ modifyConversionSource: false });
        },
        (e) => {
          this.setError(
            "error",
            "Could not update customer, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ conversionSourceSubmitting: false });
      });
  }

  updateInformation() {
    this.setState({ submitting: true });

    let phone = "";

    if (this.state.phone?.value.length == 10) {
      phone = `+1${this.state.phone?.value}`;
    }

    APIV2.updateCustomerInformation(
      this.props?.match?.params?.storeID,
      this.props?.customer?._id,
      this.state.name,
      this.state.email,
      phone
    )
      .then(
        (data) => {
          PubSub.publish(Event.CUSTOMER.MODIFIED, data.data.customer);

          this.setState({ openModify: false });
        },
        (e) => {
          this.setError(
            "error",
            "Could not update customer, please try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  getStores() {
    this.setState({
      loadingStores: true,
    });

    APIV2.getStoresForUser()
      .then(
        (data) => {
          let storeOptions = data.data.stores?.map((s) => {
            return {
              value: s._id,
              label: s.name.replace("Project LeanNation", "PLN"),
            };
          });

          this.setState({
            stores: data.data.stores,
            storeOptions: storeOptions,
          });
        },
        (e) => {
          this.setError("error", "Could not fetch stores.");
        }
      )
      .finally(() => {
        this.setState({
          loadingStores: false,
        });
      });
  }

  getStoreStaff() {
    this.setState({
      loadingCoaches: true,
    });

    APIV2.getStaffForStore(this?.props?.customer?.storeID, 1, 10000, {
      position: { $nin: ["Terminated", "Applicant"] },
      terminated: { $ne: true },
    })
      .then(
        (data) => {
          let coachOptions = data.data.staff?.map((s) => {
            return {
              value: s._id,
              label: s.name,
            };
          });

          this.setState({
            coaches: data.data.staff,
            coachOptions: coachOptions,
          });
        },
        (e) => {
          this.setError("error", "Could not fetch coaches.");
        }
      )
      .finally(() => {
        this.setState({
          loadingCoaches: false,
        });
      });
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 4000);
  }

  componentDidMount() {
    this.getStores();
    this.getStoreStaff();
  }

  componentDidUpdate(prevProps) {
    if (this.props.customer != prevProps.customer) {
      this.getStoreStaff();
    }
  }

  formatStatus(referral) {
    if (!referral) {
      return "--";
    }

    if (referral?.applied) {
      return "Used";
    }

    if (!referral?.applied && referral?.availableForUse) {
      return "Unused";
    }

    if (!referral?.availableForUse) {
      return "Pending";
    }
  }

  generateReferralValueString(given) {
    if (!given || !given?.length) {
      return "";
    }

    let totalValue = 0;
    let appliedValue = 0;

    const le = given?.map((v) => {
      totalValue += v?.value;
    });

    const used = given?.map((v) => {
      if (!v?.applied) {
        appliedValue += v?.value;
      }
    });

    return `( ${StringUtils.centsToCurrency(
      appliedValue
    )} Unused, ${StringUtils.centsToCurrency(totalValue)} Total)`;
  }

  render() {
    return (
      <>
        <Card className="mb-4 shadow border">
          <CardHeader>
            <Row className="align-items-center">
              <div className="col">
                <h3 className="mb-0">
                  Referral Code
                  <Whisper
                    placement="auto"
                    trigger="hover"
                    speaker={
                      <Tooltip>
                        <p className="m-0">
                          New members can use this referral code at checkout in
                          the coupon code field.
                        </p>
                      </Tooltip>
                    }
                  >
                    <i
                      className="mdi mdi-information-outline ml-2"
                      style={{ position: "relative", top: 1 }}
                    ></i>
                  </Whisper>
                </h3>
              </div>
              <Col xs="auto">
                <Button
                  onClick={() => {
                    swal({
                      title: "Regenerate Referral Coupon Code?",
                      text: "Are you sure you want regenerate the member's referral code? This will make their existing coupon code invalid.",
                      icon: "warning",
                      buttons: ["Nevermind", "Regenerate"],
                      dangerMode: true,
                    }).then((confirm) => {
                      if (!confirm) {
                        return;
                      }

                      this.renenerateCode();
                    });
                  }}
                  size="sm"
                  color="secondary"
                  outline
                  disabled={this.state.regenerating}
                >
                  {this.state.regenerating ? (
                    <>
                      <Spinner size="sm" color="white" />
                    </>
                  ) : (
                    <>Regenerate</>
                  )}
                </Button>
              </Col>
            </Row>
          </CardHeader>
          {this.props.loading ? (
            <CardBody>
              <Spinner size="sm" color="dark" className="mr-2" /> Loading...
            </CardBody>
          ) : (
            <>
              <CardBody className="bg-transparent p-0">
                <div className="px-4 py-4 border-bottom">
                  {this?.props?.customer?.referralCode ? (
                    <>
                      <div>
                        <h4 className="m-0 text-dark">Referral Code</h4>
                        <p className="mb-3 small text-dark">
                          This code can be shared with new members who want to
                          sign up as a referral of{" "}
                          {this.props?.customer?.name ?? "this member"}. The
                          code can be applied as a coupon at checkout online.
                        </p>
                        <pre
                          style={{ display: "inline", borderRadius: 3 }}
                          className="py-2 px-3 border border-light bg-superlight"
                        >
                          {this?.props?.customer?.referralCode}
                        </pre>
                      </div>
                      <hr className="my-4"></hr>
                      <div>
                        <h4 className="mb-0 text-dark">Referral Link</h4>
                        <p className="mb-3 small text-dark">
                          This link can be shared with new members to sign up
                          online as a referral of{" "}
                          {this.props.customer?.name ?? "this member"}.
                        </p>
                        <pre
                          style={{ display: "inline", borderRadius: 3 }}
                          className="py-2 px-3 border border-light bg-superlight"
                        >
                          {this?.props?.customer?.referralCodeURL}
                        </pre>
                      </div>
                    </>
                  ) : (
                    <Badge color="" className="bg-yellow text-dark">
                      None
                    </Badge>
                  )}
                </div>
              </CardBody>
            </>
          )}
        </Card>

        {!this.props.loading && (
          <>
            <Card className="mb-4 shadow border">
              <CardHeader>
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Referrals Received</h3>
                  </div>
                  <Col xs="auto">
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => {
                        this.setState({ referralCreate: true });
                      }}
                    >
                      Add
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="bg-transparent p-0">
                <div className="px-4 py-2 border-bottom font-weight-bold bg-superlight text-dark">
                  <Row>
                    <Col xs="12" sm="12" md="4">
                      Member
                    </Col>
                    <Col xs="12" sm="12" md="4">
                      Status
                    </Col>
                    <Col xs="12" sm="12" md="4">
                      Received On
                    </Col>
                  </Row>
                </div>
                {this?.props?.customer?.referralsReceived?.length ? (
                  <>
                    {this.props?.customer?.referralsReceived?.map(
                      (referral, i) => (
                        <div
                          onClick={() => {
                            console.log(referral);

                            this.setState({
                              referral,
                              referralOpen: true,
                              mode: "received",
                            });
                          }}
                          className="cursor-pointer bg-superlight--hover px-4 py-3 border-bottom"
                        >
                          <Row className="align-items-center">
                            <Col xs="12" sm="12" md="4">
                              {referral?.customer?.name
                                ? referral?.customer?.name
                                : "(unknown)"}
                            </Col>
                            <Col xs="12" sm="12" md="4">
                              {this.formatStatus(referral)}
                            </Col>
                            <Col xs="12" sm="12" md="4">
                              {moment(referral?.referredOn).format(
                                "MM/DD/YYYY"
                              )}
                            </Col>
                          </Row>
                        </div>
                      )
                    )}
                  </>
                ) : (
                  <>
                    <div className="px-4 py-3 border-bottom">
                      <p className="m-0 text-dark">No referrals received.</p>
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
            <Card className="mb-4 shadow border">
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="">
                    <h3 className="mb-0">Referrals Given</h3>
                    <p className="m-0">
                      <small>
                        {this.generateReferralValueString(
                          this?.props?.customer?.referralsGiven
                        )}
                      </small>
                    </p>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="bg-transparent p-0">
                <div className="px-4 py-2 border-bottom font-weight-bold bg-superlight text-dark">
                  <Row>
                    <Col xs="12" sm="12" md="4">
                      Member
                    </Col>
                    <Col xs="12" sm="12" md="4">
                      Status
                    </Col>
                    <Col xs="12" sm="12" md="4">
                      Given On
                    </Col>
                  </Row>
                </div>
                {this?.props?.customer?.referralsGiven?.length ? (
                  <>
                    {this.props?.customer?.referralsGiven?.map(
                      (referral, i) => (
                        <div
                          onClick={() => {
                            console.log(referral);

                            this.setState({
                              referral,
                              referralOpen: true,
                              mode: "given",
                            });
                          }}
                          className="cursor-pointer bg-superlight--hover px-4 py-3 border-bottom"
                        >
                          <Row className="align-items-center">
                            <Col xs="12" sm="12" md="4">
                              {referral?.customer?.name
                                ? referral?.customer?.name
                                : "(unknown)"}
                            </Col>
                            <Col xs="12" sm="12" md="4">
                              {this.formatStatus(referral)}
                            </Col>
                            <Col xs="12" sm="12" md="4">
                              {moment(referral?.referredOn).format(
                                "MM/DD/YYYY"
                              )}
                            </Col>
                          </Row>
                        </div>
                      )
                    )}
                  </>
                ) : (
                  <>
                    <div className="px-4 py-3 border-bottom">
                      <p className="m-0 text-dark">No referrals given.</p>
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
          </>
        )}
        <ReferralDetailDrawer
          open={this.state.referralOpen}
          referral={this.state.referral}
          customer={this.props.customer}
          mode={this.state.mode}
          onClose={() => {
            this.setState({ referral: null, referralOpen: false });
          }}
        ></ReferralDetailDrawer>
        <ReferralCreateDrawer
          open={this.state.referralCreate}
          customer={this.props.customer}
          onClose={() => {
            this.setState({ referralCreate: false });
          }}
        ></ReferralCreateDrawer>
      </>
    );
  }
}

export default withRouter(CustomerReferralsWidget);
