import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Table,
  Media,
  Badge,
  Progress,
  DropdownItem,
  UncontrolledDropdown,
  PaginationItem,
  PaginationLink,
  CardFooter,
  DropdownMenu,
  DropdownToggle,
  Pagination,
  Button,
  Input,
  Form,
  Spinner,
  Collapse,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import MealRow from "components/MealRow";
import API from "lib/API";
import PubSub from "lib/PubSub";
import _, { filter, findIndex } from "underscore";
import Event from "lib/Event";
import CreateMealModal from "components/CreateMealModal";
import APIV2 from "lib/APIV2";
import PageHeader from "components/Headers/PageHeader";
import CardSelectorHeader from "components/Headers/CardSelectorHeader";
import LocalStorage from "lib/LocalStorage";
import Constant from "lib/Constant";
import { Dropdown, Popover, SelectPicker, Tooltip, Whisper } from "rsuite";
import swal from "sweetalert";
import LibraryCategoryEditDrawer from "components/Drawers/LibraryCategoryEditDrawer";
import LibraryModuleEditDrawer from "components/Drawers/LibraryModuleEditDrawer";
import LibraryAllModulesDrawer from "components/Drawers/LibraryAllModulesDrawer";
import { convertTypeAcquisitionFromJson } from "typescript";
import LibraryAllTopicsDrawer from "components/Drawers/LibraryAllTopicsDrawer";
import { Capacitor } from "@capacitor/core";

class LearningCenterCourseLibrary extends React.Component {
  state = {
    active: 0,
    loadingCategories: true,
    productGroups: [],
    selectedFile: null,
    loadingMeals: true,
    meals: [],
    openCategory: false,
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  loadStaffRoles() {
    return new Promise((resolve) => {
      APIV2.getStaffRoles(1, 100, {}, {}).then(
        (staffData) => {
          this.setState({
            staffRoles: staffData.data?.roles,
          });

          resolve();
        },
        (e) => {
          console.error(e);
          resolve();
        }
      );
    });
  }

  loadStores() {
    this.setState({
      loadingStores: true,
    });

    let filters = {};

    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!this.isGlobal()) {
      let storeIDs = user?.storePermissions?.map((store) => store.storeID);

      filters = {
        _idString: { $in: storeIDs },
      };
    }

    APIV2.getStores(filters, { _id: 1, name: 1 })
      .then(
        (storeData) => {
          this.setState({
            stores: storeData.data?.stores,
            storeOptions: storeData.data?.stores?.map((store) => {
              return {
                label: store.name?.replace("Project LeanNation", "PLN")?.trim(),
                value: store._id,
              };
            }),
            store: storeData.data?.stores?.length
              ? storeData.data?.stores[0]?._id
              : null,
            loadingStores: false,
          });

          if (storeData.data?.stores?.length) {
            this.loadCourses(storeData.data?.stores[0]?._id);
          }
        },
        (e) => {
          console.error(e);

          this.setState({
            loadingStores: false,
          });
        }
      )
      .finally(() => {});
  }

  loadCourses(storeID) {
    this.setState({
      loading: true,
    });

    let user = LocalStorage.get(Constant.CACHE.USER);

    let tracksFilter = {};

    if (!this.isGlobal()) {
      let storePermissions = _.findWhere(user.storePermissions, { storeID });

      if (storePermissions?.staffProfile) {
        let roleOptions = [storePermissions.staffProfile.roleID];

        let role = _.findWhere(this.state.staffRoles, {
          _id: storePermissions.staffProfile.roleID,
        });

        if (role?.nextRoleID) {
          roleOptions.push(role.nextRoleID);
        }

        for (let role of this.state.staffRoles) {
          if (
            roleOptions?.includes(role?.nextRoleID) &&
            !roleOptions?.includes(role?._id)
          ) {
            roleOptions.push(role._id);
          }
        }

        tracksFilter = {
          staffRoleID: {
            $in: roleOptions,
          },
          draft: { $ne: true }, // TODO: Debug
        };
      }
    }

    //console.log(tracksFilter);

    let courses = [];

    APIV2.getLearningTracks(1, 100, tracksFilter, { name: 1 }).then(
      async (data) => {
        //console.log(data?.data?.tracks);

        let globalUser = this.isGlobal();

        for (let track of data?.data?.tracks) {
          for (let course of track?.courses) {
            if (
              (course?.draft && !globalUser) ||
              _.findWhere(courses, { _id: course._id })
            ) {
              continue;
            }

            if (course?.cover?.url) {
              try {
                let res = await APIV2.getPrivateFileURL(course.cover.url);

                course.cover.url = res.data.url;
              } catch (e) {
                course.cover.url = "";
              }
            }

            courses.push(course);
          }
        }

        courses = _.sortBy(courses, (course) => course.name);

        this.setState({
          courses,
          loading: false,
        });
      },
      (e) => {
        this.setState({
          loading: false,
        });
      }
    );
  }

  componentDidMount() {
    this.loadStaffRoles().then(() => {
      this.loadStores();
    });
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isGlobal() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.globalPermissions?.length ? true : false;
  }

  /**
   * Checks if the current user is a global user via permissions
   *
   * @returns
   */
  isProduction() {
    let user = LocalStorage.get(Constant.CACHE.USER);

    if (!user) {
      return false;
    }

    return user?.productionPermissions?.length ? true : false;
  }

  filterCourses(search, courses) {
    if (!search || search?.length <= 2) {
      return courses;
    }

    return courses.filter((course) => {
      return (
        course?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
        course?.description?.toLowerCase()?.includes(search.toLowerCase())
      );
    });
  }

  render() {
    return (
      <>
        <div
          className="bg-white border-bottom px-4 py-4 mb-4"
          style={{ marginTop: "5rem" }}
        >
          {/* Page content */}
          {Capacitor.isNativePlatform() && (
            <div className="pt-5 d-md-none"></div>
          )}
          <Row className="align-items-center">
            <Col xs="12" sm="">
              <h2 className="m-0 text-dark">Course Library</h2>
            </Col>
            <Col xs="12" sm="auto" className="mt-2 mt-sm-0">
              <SelectPicker
                block={true}
                data={this.state.storeOptions}
                value={this.state.store}
                cleanable={false}
                onChange={(v) => {
                  this.setState({ store: v });

                  this.loadCourses(v);
                }}
                placeholder="Select A Store"
                label="Viewing Courses For"
                loading={this.state.loadingStores}
              />
            </Col>
            <Col xs="12" className="mt-2">
              <Row className="align-items-center">
                <Col xs="" className={`${this.state.search ? "pr-2" : ""}`}>
                  <Input
                    className="form-control"
                    bsSize="sm"
                    value={this.state.search}
                    placeholder="Search Courses"
                    onChange={(e) => {
                      this.setState({ search: e.target.value });
                    }}
                  />
                </Col>
                {this.state.search ? (
                  <Col xs="auto" className="pl-0">
                    <Button
                      color="dark"
                      size="sm"
                      className="btn-icon-only m-0"
                      style={{ height: 31 }}
                      outline
                      onClick={() => {
                        this.setState({ search: "" });
                      }}
                    >
                      <i className="mdi mdi-close"></i>
                    </Button>
                  </Col>
                ) : null}
              </Row>
            </Col>
          </Row>
        </div>

        <Container fluid>
          {/* Table */}
          {!this.state.store && !this.state.loadingStores ? (
            <>
              <div className="rounded p-4 border">
                <h3 className="text-center m-0 text-dark">
                  Select a store to view the course library
                </h3>
              </div>
            </>
          ) : (
            <>
              {this.state.loading ? (
                <>
                  <Row className="align-items-top">
                    <Col xs="12" sm="6" md="4" className="mb-3">
                      <Card className={`border`}>
                        <CardHeader className="p-0">
                          <div
                            className="d-flex align-items-center justify-content-center skeleton"
                            style={{
                              height: "20vw",
                              minHeight: 180,
                              maxHeight: 220,
                              width: "100%",
                            }}
                          ></div>
                        </CardHeader>
                        <CardBody className="p-2">
                          <h3
                            className="rounded skeleton"
                            style={{ height: 21, width: "80%" }}
                          >
                            &nbsp;
                          </h3>
                          <p
                            className="rounded skeleton m-0"
                            style={{
                              lineHeight: 1.3,
                              lineClamp: 3,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              height: 50,
                            }}
                          >
                            &nbsp;
                          </p>
                        </CardBody>
                        <CardFooter className="p-2 border-top-0">
                          <div
                            className="rounded skeleton"
                            style={{ height: 31 }}
                          ></div>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" md="4" className="mb-3">
                      <Card className={`border`}>
                        <CardHeader className="p-0">
                          <div
                            className="d-flex align-items-center justify-content-center skeleton"
                            style={{
                              height: "20vw",
                              minHeight: 180,
                              maxHeight: 220,
                              width: "100%",
                            }}
                          ></div>
                        </CardHeader>
                        <CardBody className="p-2">
                          <h3
                            className="rounded skeleton"
                            style={{ height: 21, width: "80%" }}
                          >
                            &nbsp;
                          </h3>
                          <p
                            className="rounded skeleton m-0"
                            style={{
                              lineHeight: 1.3,
                              lineClamp: 3,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              height: 50,
                            }}
                          >
                            &nbsp;
                          </p>
                        </CardBody>
                        <CardFooter className="p-2 border-top-0">
                          <div
                            className="rounded skeleton"
                            style={{ height: 31 }}
                          ></div>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" md="4" className="mb-3">
                      <Card className={`border`}>
                        <CardHeader className="p-0">
                          <div
                            className="d-flex align-items-center justify-content-center skeleton"
                            style={{
                              height: "20vw",
                              minHeight: 180,
                              maxHeight: 220,
                              width: "100%",
                            }}
                          ></div>
                        </CardHeader>
                        <CardBody className="p-2">
                          <h3
                            className="rounded skeleton"
                            style={{ height: 21, width: "80%" }}
                          >
                            &nbsp;
                          </h3>
                          <p
                            className="rounded skeleton m-0"
                            style={{
                              lineHeight: 1.3,
                              lineClamp: 3,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              height: 50,
                            }}
                          >
                            &nbsp;
                          </p>
                        </CardBody>
                        <CardFooter className="p-2 border-top-0">
                          <div
                            className="rounded skeleton"
                            style={{ height: 31 }}
                          ></div>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" md="4" className="mb-3">
                      <Card className={`border`}>
                        <CardHeader className="p-0">
                          <div
                            className="d-flex align-items-center justify-content-center skeleton"
                            style={{
                              height: "20vw",
                              minHeight: 180,
                              maxHeight: 220,
                              width: "100%",
                            }}
                          ></div>
                        </CardHeader>
                        <CardBody className="p-2">
                          <h3
                            className="rounded skeleton"
                            style={{ height: 21, width: "80%" }}
                          >
                            &nbsp;
                          </h3>
                          <p
                            className="rounded skeleton m-0"
                            style={{
                              lineHeight: 1.3,
                              lineClamp: 3,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              height: 50,
                            }}
                          >
                            &nbsp;
                          </p>
                        </CardBody>
                        <CardFooter className="p-2 border-top-0">
                          <div
                            className="rounded skeleton"
                            style={{ height: 31 }}
                          ></div>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" md="4" className="mb-3">
                      <Card className={`border`}>
                        <CardHeader className="p-0">
                          <div
                            className="d-flex align-items-center justify-content-center skeleton"
                            style={{
                              height: "20vw",
                              minHeight: 180,
                              maxHeight: 220,
                              width: "100%",
                            }}
                          ></div>
                        </CardHeader>
                        <CardBody className="p-2">
                          <h3
                            className="rounded skeleton"
                            style={{ height: 21, width: "80%" }}
                          >
                            &nbsp;
                          </h3>
                          <p
                            className="rounded skeleton m-0"
                            style={{
                              lineHeight: 1.3,
                              lineClamp: 3,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              height: 50,
                            }}
                          >
                            &nbsp;
                          </p>
                        </CardBody>
                        <CardFooter className="p-2 border-top-0">
                          <div
                            className="rounded skeleton"
                            style={{ height: 31 }}
                          ></div>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col xs="12" sm="6" md="4" className="mb-3">
                      <Card className={`border`}>
                        <CardHeader className="p-0">
                          <div
                            className="d-flex align-items-center justify-content-center skeleton"
                            style={{
                              height: "20vw",
                              minHeight: 180,
                              maxHeight: 220,
                              width: "100%",
                            }}
                          ></div>
                        </CardHeader>
                        <CardBody className="p-2">
                          <h3
                            className="rounded skeleton"
                            style={{ height: 21, width: "80%" }}
                          >
                            &nbsp;
                          </h3>
                          <p
                            className="rounded skeleton m-0"
                            style={{
                              lineHeight: 1.3,
                              lineClamp: 3,
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 3,
                              WebkitBoxOrient: "vertical",
                              height: 50,
                            }}
                          >
                            &nbsp;
                          </p>
                        </CardBody>
                        <CardFooter className="p-2 border-top-0">
                          <div
                            className="rounded skeleton"
                            style={{ height: 31 }}
                          ></div>
                        </CardFooter>
                      </Card>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  {this.state.courses?.length ? (
                    <>
                      <Row className="align-items-top">
                        {this.filterCourses(
                          this.state.search,
                          this.state.courses
                        )?.length ? (
                          <>
                            {this.filterCourses(
                              this.state.search,
                              this.state.courses
                            )?.map((course, j) => (
                              <Col
                                xs="12"
                                sm="6"
                                md="4"
                                key={course?._id}
                                className="mb-3"
                              >
                                <Card
                                  className={`cursor-pointer shadow-sm-hover border`}
                                  onClick={() => {
                                    this.props.history.push(
                                      `/university/learning/courses/${course?._id}`
                                    );
                                  }}
                                >
                                  <CardHeader className="p-0">
                                    {course?.draft ? (
                                      <Badge
                                        color=""
                                        className="bg-white border-light border position-absolute ml-2 mt-2"
                                        style={{}}
                                      >
                                        DRAFT
                                      </Badge>
                                    ) : null}
                                    {course?.cover?.url ? (
                                      <div
                                        style={{
                                          height: "20vw",
                                          minHeight: 180,
                                          maxHeight: 220,
                                          borderTopLeftRadius: 6,
                                          borderTopRightRadius: 6,
                                          background: `url(${course?.cover?.url}) no-repeat center center`,
                                          backgroundSize: "cover",
                                        }}
                                      ></div>
                                    ) : (
                                      <div
                                        className="d-flex align-items-center justify-content-center"
                                        style={{
                                          height: "20vw",
                                          minHeight: 180,
                                          maxHeight: 220,
                                          width: "100%",
                                        }}
                                      >
                                        <img
                                          src={require("../../assets/img/brand/pln_primary_no_dot.svg")}
                                          style={{
                                            width: "80%",
                                            maxWidth: 250,
                                          }}
                                          className=""
                                          alt="logo"
                                        ></img>
                                      </div>
                                    )}
                                  </CardHeader>
                                  <CardBody className="p-2">
                                    <h3
                                      className="text-dark mb-1"
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {course?.name}
                                    </h3>
                                    <p
                                      className="small text-dark m-0"
                                      style={{
                                        lineHeight: 1.3,
                                        lineClamp: 3,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitLineClamp: 3,
                                        WebkitBoxOrient: "vertical",
                                        height: 50,
                                      }}
                                    >
                                      {course?.description}
                                    </p>
                                  </CardBody>
                                  <CardFooter className="p-2 border-top-0">
                                    {/**
                                 * <Row className="align-items-center">
                                  <Col xs="12">
                                    <Row className="align-items-center mb-1">
                                      <Col xs="" className="pr-0">
                                        <p className="small text-dark m-0">
                                          Topics Completed
                                        </p>
                                      </Col>
                                      <Col xs="auto" className="pl-0">
                                        <p className="small text-dark m-0">
                                          3/5
                                        </p>
                                      </Col>
                                    </Row>
                                    <div>
                                      <Progress
                                        value={50}
                                        color="success"
                                        size="sm"
                                      ></Progress>
                                    </div>
                                  </Col>
                                  <Col
                                    xs="12"
                                    md="6"
                                    className="mb-3 mb-md-0 pr-md-0"
                                  >
                                    <h4 className="text-dark m-0 text-center text-md-left">
                                      <i className="mdi mdi-circle-half-full text-success mr-2"></i>
                                      In Progress
                                    </h4>
                                  </Col>
                                  <Col xs="12" md="6">
                                    <Button
                                      color="primary"
                                      outline
                                      block
                                      size="sm"
                                      onClick={() => {
                                        this.props.history.push(
                                          `/global/learning-center/course/${course?._id}`
                                        );
                                      }}
                                    >
                                      View Course
                                    </Button>
                                  </Col>
                                </Row>
                                 */}
                                    <Button
                                      color="primary"
                                      outline
                                      block
                                      size="sm"
                                    >
                                      View Course
                                    </Button>
                                  </CardFooter>
                                </Card>
                              </Col>
                            ))}
                          </>
                        ) : (
                          <>
                            <Col xs="12" className="mb-3">
                              <div className="rounded p-4 border">
                                <h3 className="text-center m-0 text-dark">
                                  No courses found matching your search "
                                  {this.state.search}".
                                </h3>
                              </div>
                            </Col>
                          </>
                        )}
                      </Row>
                    </>
                  ) : (
                    <>
                      <div className="rounded p-4 border">
                        <h3 className="text-center m-0 text-dark">
                          No courses available at this time, check back later.
                        </h3>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Container>
      </>
    );
  }
}

export default LearningCenterCourseLibrary;
