import APIV2 from "lib/APIV2";
import Event from "lib/Event";
import PubSub from "lib/PubSub";
import StringUtils from "lib/StringUtils";
import React from "react";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import {
  DatePicker,
  SelectPicker,
  Modal,
  Drawer,
  Dropdown,
  Whisper,
  Popover,
} from "rsuite";
import moment from "moment";
import _ from "underscore";
import ModalBody from "reactstrap/lib/ModalBody";
import SelectedMealCheckoutRow from "components/SelectedMealCheckoutRow";

import CustomerContactInformationWidget from "components/Widgets/CustomerContactInformationWidget";
import CustomerShippingAddressWidget from "components/Widgets/CustomerShippingAddressWidget";
import CustomerBillingAddressWidget from "components/Widgets/CustomerBillingAddressWidget";
import EditBoxDrawer from "./EditBoxDrawer";
import DropdownItem from "reactstrap/lib/DropdownItem";
import swal from "sweetalert";
import Invoice from "components/Invoice";
import { isThisTypeNode } from "typescript";
import ReactGoogleAutocomplete from "react-google-autocomplete";

class StorefrontStaffMemberDrawer extends React.Component {
  state = {
    roleID: "",
    name: "",
    email: "",
    phone: {
      value: "",
    },
    hireDate: null,
    roleID: "",
    roleOptions: [],
  };

  constructor() {
    super();

    this.dropdownRef = React.createRef();
  }

  getRoles() {
    this.setState({
      loadingRoles: true,
    });

    APIV2.getStaffRoles(1, 100).then((data) => {
      this.setState({
        roles: data.data.roles,
        roleOptions: data.data.roles.map((role) => {
          return {
            label: role.name,
            value: role._id,
          };
        }),
        loadingRoles: false,
      });
    });
  }

  _loadState() {
    let state = {
      name: this.props.staff?.name ? this.props.staff.name : "",
      email: this.props.staff?.email ? this.props.staff.email : "",
      phone: {
        value: this.props.staff?.phone ? this.props.staff.phone : "",
      },
      roleID: this.props.staff?.roleID ? this.props.staff.roleID : "",
      hireDate: this.props.staff?.hireDate
        ? moment(this.props.staff.hireDate).toDate()
        : null,
      address: this.props.staff?.address ? this.props.staff.address : {},
      formattedAddress: this.props.staff?.address?.formatted_address
        ? this.props.staff.address.formatted_address
        : "",
      terminationDate: this.props.staff?.terminationDate
        ? moment(this.props.staff.terminationDate).toDate()
        : null,
    };

    this.setState(state);
  }

  componentDidUpdate(prevProps) {
    if (this.props.staff != prevProps.staff) {
      this._loadState();
    }
  }

  componentDidMount() {
    this.getRoles();

    if (this.props.staff) {
      this._loadState();
    }
  }

  handleInputChange(e) {
    const { name, value } = e.target;

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name == "formattedAddress" && !value) {
          this.setState({
            address: {},
            formattedAddress: "",
          });
        }
      }
    );
  }

  handlePhoneChange(values) {
    this.setState({
      phone: values,
    });
  }

  setError(id, message = "") {
    if (!message) {
      this.setState({ [id]: "" });

      return;
    }

    this.setState({ [id]: message });

    setTimeout(() => {
      this.setError(id);
    }, 5000);
  }

  toggleModal() {
    this.setState({
      name: "",
      email: "",
      phone: {
        value: "",
      },
      roleID: "",
      hireDate: null,
      terminationDate: null,
      address: {},
    });

    this.props.onClose();
  }

  create() {
    let hireDate = null;
    let terminationDate = null;

    if (this.state.hireDate) {
      hireDate = moment(this.state.hireDate).startOf("day").toDate();
    }

    if (this.state.terminationDate) {
      terminationDate = moment(this.state.terminationDate)
        .startOf("day")
        .toDate();
    }

    this.setState({ submitting: true });

    APIV2.createStoreStaff(
      this.props?.storeID,
      this.state.name,
      this.state.email,
      this.state.phone?.value,
      this.state.address,
      this.state.roleID,
      hireDate,
      terminationDate
    )
      .then(
        (data) => {
          const staff = data.data.staff;

          PubSub.publish(Event.STAFF.CREATED, staff);

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create staff member - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  modify() {
    let hireDate = null;
    let terminationDate = null;

    if (this.state.hireDate) {
      hireDate = moment(this.state.hireDate).startOf("day").toDate();
    }

    if (this.state.terminationDate) {
      terminationDate = moment(this.state.terminationDate)
        .startOf("day")
        .toDate();
    }

    this.setState({ submitting: true });

    APIV2.modifyStoreStaff(
      this.props?.storeID,
      this.props.staff._id,
      this.state.name,
      this.state.email,
      this.state.phone?.value,
      this.state.address ? this.state.address : "",
      this.state.roleID,
      hireDate,
      terminationDate
    )
      .then(
        (data) => {
          const staff = data.data.staff;

          PubSub.publish(Event.STAFF.MODIFIED, staff);

          this.toggleModal();
        },
        (e) => {
          this.setError(
            "error",
            e?.response?.body?.message ??
              "Unable to create staff member - unknown error occurred. Try again."
          );
        }
      )
      .finally(() => {
        this.setState({ submitting: false });
      });
  }

  handleGoogleAutocomplete(place, ref) {
    let streetNumber = "";
    let address1 = "";
    let city = "";
    let state = "";
    let zip = "";

    if (
      !place ||
      !place.address_components ||
      !place.address_components.length
    ) {
      return;
    }

    for (let i = 0; i < place.address_components.length; i++) {
      let comp = place.address_components[i];

      if (comp.types && comp.types.indexOf("street_number") >= 0) {
        streetNumber = comp.long_name;
      }

      if (comp.types && comp.types.indexOf("route") >= 0) {
        address1 = comp.long_name;
      }

      if (comp.types && comp.types.indexOf("locality") >= 0) {
        city = comp.long_name;
      }

      if (
        comp.types &&
        comp.types.indexOf("administrative_area_level_1") >= 0
      ) {
        state = comp.long_name;
      }

      if (comp.types && comp.types.indexOf("postal_code") >= 0) {
        zip = comp.long_name;
      }
    }

    console.log(place);

    this.setState({
      address: place,
      formattedAddress: place?.formatted_address,
    });

    ref.value = place?.formatted_address;
  }

  submit() {
    if (this.props.staff) {
      this.modify();
    } else {
      this.create();
    }
  }

  render() {
    return (
      <>
        <Drawer
          size="sm"
          style={{ maxWidth: "100%" }}
          open={this.props.open}
          onClose={() => this.toggleModal()}
        >
          <Drawer.Header className="pr-4">
            <h3 className="m-0" style={{ position: "relative", top: "7px" }}>
              {this.props.staff ? "Modify" : "Create"} Staff Member
            </h3>
            <Drawer.Actions>
              <Button
                onClick={this.submit.bind(this)}
                size="sm"
                color="primary"
                disabled={
                  !this.state.name ||
                  !this.state.email ||
                  !this.state.phone?.value ||
                  !this.state.roleID ||
                  this.state.submitting
                }
              >
                {this.state.submitting ? (
                  <Spinner size="sm" color="white"></Spinner>
                ) : this.props.staff ? (
                  "Save"
                ) : (
                  "Create"
                )}
              </Button>
            </Drawer.Actions>
          </Drawer.Header>
          <Drawer.Body className="p-4">
            {this.state.error && (
              <>
                <Alert
                  color=""
                  className="m-0 mb-4 bg-primary-superlight text-dark font-weight-500"
                >
                  {this.state.error}
                </Alert>
              </>
            )}
            <FormGroup>
              <h5>
                Name&nbsp;<span className="text-danger">*</span>
              </h5>
              <Input
                type="text"
                name="name"
                placeholder="Name"
                required
                bsSize="sm"
                value={this.state.name}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>
                Email<span className="text-danger">*</span>
              </h5>
              <Input
                type="email"
                name="email"
                placeholder="Email"
                required
                bsSize="sm"
                value={this.state.email}
                onChange={this.handleInputChange.bind(this)}
              ></Input>
            </FormGroup>
            <FormGroup>
              <h5>
                Phone&nbsp;<span className="text-danger">*</span>
              </h5>
              <NumberFormat
                className="form-control form-control-sm mb-3"
                format="(###) ###-####"
                mask=""
                name="phoneNumberInput"
                placeholder="Phone Number"
                onValueChange={this.handlePhoneChange.bind(this)}
                value={this.state.phone?.value}
                type="tel"
                required
              />
            </FormGroup>
            <FormGroup>
              <h5>Mailing Address</h5>
              <ReactGoogleAutocomplete
                required
                placeholder="Mailing Address"
                apiKey={"AIzaSyA5-fbnedb5uXDBwbjnz0J_2eDe_9Gi2Cs"}
                onPlaceSelected={this.handleGoogleAutocomplete.bind(this)}
                onChange={this.handleInputChange.bind(this).bind(this)}
                name="formattedAddress"
                options={{ types: ["address"] }}
                value={this.state.formattedAddress}
                className="form-control form-control-sm"
                //value={this.state.address1}
              ></ReactGoogleAutocomplete>
            </FormGroup>
            <FormGroup>
              <h5>
                Role&nbsp;<span className="text-danger">*</span>
              </h5>

              <SelectPicker
                block
                data={this.state.roleOptions}
                value={this.state.roleID}
                onChange={(v) => {
                  this.setState({ roleID: v });
                }}
                placeholder="Select Role"
                loading={this.state.loadingRoles}
                searchable={false}
                oneTap={true}
              ></SelectPicker>
            </FormGroup>
            <FormGroup>
              <h5>Hire Date</h5>
              <DatePicker
                block
                onChange={(v) => {
                  this.setState({ hireDate: v });
                }}
                value={this.state.hireDate}
                cleanable
                oneTap
                placement="auto"
              ></DatePicker>
            </FormGroup>
            {this.props.staff?.terminated ? (
              <FormGroup>
                <h5>Termination Date</h5>
                <DatePicker
                  block
                  onChange={(v) => {
                    this.setState({ terminationDate: v });
                  }}
                  value={this.state.terminationDate}
                  cleanable
                  oneTap
                  placement="auto"
                ></DatePicker>
              </FormGroup>
            ) : null}
          </Drawer.Body>
        </Drawer>
      </>
    );
  }
}

export default withRouter(StorefrontStaffMemberDrawer);
