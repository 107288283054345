import cubejs from "@cubejs-client/core";
import {
  AreaChart,
  BadgeDelta,
  Card,
  DeltaBar,
  Flex,
  Metric,
  ProgressBar,
  Tab,
  TabList,
  Text,
  Col as TCol,
  ColGrid,
  DonutChart,
  Bold,
  List,
  ListItem,
  BarList,
  BarChart,
} from "@tremor/react";
import moment from "moment";
import React from "react";
import { Button, Col, Row } from "reactstrap";
import { Drawer } from "rsuite";
import DataAPI from "../../../lib/DataAPI";
import StringUtils from "../../../lib/StringUtils";
import _ from "underscore";
import ClientOrderKPIDetailDrawer from "./ClientOrderKPIDetailDrawer";
import ChartMetricHeader from "./ChartMetricHeader";
import ScorecardNewMemberDetailDrawer from "./ScorecardNewMemberDetailDrawer";

class NewMemberKPICard extends React.Component {
  state = {
    loading: true,
    dataAvailable: false,
    active: "A",
    typeList: [],
    nameList: [],
    utmSourceList: [],
    utmCampaignList: [],
  };

  /**
   * Fetches a summary of all the member data
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchData(cubejsApi, stores, dateRange, metricNames = []) {
    let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

    secondDifference = secondDifference / 1000;

    let granularity = secondDifference > 60 * 60 * 24 * 90 ? "week" : "day";

    if (secondDifference >= 60 * 60 * 24 * 364) {
      granularity = "month";
    }

    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["Metrics.count"],
          order: {
            "Metrics.createdat": "asc",
          },

          timeDimensions: [
            {
              dimension: "Metrics.createdat",
              dateRange: [dateRange[0], dateRange[1]],
              granularity,
            },
          ],
          filters: [
            {
              member: "Metrics.metadatastoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "Metrics.name",
              operator: "equals",
              values: metricNames,
            },
          ],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          let total = 0;

          if (!data?.length) {
            return resolve(null);
          }

          data = data.map((item) => {
            total += item["Metrics.count"];

            let mom = moment(item[`Metrics.createdat.${granularity}`]);

            let granularityString = mom.format("MM/DD/YYYY");

            if (granularity == "week") {
              mom.day(0);
              granularityString = "Week " + mom.format("MM/DD/YYYY");
            }

            if (granularity == "month") {
              mom.startOf("month");

              granularityString = mom.format("MMMM YY");
            }

            return {
              count: item["Metrics.count"],
              dateObj: mom.toDate(),
              date: granularityString,
            };
          });

          return resolve({
            total,
            history: data,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async _fetchReport(stores, dateRange) {
    this.setState({
      loading: true,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!dateRange?.length) {
      this.setState({
        loading: false,
        dataAvailable: false,
      });

      return;
    }

    if (!stores?.length) {
      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchData(cubejsApi, stores, dateRange, [
        "member_new",
        "member_reactivate",
      ]);
    } catch (e) {
      this.setState({
        dataAvailable: false,
        error: "Unable to load new member quantity.",
        loading: false,
      });

      return;
    }

    console.log(currentCount);

    if (currentCount !== null) {
      this.setState({
        dataAvailable: true,
        previousCount: null,
      });
    } else {
      this.setState({
        dataAvailable: false,
      });
    }

    if (this.props.comparePrevious) {
      let previousCount = null;

      let secondDifference = dateRange[1].getTime() - dateRange[0].getTime();

      secondDifference = secondDifference / 1000;

      let granularity = secondDifference > 60 * 60 * 24 * 90 ? "week" : "day";

      if (secondDifference >= 60 * 60 * 24 * 364) {
        granularity = "month";
      }

      let startMoment = moment(dateRange[0].toISOString());
      let endMoment = moment(dateRange[1].toISOString());

      startMoment.subtract(secondDifference, "seconds");
      endMoment.subtract(secondDifference + 1, "seconds");

      try {
        previousCount = await this._fetchData(
          cubejsApi,
          stores,
          [startMoment.toDate(), endMoment.toDate()],
          ["member_new", "member_reactivate"]
        );
      } catch (e) {}

      let newHistory = [];
      let reactivateHistory = [];

      try {
        let res = await this._fetchData(cubejsApi, stores, dateRange, [
          "member_new",
        ]);

        newHistory = res?.history;
      } catch (e) {}

      try {
        let res = await this._fetchData(cubejsApi, stores, dateRange, [
          "member_reactivate",
        ]);

        reactivateHistory = res?.history;
      } catch (e) {}

      let dataHistory = [];

      for (let i = 0; i < newHistory?.length; i++) {
        const cur = newHistory[i];

        let out = {
          dateObj: cur?.dateObj,
          date: cur?.date,
          "First-Time Members": cur?.count,
          "Reactivated Members": 0,
        };

        dataHistory.push(out);
      }

      for (let i = 0; i < reactivateHistory?.length; i++) {
        const cur = reactivateHistory[i];

        const idx = _.findIndex(dataHistory, { date: cur?.date });

        if (idx >= 0) {
          dataHistory[idx]["Reactivated Members"] = cur?.count;
        } else {
          let out = {
            dateObj: cur?.dateObj,
            date: cur?.date,
            "First-Time Members": 0,
            "Reactivated Members": cur?.count,
          };

          dataHistory.push(out);
        }
      }

      startMoment = moment(dateRange[0]);
      endMoment = moment(dateRange[1]);

      let tempDate = moment(dateRange[0])
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0);

      if (granularity == "week") {
        tempDate.day(0);
      }

      if (granularity == "month") {
        tempDate.startOf("month");
      }

      while (tempDate.toDate() <= endMoment.toDate()) {
        let granularityString = tempDate.format("MM/DD/YYYY");

        if (granularity == "week") {
          tempDate.day(0);
          granularityString = "Week " + granularityString;
        }

        if (granularity == "month") {
          tempDate.startOf("month");
          granularityString = tempDate.format("MMMM YY");
        }

        if (!_.findWhere(dataHistory, { date: granularityString })) {
          dataHistory.push({
            dateObj: tempDate.toDate(),
            date: granularityString,
            "First-Time Members": 0,
            "Reactivated Members": 0,
          });
        }

        if (granularity == "day") {
          tempDate.add(1, "day");
        } else if (granularity == "week") {
          tempDate.add(1, "week");
        } else if (granularity == "month") {
          tempDate.add(1, "month");
        }
      }

      if (previousCount !== null) {
        let percentChange =
          previousCount?.total > 0
            ? (currentCount?.total - previousCount?.total) /
              previousCount?.total
            : null;

        let declineMode = "";
        let isNegative = false;

        if (percentChange > 0) {
          if (Math.abs(percentChange) < 0.015) {
            declineMode = "unchanged";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateIncrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "increase";
          }
        } else {
          isNegative = true;

          if (Math.abs(percentChange) < 0.015) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) < 0.1) {
            declineMode = "moderateDecrease";
          } else if (Math.abs(percentChange) >= 0.1) {
            declineMode = "decrease";
          }
        }

        dataHistory = _.sortBy(dataHistory, "dateObj");

        this.setState({
          previousCount,
          changeIsNegative: isNegative,
          deltaType: declineMode,
          percentChange,
          percentChangeString: Math.abs(percentChange * 100).toFixed(1) + "%",

          dataHistory,
        });
      }
    }

    this.setState({
      currentCount,
      loading: false,
    });
  }

  /**
   * Load all report information
   *
   * @param {*} stores
   * @param {*} dateRange
   * @param {*} compare
   */
  async loadReport(stores, dateRange, compare) {
    this.setState({
      loading: true,
    });

    this._fetchReport(stores, dateRange);
  }

  /**
   * Fetches the order data by week for the given stores & date range
   *
   * @param {*} cubejsApi
   * @param {*} stores
   * @param {*} dateRange
   * @returns
   */
  async _fetchReferralData(cubejsApi, stores, dateRange) {
    return new Promise((resolve, reject) => {
      // Load
      cubejsApi
        .load({
          measures: ["Metrics.count"],
          order: {
            "Metrics.createdat": "desc",
          },

          timeDimensions: [
            {
              dimension: "Metrics.createdat",
              dateRange: [dateRange[0], dateRange[1]],
            },
          ],
          filters: [
            {
              member: "Metrics.metadatastoreid",
              operator: "equals",
              values: stores,
            },
            {
              member: "Metrics.name",
              operator: "equals",
              values: ["member_new", "member_reactivate", "member_referral"],
            },
          ],
          dimensions: ["Stores.name", "Metrics.name"],
        })
        .then((res) => {
          let data = res?.loadResponse?.results?.length
            ? res?.loadResponse?.results[0]?.data
            : [];

          if (!data?.length) {
            return resolve(null);
          }

          let total = 0;
          let totalReferred = 0;

          let out = [];

          let outOverall = [];

          for (let i = 0; i < data?.length; i++) {
            const item = data[i];
            const storeName = item["Stores.name"]
              ?.replace("Project LeanNation", "PLN")
              ?.trim();

            const idx = _.findIndex(out, { storeName });

            let metricName = item["Metrics.name"];

            if (
              metricName == "member_new" ||
              metricName == "member_reactivate"
            ) {
              metricName = "Non-Referred Members";

              total += item["Metrics.count"];
            } else if (metricName == "member_referral") {
              metricName = "Referred Members";
              totalReferred += item["Metrics.count"];
            }

            if (idx >= 0) {
              out[idx][metricName] = out[idx][metricName]
                ? out[idx][metricName] + item["Metrics.count"]
                : item["Metrics.count"];
            } else {
              out.push({
                storeName,
                [metricName]: item["Metrics.count"],
              });
            }

            const idx2 = _.findIndex(outOverall, { metricName });

            if (idx2 >= 0) {
              outOverall[idx2].Members += item["Metrics.count"];
            } else {
              outOverall.push({
                Members: item["Metrics.count"],
                metricName,
              });
            }
          }

          out = out.map((item) => {
            const total = item["Non-Referred Members"];

            return {
              ...item,
              "Non-Referred Members":
                total -
                (item["Referred Members"] ? item["Referred Members"] : 0),
              total,
              percent: total
                ? ((item["Referred Members"] ? item["Referred Members"] : 0) /
                    total) *
                  100
                : 0,
            };
          });

          const referred = _.findWhere(outOverall, {
            metricName: "Referred Members",
          });
          const nonReferred = _.findWhere(outOverall, {
            metricName: "Non-Referred Members",
          });

          if (referred && nonReferred) {
            nonReferred.Members = nonReferred.Members - referred.Members;

            outOverall = [referred, nonReferred];
          }

          outOverall = _.sortBy(outOverall, "metricName");

          out = _.sortBy(out, "total");

          out.reverse();

          return resolve({
            byStore: out,
            total: ((totalReferred / total) * 100).toFixed(1),
            overall: outOverall,
          });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  async loadReferralReport(stores, dateRange) {
    this.setState({
      referralLoading: true,
      referralDataAvailable: false,
    });

    const cubejsApi = cubejs(DataAPI.getAuthToken(), {
      apiUrl: DataAPI.getEnvironment(),
    });

    if (!stores?.length || !dateRange?.length) {
      this.setState({
        referralLoading: false,
        referralDataAvailable: false,
      });

      return;
    }

    let currentCount = null;

    try {
      currentCount = await this._fetchReferralData(
        cubejsApi,
        stores,
        dateRange
      );
    } catch (e) {
      this.setState({
        referralDataAvailable: false,
        referralError: "Unable to load total new members by store.",
        referralLoading: false,
      });

      return;
    }

    if (currentCount !== null) {
      this.setState({
        referralDataAvailable: true,
        referralPreviousCount: null,
      });
    } else {
      this.setState({
        referralDataAvailable: false,
      });

      return;
    }

    let colors = StringUtils.randomChartColorSet(currentCount.byStore?.length);

    console.log(currentCount?.byStore);

    this.setState({
      referralCurrentCount: currentCount,
      referralLoading: false,
      referralByStore: currentCount?.byStore,
      referralOverall: currentCount?.overall,
      referralByStoreColors: colors,
      referralTotal: currentCount?.total,
    });

    /*if (typeof this.props.onLoaded == "function") {
      this.props.onLoaded();
    }*/
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.stores != prevProps?.stores && this.props.stores?.length) ||
      this.props.dateRange != prevProps?.dateRange ||
      this.props.comparePrevious != prevProps.comparePrevious
    ) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );

      this.loadReferralReport(this.props.stores, this.props.dateRange);
    }
  }

  componentDidMount() {
    if (this.props.stores && this.props.dateRange) {
      this.loadReport(
        this.props.stores,
        this.props.dateRange,
        this.props.comparePrevious
      );

      this.loadReferralReport(this.props.stores, this.props.dateRange);
    }
  }

  render() {
    return (
      <>
        <Card marginTop="mt-0" hFull={true}>
          <ChartMetricHeader
            forceWrapComparison={true}
            title="New Members"
            description="All new members that joined during the selected date range."
            actions={
              <>
                <Button
                  size="sm"
                  outline
                  color="dark"
                  className="border-0 btn-icon-only"
                  disabled={this.state.loading || !this.state.dataAvailable}
                  onClick={() => {
                    this.setState({
                      open: true,
                    });
                  }}
                >
                  <i className="mdi mdi-fullscreen"></i>
                </Button>
              </>
            }
            loading={this.state.loading}
            dataAvailable={this.state.dataAvailable}
            metric={`${StringUtils.numberFormat(
              this.state.currentCount?.total
            )} Members`}
            comparisonMetric={
              this.state.previousCount !== null
                ? `${StringUtils.numberFormat(
                    this.state.previousCount?.total
                  )} Members`
                : null
            }
            dateRange={this.props.dateRange}
            deltaType={this.state.deltaType}
            percentChange={this.state.percentChangeString}
            showPercentChange={true}
          ></ChartMetricHeader>

          {this.state.loading || this.state.referralLoading ? (
            <>
              <div
                className="skeleton rounded"
                style={{ width: "100%", height: 320, marginTop: "1.5rem" }}
              >
                &nbsp;
              </div>
            </>
          ) : (
            <>
              <div className="mt-3 mb-3 border-top"></div>

              <DonutChart
                data={this.state.referralOverall}
                category={"Members"}
                dataKey="metricName"
                colors={["green", "blue"]}
                showTooltip={false}
                valueFormatter={(number) => {
                  return (
                    <>
                      <tspan
                        style={{ fontSize: 18 }}
                        x="50%"
                        y="43%"
                        dy="1.2rem"
                      >
                        {StringUtils.numberFormat(number)}&nbsp;Members
                      </tspan>

                      <tspan
                        style={{ fontSize: 14 }}
                        x="50%"
                        y="50%"
                        dy="1.2rem"
                      >
                        {StringUtils.numberFormat(
                          this.state.referralCurrentCount?.total
                        )}
                        % Referred (
                        {StringUtils.numberFormat(
                          Math.round(
                            (this.state.referralCurrentCount?.total / 100) *
                              this.state.currentCount?.total
                          )
                        )}{" "}
                        members)
                      </tspan>
                    </>
                  );
                }}
                height="h-80"
              />
            </>
          )}
        </Card>
        <ScorecardNewMemberDetailDrawer
          open={this.state.open}
          onClose={() => {
            this.setState({
              open: false,
            });
          }}
          stores={this.props.stores}
          dateRange={this.props.dateRange}
        />
      </>
    );
  }
}

export default NewMemberKPICard;
